import React, { createContext, useMemo, useContext, useCallback, useEffect } from 'react'
import { DateAtQuery, GameCategoryType, NowTime, PaginationReq } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import MuiButton from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { SearchToRequestFunc, InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, getTimeFromDateInputValue, getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import allRoute from '../route/route'
import { createValidateStartAtWithEndAt } from '../../../utils/default/Validator'
import { setToday, setYesterday, setThisMonth, setLastMonth, setThisWeek, setLastWeek, setLastSevenDays, createShouldDisableDate, getAllowedTimeStamps, createShouldDisableFuture, getCorrectedDateRange } from '../../../utils/default/TimeHelper'
import { endOfDay, getTime, startOfDay, subDays, subHours } from 'date-fns'
import { getCategoryShortName } from '../../../utils/default/PlatformHelper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import DropDown from '../../default/form/DropDown'
import { makeStyles } from '@material-ui/core'

interface PropType {
  category: 0 | GameCategoryType
}
export enum DropdownValue {
  latestThreeMonthsData = 'three_months',
  latestTwentyFourHoursData = 'last_24_hours'
}

export interface FormType {
  search_type: DropdownValue
  time: DateInputValue
}

export type RequestType = DateAtQuery & PaginationReq & { search_type: DropdownValue }

export const searchToRequest: SearchToRequestFunc<RequestType> = (search) => {
  const startTimeStamp = Number(search.start_at)
  const endTimeStamp = Number(search.end_at)
  const { minAllowedStartTimeStamp, maxAllowedEndTimeStamp } = getAllowedTimeStamps(startTimeStamp, endTimeStamp)
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, minAllowedStartTimeStamp, endTimeStamp)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, startTimeStamp, maxAllowedEndTimeStamp)
    )(search.end_at)
  } as RequestType
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as RequestType
}

export const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  search_type: DropdownValue.latestThreeMonthsData,
  time: setLastSevenDays(),
  gameId: 0,
  ...defaultForm
})

const formToRequest = (form: FormType): RequestType => {
  return {
    search_type: form.search_type,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    page: 1
  }
}

const getValueFromEvent: ValueGetter<FormType> = {
  time: getTimeFromDateInputValue,
  search_type: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange, dispatch } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    if (value.search_type === DropdownValue.latestThreeMonthsData) {
      return createShouldDisableFuture()
    } else {
      const leftBound = subHours(startOfDay(NowTime.get()), 24)
      return createShouldDisableDate(value.time.start, value.time.end, leftBound)
    }
  }, [value.time, value.search_type])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const tools = useMemo(() => {
    return [
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      },
      {
        label: t('common.thisWeek'),
        change: setThisWeek
      },
      {
        label: t('common.lastWeek'),
        change: setLastWeek
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.today'),
        change: setToday
      }
    ]
  }, [t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onTypeChange = useCallback(() => {
    const today = new Date()
    if (value.search_type === DropdownValue.latestThreeMonthsData) {
      dispatch({
        type: 'change',
        label: 'time',
        value: {
          start: subDays(startOfDay(today), 6),
          end: endOfDay(today)
        }
      })
    } else {
      dispatch({
        type: 'change',
        label: 'time',
        value: {
          start: subHours(today, 24),
          end: today
        }
      })
    }
  }, [value.search_type])
  const onChange = useCallback((value) => {
    handleChange('time')(getCorrectedDateRange(value))
  }, [])

  useEffect(onTypeChange, [value.search_type])
  return (
    <DateInputBase
      onlyDate={value.search_type === DropdownValue.latestThreeMonthsData}
      value={value.time}
      FrontComponent={SearchDropDown}
      start={startOption}
      end={endOption}
      tools={value.search_type === DropdownValue.latestThreeMonthsData ? tools : []}
      classes={dateClasses}
      onChange={onChange}
    />
  )
})

const SearchDropDown: React.FC = React.memo(() => {
  const { t } = useT()
  const useStyles = makeStyles(() => ({
    container: {
      top: '3px'
    },
    label: {
      fontSize: '14px'
    }
  }))
  const classes = useStyles()
  const { value, handleChange } = useContext(FormContext)
  const options = useMemo(() => ([
    {
      name: t('common.latestThreeMonthsData'),
      value: DropdownValue.latestThreeMonthsData
    },
    {
      name: t('common.latestTwentyFourHoursData'),
      value: DropdownValue.latestTwentyFourHoursData
    }
  ]), [t])
  const onChange = useCallback(handleChange('search_type'), [])

  return (
    <Grid item>
      <DropDown
        label={t('common.searchDate')}
        classes={{
          label: classes.label
        }}
        options={options}
        value={value.search_type}
        onChange={onChange}
      />
    </Grid>)
})

const AdminReportPlayerLeaderboardDetailForm: React.FC<PropType> = (props) => {
  const { category } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const categories = useGDKStore.platform.categories()
  const request = useRequestFromSearch({ searchToRequest })
  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        search_type: request.search_type,
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        }
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: (option) => allRoute.adminReportPlayerLeaderboardDetail.encodePath({ ...option, param: { ...option.param, id: category } })
  })

  const validation = useMemo(() => ({
    time: [
      {
        func: createValidateStartAtWithEndAt('time', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    search_type: []
  } as FormValidation<FormType>), [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('common.leaderboardDetail', { prefix: category === 0 ? t('common.allPlatform') : getCategoryShortName(category, categories) })}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default AdminReportPlayerLeaderboardDetailForm
