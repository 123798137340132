import React, { useMemo, useCallback, useContext } from 'react'
import { FeedbackFrequency, PlayerRankType } from '@golden/gdk-admin'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import playerRankName from '../../../constants/default/playerRankName'
import FeedbackInput from './FeedbackInput'
import { createTableData } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import CoreTable from '../../default/present/CoreTable'
import { FormState } from '../../../utils/default/FormHook'

type RowType = Record<string, React.ReactElement> & {
  id: number
}

interface FormType {
  period: FeedbackFrequency
  ranks: Array<{ rank: PlayerRankType, feedback: string }>
  channels: Array<{
    channel_id: number
    channel_code: string
    ranks: Array<{ rank: PlayerRankType, feedback: string }>
  }>
}

export interface PropTypes {
  context: React.Context<FormState<FormType>>
}

const RankInput: React.FC<{
  rank: PlayerRankType
  value: string
  onChange: (rank: PlayerRankType, value: string) => void
  disabled?: boolean
}> = React.memo((props) => {
  const { rank, value, onChange, disabled } = props
  const handleChange = useCallback((value: string) => { onChange(rank, value) }, [onChange, rank])
  return (
    <FeedbackInput
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  )
})
const FeedbackRankInput: React.FC<PropTypes> = (props) => {
  const { context } = props
  const { value: form, dispatch } = useContext(context)
  const { t } = useT()
  const commonClasses = useCommonStyles()
  const tableClasses = useMemo(() => ({
    head: commonClasses.blackTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])

  const handleChange = useCallback((rank: PlayerRankType, value: string) => {
    dispatch({
      type: 'change',
      label: 'ranks',
      value: form.ranks?.map((item) => {
        if (item.rank === rank) {
          return {
            rank,
            feedback: value
          }
        } else {
          return item
        }
      })
    })
  }, [form.ranks, dispatch])
  const rows = useMemo(() => {
    const rankDataObject = Object.keys(playerRankName)
      .map((key) => key as PlayerRankType)
      .map((key) => {
        const target = form.ranks?.find((item) => item.rank === key)
        return {
          [key.toString()]: (
            <RankInput
              rank={key}
              value={target?.feedback ?? ''}
              onChange={handleChange}
            />
          )
        }
      })
      .reduce((item, obj) => Object.assign({}, obj, item), {})
    return [
      { id: 1, ...rankDataObject }
    ] as RowType[]
  }, [form, handleChange])
  const data = useMemo(() => {
    const rankColumnObject = Object.keys(playerRankName)
      .map((key) => key as PlayerRankType)
      .map((key) => {
        return {
          [key.toString()]: {
            label: `${t(playerRankName[key])}*`,
            value: key.toString(),
            align: 'center'
          }
        }
      })
      .reduce((item, obj) => Object.assign({}, item, obj), {})
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        ...rankColumnObject
      },
      Object.keys(playerRankName),
      rows,
      'id'
    )
  }, [rows, t])
  return (
    <CoreTable
      dense
      data={data}
      classes={tableClasses}
      total={1}
    />
  )
}

export default React.memo(FeedbackRankInput)
