import React from 'react'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import ButtonBase from '../../default/Button'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'

interface PropTypes {
  content: string
  isOpen: boolean
  handleClose: () => void
}

const Button = React.memo(ButtonBase)

const ExternalGameChannelErrorDialog: React.FC<PropTypes> = (props) => {
  const { isOpen, content, handleClose } = props
  const { t } = useT()
  const commonClasses = useCommonStyles()

  return (
    <Dialog maxWidth={'xs'} open={isOpen} fullWidth>
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {t('common.detailInformation')}
      </Box>
      <Box paddingBottom={2} overflow="auto">
        <Box
          padding={4}
          textAlign='left'
          justifyContent='center'
        >
          <Box maxWidth={1}>
            <Box padding={2} overflow="auto">
              {content}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box textAlign="center" paddingX={2} paddingBottom={2}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="default"
        >
          {t('common.confirm')}
        </Button>
      </Box>
    </Dialog>
  )
}

export default ExternalGameChannelErrorDialog
