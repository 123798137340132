import React, { useMemo, createContext, useContext, useCallback, useState } from 'react'
import { PaginationReq, ManagerLoginHistoryQuery, DeviceType, RoleTreeItem, AgentType } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { SearchToRequestFunc, useRequestFromSearch, InitialFormFunc, useChangeUrlSubmit, useGetData } from '../../../utils/default/ComplexFlowHook'
import { guaranteeNotUndefined, guaranteeBetween, pipe, parseInt, acceptUndefined, guaranteeBeOneOf, convertEmptyToUndefined, getTimeFromDateInputValue, getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import deviceName from '../../../constants/admin/deviceName'
import allRoutes from '../route/route'
import { createValidateIp } from '../../../utils/default/Validator'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import { setToday, setYesterday, setThisMonth, setLastMonth, getAllowedTimeStamps, createShouldDisableFuture, getCorrectedDateRange } from '../../../utils/default/TimeHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import agentTypeName from '../../../constants/default/agentTypeName'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

export type ManagerLoginHistoryRequest = PaginationReq & ManagerLoginHistoryQuery

export interface ManagerLoginHistoryFormType {
  time: DateInputValue
  account: string
  ip: string
  device: DeviceType | '--'
  region: string
  identity: string
  roleId?: number | '--'
  agentType?: number | '--'
}

export const searchToRequest: SearchToRequestFunc<ManagerLoginHistoryRequest> = (search) => {
  const startTimeStamp = Number(search.start_at)
  const endTimeStamp = Number(search.end_at)
  const { minAllowedStartTimeStamp, maxAllowedEndTimeStamp } = getAllowedTimeStamps(startTimeStamp, endTimeStamp)
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, minAllowedStartTimeStamp, endTimeStamp)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, startTimeStamp, maxAllowedEndTimeStamp)
    )(search.end_at),
    device: acceptUndefined(search.device, pipe(
      (value) => guaranteeBeOneOf(value, Object.keys(deviceName)),
      parseInt
    ))
  } as ManagerLoginHistoryRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as ManagerLoginHistoryRequest
}

export const initialForm: InitialFormFunc<ManagerLoginHistoryFormType> = (defaultForm) => ({
  time: setToday(),
  account: '',
  ip: '',
  device: '--',
  region: '',
  identity: 'admin',
  roleId: '--',
  agentType: '--',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<ManagerLoginHistoryFormType> = {
  time: getTimeFromDateInputValue,
  account: getValueFromChangeEvent,
  ip: getValueFromChangeEvent,
  device: getValueFromChangeEvent,
  region: getValueFromChangeEvent,
  identity: getValueFromChangeEvent,
  roleId: getValueFromChangeEvent,
  agentType: getValueFromChangeEvent
}

const formToRequest = (form: ManagerLoginHistoryFormType): ManagerLoginHistoryRequest => {
  const converted = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    account: convertEmptyToUndefined(form.account),
    ip: convertEmptyToUndefined(form.ip),
    device: form.device === '--' ? undefined : form.device,
    region: convertEmptyToUndefined(form.region),
    identity: convertEmptyToUndefined(form.identity),
    role_id: (form.roleId === '--' || form.identity === 'agent') ? undefined : form.roleId,
    agent_type: (form.agentType === '--' || form.identity === 'admin') ? undefined : form.agentType,
    page: 1
  } as ManagerLoginHistoryRequest
  return omitBy(converted, isUndefined) as ManagerLoginHistoryRequest
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => createShouldDisableFuture(), [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onChange = useCallback((value) => {
    handleChange('time')(getCorrectedDateRange(value))
  }, [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const AdminOrAgentInput: React.FC = React.memo(() => {
  const { value } = useContext(FormContext)
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const { t } = useT()
  const [nodes, setTree] = useState<RoleTreeItem[]>([] as RoleTreeItem[])
  const me = useGDKStore.admin.me()
  useGetData({
    canLoadData: !!me?.role_id,
    gdkFunc: () => gdk.permissionRole.getRoleTree(me?.role_id ?? 0),
    gdkFuncDependencies: [gdk],
    onSuccess: (res) => {
      setTree(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  const roleOptions = useMemo(() => (
    [{ name: t('common.all'), value: '--', disabled: false }].concat(
      nodes.map((role) => ({ name: role.name, value: role.id.toString(), disabled: false }))
    )
  ), [nodes, t])

  const accountTypeOptions = useMemo(() => [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(agentTypeName)
      .map((key) => {
        return {
          name: t(agentTypeName[Number(key) as AgentType]),
          value: key
        }
      })
  ), [t])

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      {(value.identity === 'admin') && <FormField<ManagerLoginHistoryFormType, DropDownProps>
        context={FormContext}
        component={DropDown}
        name="roleId"
        fullWidth
        label={t('common.role')}
        options={roleOptions}
      />}
      {(value.identity === 'agent') && <FormField<ManagerLoginHistoryFormType, DropDownProps>
        context={FormContext}
        component={DropDown}
        name="agentType"
        label={t('common.accountType')}
        options={accountTypeOptions}
        fullWidth
      />}
    </LoadingAndErrorFrame>
  )
})

const ManagerLoginHistoryForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        ...request,
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        identity: 'admin',
        roleId: request.role_id,
        agentType: request.agent_type
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.managerLoginHistory.encodePath
  })

  const validation = useMemo(() => ({
    time: [],
    account: [],
    ip: [
      {
        func: createValidateIp('ip', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    device: [],
    region: [],
    identity: [],
    roleId: [],
    agentType: []
  } as FormValidation<ManagerLoginHistoryFormType>), [t])

  const identityOptions = useMemo(() => [
    {
      name: t('goldenPlatform.admin'),
      value: 'admin'
    },
    {
      name: t('goldenPlatform.agent'),
      value: 'agent'
    }
  ], [t])

  const deviceOptions = useMemo(() => [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(deviceName)
      .map((key) => Number(key) as DeviceType)
      .filter((key) => (key !== 6))
      .map((key) => ({
        name: t(deviceName[key]),
        value: key
      })) as any
  ), [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.managerLoginHistory')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<ManagerLoginHistoryFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="identity"
                    label={t('common.backgroundCategory')}
                    options={identityOptions}
                    fullWidth
                  />
                </Grid>
                {<Grid item xs={12} md={3}>
                  <AdminOrAgentInput />
                </Grid>}
                <Grid item xs={12} md={3}>
                  <FormField<ManagerLoginHistoryFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="account"
                    label={t('common.account')}
                    placeholder={t('placeholder.inputAccount')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ManagerLoginHistoryFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="ip"
                    label={t('common.loginIpAddress')}
                    placeholder={t('placeholder.inputLoginIp')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ManagerLoginHistoryFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="device"
                    label={t('common.device')}
                    options={deviceOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<ManagerLoginHistoryFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="region"
                    label={t('common.countryAndProvince')}
                    placeholder={t('placeholder.inputOneOfProvinceAndCountry')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(ManagerLoginHistoryForm)
