import React, { useMemo } from 'react'
import useT from '../../i18ns/admin/useT'
import DropDown, { PropTypes as DropDownProps } from '../default/form/DropDown'
import { GameCategoryType, GameStatus, GameType } from '@golden/gdk-admin'
import { useInternValue } from '../../utils/default/FormHook'
import useGDKStore from '../../providers/admin/gdk/useGDKStore'
import MultipleSelector, { PropTypes as MultipleSelectorProps } from '../default/form/MultipleSelector'

type SingleValueType = GameType | 'noGame' | 'all'
type MultipleValueType = GameType[]
type ValueType = SingleValueType | MultipleValueType
type OmitType = 'value' | 'defaultValue' | 'onChange' | 'options'

export type PropTypes = Omit<DropDownProps, OmitType> &
Omit<MultipleSelectorProps, OmitType> &
{
  value?: ValueType
  defaultValue?: ValueType
  category?: GameCategoryType | 'all'
  filterNoCategories?: GameCategoryType[]
  filterNoGames?: GameType[]
  onChange?: (value: ValueType) => void
  multiple?: boolean
  noAll?: true
  withStatus?: boolean
  active?: true
  hasTournamentId?: boolean
  isHidingModifiedFeaturesHiddenGames?: boolean
}

const GameIdInput: React.FC<PropTypes> = React.memo((props) => {
  const {
    value = 'all',
    defaultValue,
    category,
    onChange,
    multiple = false,
    withStatus = true,
    label,
    noAll = false,
    active = false,
    hasTournamentId = false,
    filterNoCategories = [],
    filterNoGames = [],
    isHidingModifiedFeaturesHiddenGames = false
  } = props
  const { t } = useT()
  const [internValue, setInternValue] = useInternValue<ValueType>(defaultValue ?? value, value)
  const aliveGames = useGDKStore.platform.aliveGames()
  const gameOptions = useMemo(() => aliveGames
    .filter((game) => !filterNoCategories.includes(game.category))
    .filter((game) => multiple || !category || game.category === category)
    .filter((game) => !active || game.status !== GameStatus.STOP_SALE)
    .filter((game) => !hasTournamentId || game.has_tournament_id)
    .filter((game) => !filterNoGames.includes(game.id))
    .filter((game) => isHidingModifiedFeaturesHiddenGames ? !game.instance.hideModifyFeatures : true)
    .map((game) => ({ value: game.id, name: withStatus ? game.nameWithStatus : game.instance?.name ?? '' }))
  , [active, category, filterNoCategories, aliveGames, multiple, withStatus, hasTournamentId])
  const options = useMemo(() => {
    if (gameOptions.length === 0 && category !== 'all') return [{ name: t('common.noGame'), value: 'noGame' }]
    return [
      ...(!noAll ? [{ name: t('common.all'), value: 'all' }] : []),
      ...(category === 'all' ? [] : gameOptions)
    ]
  }, [category, gameOptions, noAll, t])
  if (multiple) {
    return (
      <MultipleSelector
        {...props}
        defaultValue={defaultValue as Array<string | number>}
        value={internValue as Array<string | number>}
        label={label ?? t('common.gamePlatform')}
        options={gameOptions}
        onChange={(value) => {
          setInternValue(value as MultipleValueType)
          if (onChange) onChange(value as MultipleValueType)
        }}
      />
    )
  }
  return (
    <DropDown
      {...props}
      value={internValue}
      label={label ?? t('common.gamePlatform')}
      options={options}
      onChange={(event) => {
        const value = event.target.value as SingleValueType
        setInternValue(value)
        if (onChange) onChange(value)
      }}
    />
  )
})

export default React.memo(GameIdInput)
