import React, { useContext, useCallback, createContext, useMemo } from 'react'
import Button from '@material-ui/core/Button'
import { DialogPayloadContext as ChannelDialogContext } from '../../../views/admin/externalGame/ExternalGameChannelPage'
import {
  FormType as ExternalGameChannelFormType,
  formToRequest
} from './ExternalGameChannelDialog'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createDefaultFormState } from '../../../utils/default/FormHook'
import { useGDKFuncHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { GameBase } from '@golden/game-china'

interface PropTypes {
  id: number
  game: GameBase
  form: ExternalGameChannelFormType
  reload: () => void
}

const ExternalGameChannelChangeButton: React.FC<PropTypes> = (props) => {
  const { id, game, form, reload } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const context = useMemo(() => createContext(createDefaultFormState(form)), [form])
  const globalDialog = useGlobalDialog()
  const [, setPayload] = useContext(ChannelDialogContext)
  const { handleSubmit } = useGDKFuncHandleSubmit({
    formToRequest: useMemo(() => formToRequest(game), [game]),
    gdkFunc: (payload) => gdk.platform.updateChannel(id, payload),
    gdkFuncDependencies: [gdk],
    onSuccess: () => {
      setPayload((payload) => ({
        ...payload,
        open: false
      }))
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        showCancel: false,
        message: t('dialog.updateSuccess')
      }))
      globalDialog.setOpen({
        id: `updateChannelSuccess-${id}`,
        value: true,
        isOK: false
      })
      reload()
    },
    onError: (error) => {
      setPayload((payload) => ({
        ...payload,
        open: false
      }))
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: true,
        variant: 'error',
        showCancel: false,
        message: error.message
      }))
      globalDialog.setOpen({
        id: `updateChannelFailure-${id}`,
        value: true,
        isOK: false
      })
    }
  })
  const handleCancel = useCallback(() => { setPayload((payload) => ({ ...payload, open: false })) }, [])
  return (
    <Button
      className={classes.blueGradualOutlineButton}
      classes={{
        disabled: classes.blueGradualOutlineDisabledButton
      }}
      onClick={useCallback(() => {
        setPayload((payload) => ({
          ...payload,
          onSubmit: handleSubmit,
          onCancel: handleCancel,
          open: true,
          defaultValue: form,
          context,
          gameId: game.id
        }))
      }, [game.id, context, form, handleSubmit])}
    >
      {t('common.update')}
    </Button>
  )
}

export default React.memo(ExternalGameChannelChangeButton)
