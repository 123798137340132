import React, { useMemo, createContext, useContext, useCallback } from 'react'
import { PaginationReq, OperationLogsReq, OperationLogsCategoryType, OperationLogsPlayerSettingMethod, OperationLogsPlayerPermissionMethod, OperationLogsPlayerInfoMethod, OperationLogsPlayerAccountMethod, OperationLogsCryptoWalletMethod, OperationLogsBankCardMethod, OperationLogsWithdrawalAccountMethod, PermissionType } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { SearchToRequestFunc, useRequestFromSearch, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { guaranteeNotUndefined, guaranteeBetween, pipe, parseInt, getTimeFromDateInputValue, getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import allRoutes from '../route/route'
import { createValidateStartAtWithEndAt, createValidateNotEmpty } from '../../../utils/default/Validator'
import { FormValidation, createDefaultFormState, ValueGetter, ChangedFormGetter } from '../../../utils/default/FormHook'
import { setToday, setYesterday, setThisMonth, setLastMonth, getAllowedTimeStamps, createShouldDisableFuture, getCorrectedDateRange } from '../../../utils/default/TimeHelper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

export type PlayerOperationLogsRequest = PaginationReq & OperationLogsReq

export interface PlayerOperationLogsType {
  time: DateInputValue
  category: OperationLogsCategoryType | '--'
  method: string
  account: string
}

export const searchToRequest: SearchToRequestFunc<PlayerOperationLogsRequest> = (search) => {
  const startTimeStamp = Number(search.start_at)
  const endTimeStamp = Number(search.end_at)
  const { minAllowedStartTimeStamp, maxAllowedEndTimeStamp } = getAllowedTimeStamps(startTimeStamp, endTimeStamp)
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, minAllowedStartTimeStamp, endTimeStamp)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, startTimeStamp, maxAllowedEndTimeStamp)
    )(search.end_at)
  } as PlayerOperationLogsRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as PlayerOperationLogsRequest
}

export const initialForm: InitialFormFunc<PlayerOperationLogsType> = (defaultForm) => ({
  time: setToday(),
  account: '',
  category: '--',
  method: '--',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<PlayerOperationLogsType> = {
  time: getTimeFromDateInputValue,
  account: getValueFromChangeEvent,
  category: getValueFromChangeEvent,
  method: getValueFromChangeEvent
}

const getChangedForm: ChangedFormGetter<PlayerOperationLogsType> = {
  category: (value, form) => ({
    ...form,
    category: value,
    method: '--'
  })
}

const formToRequest = (form: PlayerOperationLogsType): PlayerOperationLogsRequest => {
  const converted = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    account: form.account,
    category: form.category === '--' ? undefined : form.category,
    method: form.method === '--' ? undefined : form.method,
    page: 1
  } as PlayerOperationLogsRequest
  return omitBy(converted, isUndefined) as PlayerOperationLogsRequest
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => createShouldDisableFuture(), [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onChange = useCallback((value) => {
    handleChange('time')(getCorrectedDateRange(value))
  }, [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const MethodsDropDown: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)

  const methodOptions = useMemo(() => {
    const all = [
      {
        name: t('common.all'),
        value: '--'
      }
    ]

    const categoryOptionsMap = {
      [OperationLogsCategoryType.PLAYER_SETTING]: [
        {
          name: t('common.loginPassword'),
          value: OperationLogsPlayerSettingMethod.LOGIN_PASSWORD
        },
        {
          name: t('common.secretQuestion'),
          value: OperationLogsPlayerSettingMethod.SECRET_QUESTION
        },
        {
          name: t('common.wechatAccount'),
          value: OperationLogsPlayerSettingMethod.WECHAT_ACCOUNT
        },
        {
          name: t('common.qqAccount'),
          value: OperationLogsPlayerSettingMethod.QQ_ACCOUNT
        },
        {
          name: t('common.birthday'),
          value: OperationLogsPlayerSettingMethod.BIRTHDAY
        },
        {
          name: t('common.receiveAddress'),
          value: OperationLogsPlayerSettingMethod.ADDRESS
        }
      ],
      [OperationLogsCategoryType.PLAYER_PERMISSION]: [
        {
          name: `${t('common.finance')} ${t('common.warning')}`,
          value: OperationLogsPlayerPermissionMethod.FM_WARNING_ACCOUNT
        },
        {
          name: `${t('common.riskControl')} ${t('common.warning')}`,
          value: OperationLogsPlayerPermissionMethod.RC_WARNING_ACCOUNT
        },
        {
          name: t('common.freezeAccountPwdWrong'),
          value: OperationLogsPlayerPermissionMethod.FREEZE_ACCOUNT_PWD_WRONG
        },
        {
          name: t('common.firstWithdrawLimit'),
          value: OperationLogsPlayerPermissionMethod.IS_FIRST_WITHDRAW_LIMIT
        },
        {
          name: t('common.rmbWithdrawLimit'),
          value: OperationLogsPlayerPermissionMethod.IS_RMB_WITHDRAW_LIMIT
        },
        {
          name: t('roleType.smsVerifyBlocked'),
          value: OperationLogsPlayerPermissionMethod.SMS_VERIFY_BLOCKED
        },
        {
          name: t('common.freezeAccount'),
          value: OperationLogsPlayerPermissionMethod.FREEZE_ACCOUNT
        },
        {
          name: t('common.forbidActivity'),
          value: OperationLogsPlayerPermissionMethod.FORBID_ACTIVITY
        },
        {
          name: t('common.forbidBet'),
          value: OperationLogsPlayerPermissionMethod.FORBID_BET
        },
        {
          name: t('common.forbidDeposit'),
          value: OperationLogsPlayerPermissionMethod.FORBID_DEPOSIT
        },
        {
          name: t('common.forbidWithdraw'),
          value: OperationLogsPlayerPermissionMethod.FORBID_WITHDRAW
        },
        {
          name: t('common.forbidTransfer'),
          value: OperationLogsPlayerPermissionMethod.FORBID_TRANSFER
        }
      ],
      [OperationLogsCategoryType.PLAYER_INFO]: [
        {
          name: t('common.cellphone'),
          value: OperationLogsPlayerInfoMethod.CELLPHONE
        },
        {
          name: t('common.playerNickname'),
          value: OperationLogsPlayerInfoMethod.NICKNAME
        },
        {
          name: t('common.email'),
          value: OperationLogsPlayerInfoMethod.EMAIL
        },
        {
          name: t('common.siguaId'),
          value: OperationLogsPlayerInfoMethod.BUBBLEID
        },
        {
          name: t('common.financeMemo'),
          value: OperationLogsPlayerPermissionMethod.FINANCE_MEMO
        },
        {
          name: t('common.battleFieldMemo'),
          value: OperationLogsPlayerPermissionMethod.BATTLE_FILED_MEMO
        },
        {
          name: t('common.riskMemo'),
          value: OperationLogsPlayerPermissionMethod.RISK_MEMO
        }
      ],
      [OperationLogsCategoryType.PLAYER_ACCOUNT]: [
        {
          name: t('common.register'),
          value: OperationLogsPlayerAccountMethod.REGISTER
        },
        {
          name: t('common.forgetPassword'),
          value: OperationLogsPlayerAccountMethod.FORGET_PASSWORD
        }
      ],
      [OperationLogsCategoryType.CRYPTO_WALLET]: [
        {
          name: t('common.cryptoWalletData'),
          value: OperationLogsCryptoWalletMethod.CRYPTO_WALLET
        }
      ],
      [OperationLogsCategoryType.BANK_CARD]: [
        {
          name: t('common.debitCardInfo'),
          value: OperationLogsBankCardMethod.DEBIT_CARD
        }
      ],
      [OperationLogsCategoryType.WITHDRAWAL_ACCOUNT]: [
        {
          name: t('common.withdrawalAccountData'),
          value: OperationLogsWithdrawalAccountMethod.WITHDRAWAL_ACCOUNT
        }
      ]
    }

    const options = value.category === '--' ? [] : categoryOptionsMap[value.category]

    return all.concat(options)
  }, [t, value.category])

  const onChange = useCallback(handleChange('method'), [])
  if (value.category === '--') return null

  return (
    <Grid item xs={12} md={3}>
      <DropDown
        label={t('common.function')}
        options={methodOptions}
        value={value.method}
        onChange={onChange}
        fullWidth
      />
    </Grid>
  )
})

const PlayerOperationLogsForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const me = useGDKStore.admin.me()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        ...request
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.playerOperationLogs.encodePath
  })

  const validation = useMemo(() => ({
    time: [
      {
        func: createValidateStartAtWithEndAt('time', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    account: [],
    category: [
      {
        func: createValidateNotEmpty('category', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    method: []
  } as FormValidation<PlayerOperationLogsType>), [t])

  const categoryOptions = useMemo(() => [
    {
      name: t('common.all'),
      value: '--'
    },
    {
      name: t('common.account'),
      value: OperationLogsCategoryType.PLAYER_ACCOUNT
    },
    {
      name: t('common.playerInformation'),
      value: OperationLogsCategoryType.PLAYER_INFO,
      readable: [PermissionType.PLAYER_MANAGEMENT_READ, PermissionType.PLAYER_MANAGEMENT_INFO_READ]
    },
    {
      name: t('common.playerPermissionSetting'),
      value: OperationLogsCategoryType.PLAYER_PERMISSION,
      readable: [PermissionType.PLAYER_MANAGEMENT_READ, PermissionType.PLAYER_MANAGEMENT_PERMISSION_READ]
    },
    {
      name: t('common.playerCenterSetting'),
      value: OperationLogsCategoryType.PLAYER_SETTING,
      readable: [PermissionType.PLAYER_MANAGEMENT_READ, PermissionType.PLAYER_MANAGEMENT_CENTER_READ]
    },
    {
      name: t('common.debitCardData'),
      value: OperationLogsCategoryType.BANK_CARD,
      readable: [PermissionType.PLAYER_MANAGEMENT_READ, PermissionType.PLAYER_MANAGEMENT_DEBIT_CARD_READ]
    },
    {
      name: t('common.cryptoWalletData'),
      value: OperationLogsCategoryType.CRYPTO_WALLET,
      readable: [PermissionType.PLAYER_MANAGEMENT_READ, PermissionType.PLAYER_MANAGEMENT_CRYPTO_WALLET_READ]
    },
    {
      name: t('common.withdrawalAccountData'),
      value: OperationLogsCategoryType.WITHDRAWAL_ACCOUNT,
      readable: [PermissionType.PLAYER_MANAGEMENT_READ, PermissionType.PLAYER_MANAGEMENT_WITHDRAWAL_ACCOUNT_READ]
    }
  ].filter((item) => {
    if (!me) return false
    if (!item.readable) return true
    return item.readable.every((el) => me.permissions.includes(el))
  }), [me, t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.playerOperationLogs')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <DateInput />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<PlayerOperationLogsType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="account"
                      label={t('common.playerAccount')}
                      placeholder={t('placeholder.inputPlayerAccount')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<PlayerOperationLogsType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="category"
                      options={categoryOptions}
                      label={t('common.block')}
                      fullWidth
                    />
                  </Grid>
                  <MethodsDropDown />
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(PlayerOperationLogsForm)
