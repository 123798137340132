import React, { useMemo, useContext, useCallback, createContext } from 'react'
import { PaginationReq, GameCategoryType, GameType, FeedbackHistoryQuery, PlayerFeedbackSearchTimeType, PlayerFeedbackStatusType } from '@golden/gdk-admin'
import { omitBy, isUndefined, enumValues } from '@golden/utils'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import DateInputBase, { DateInputValue } from '../../../default/form/DateInput'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import useT from '../../../../i18ns/admin/useT'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import { SearchToRequestFunc, useRequestFromSearch, useChangeUrlSubmit, InitialFormFunc } from '../../../../utils/default/ComplexFlowHook'
import { guaranteeNotUndefined, guaranteeBetween, guaranteeBeOneOf, pipe, parseInt, acceptUndefined, getDateFromDateInputValue, getValueFromChangeEvent, guaranteeBeKey, convertEmptyToUndefined, getValueFromValue } from '../../../../utils/default/FormHelper'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import DropDown, { PropTypes as DropDownProps } from '../../../default/form/DropDown'
import FormStateProvider from '../../../default/form/FormStateProvider'
import FormField from '../../../default/form/FormField'
import FormSubmitButton from '../../../default/form/FormSubmitButton'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../../utils/default/FormHook'
import { setToday, setThisMonth, setYesterday, setLastMonth, getAllowedTimeStamps, createShouldDisableFuture, getCorrectedDateRange } from '../../../../utils/default/TimeHelper'
import allRoute from '../../route/route'
import { startOfDay, endOfDay, getTime, isValid } from 'date-fns'
import feedbackStatusName from '../../../../constants/default/feedbackStatusName'
import playerFeedbackSearchTimeName from '../../../../constants/admin/playerFeedbackSearchTimeName'
import { TextField, TextFieldProps } from '@material-ui/core'
import GameInput, { PropTypes as GameInputProps } from '../../GameInput'
export type PlayerReportFeedbackRequest = PaginationReq & FeedbackHistoryQuery

export interface PlayerReportFeedbackFormType {
  search_type: PlayerFeedbackSearchTimeType | '--'
  time: DateInputValue
  start: Date | null
  end: Date | null
  account: string
  serial_number: string
  search_status: PlayerFeedbackStatusType | '--'
  game: {
    game_category: GameCategoryType | 'all'
    game_id: GameType | 'all' | 'noGame'
  }
}

export const searchToRequest: SearchToRequestFunc<PlayerReportFeedbackRequest> = (search) => {
  const startTimeStamp = Number(search.start_at)
  const endTimeStamp = Number(search.end_at)
  const { minAllowedStartTimeStamp, maxAllowedEndTimeStamp } = getAllowedTimeStamps(startTimeStamp, endTimeStamp)
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    search_type: acceptUndefined(search.search_type, pipe(
      (value) => guaranteeBeKey(value, Object.keys(playerFeedbackSearchTimeName)),
      parseInt
    )),
    start_at: acceptUndefined(search.start_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, minAllowedStartTimeStamp, endTimeStamp)
    )),
    end_at: acceptUndefined(search.end_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, startTimeStamp, maxAllowedEndTimeStamp)
    )),
    game_category: acceptUndefined(search.game_category, pipe(
      (value) => guaranteeBeOneOf(Number(value), enumValues(GameCategoryType)),
      parseInt
    )),
    game_id: acceptUndefined(search.game_id, pipe(
      (value) => guaranteeBeOneOf(Number(value), enumValues(GameType)),
      parseInt
    )),
    search_status: acceptUndefined(search.search_status, pipe(
      (value) => guaranteeBeKey(value, Object.keys(feedbackStatusName))
    ))
  } as PlayerReportFeedbackRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as PlayerReportFeedbackRequest
}

export const initialForm: InitialFormFunc<PlayerReportFeedbackFormType> = (defaultForm) => ({
  time: setToday(),
  start: startOfDay(new Date()),
  end: endOfDay(new Date()),
  search_type: PlayerFeedbackSearchTimeType.CREATED_AT,
  game: {
    game_category: 'all',
    game_id: 'all'
  },
  account: '',
  serial_number: '',
  search_status: '--',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<PlayerReportFeedbackFormType> = {
  search_type: getValueFromChangeEvent,
  time: getDateFromDateInputValue,
  start: getValueFromChangeEvent,
  end: getValueFromChangeEvent,
  game: getValueFromValue,
  serial_number: getValueFromChangeEvent,
  account: getValueFromChangeEvent,
  search_status: getValueFromChangeEvent
}

const formToRequest = (form: PlayerReportFeedbackFormType): PlayerReportFeedbackRequest => {
  const converted = {
    search_type: form.search_type,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    game_category: form.game.game_category === 'all' ? undefined : form.game.game_category,
    game_id: form.game.game_id === 'all' || form.game.game_id === 'noGame' ? undefined : form.game.game_id,
    search_status: form.search_status === '--' ? undefined : form.search_status,
    account: convertEmptyToUndefined(form.account),
    serial_number: convertEmptyToUndefined(form.serial_number),
    page: 1
  } as PlayerReportFeedbackRequest
  const omitted = omitBy(converted, isUndefined)
  return omitted as PlayerReportFeedbackRequest
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const SearchDropDown: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange, handleOther, error } = useContext(FormContext)
  const searchTimeOptions = useMemo(() => {
    return (
      Object.keys(playerFeedbackSearchTimeName)
        .map((key) => Number(key) as PlayerFeedbackSearchTimeType)
        .map((key) => ({ name: t(playerFeedbackSearchTimeName[key]), value: key })) as any
    )
  }, [t])
  return (
    <Grid item xs={12} md={6} lg={2}>
      <DropDown
        value={value.search_type}
        onChange={handleChange('search_type')}
        onBlur={handleOther('blur', 'search_type')}
        options={searchTimeOptions}
        label={t('common.searchDate')}
        fullWidth
        error={error.search_type !== null}
        helperText={error.search_type ?? ''}
      />
    </Grid>
  )
})

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange, error } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => createShouldDisableFuture(), [value.time])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])

  const onChange = useCallback((value) => {
    handleChange('time')(getCorrectedDateRange(value))
  }, [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      FrontComponent={SearchDropDown}
      tools={tools}
      classes={dateClasses}
      startError={!!error.start}
      startHelperText={error.start ?? ''}
      endError={!!error.end}
      endHelperText={error.end ?? ''}
      onlyDate
    />
  )
})

const PlayerReportFeedbackForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        game: {
          game_category: request.game_category ?? 'all',
          game_id: request.game_id ?? 'all'
        },
        ...request
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.playerReportFeedbackHistory.encodePath
  })

  const validation = useMemo(() => ({
    search_type: [],
    time: [
      {
        func: (value: DateInputValue) => {
          return {
            isPass: (!!value.start && !!value.end && isValid(value.start) && isValid(value.end)),
            stop: !(!!value.start && !!value.end && isValid(value.start) && isValid(value.end)),
            newError: {
              start: !value.start ? t('error.mustNotEmpty') : (isValid(value.start) ? null : t('error.dateError')),
              end: !value.end ? t('error.mustNotEmpty') : (isValid(value.end) ? null : t('error.dateError'))
            }
          }
        },
        when: ['change', 'beforeClickSubmit']
      }
    ],
    start: [],
    end: [],
    account: [],
    game: [],
    serial_number: [],
    search_status: []
  } as FormValidation<PlayerReportFeedbackFormType>), [t])

  const statusOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(feedbackStatusName)
      .map((key) => ({
        name: t(feedbackStatusName[key as PlayerFeedbackStatusType]),
        value: key
      })) as any
  )

  return (
    <Paper>
      <Box
        paddingY={1.25}
        paddingX={2}
        className={classes.pinkTitleBar}
      >
        <Typography variant="h5">
          {t('page.feedbackHistory')}
        </Typography>
      </Box>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <FormStateProvider
            context={FormContext}
            defaultValue={defaultForm}
            onSubmit={handleSubmit}
            validation={validation}
            getValueFromEvent={getValueFromEvent}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <DateInput />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<PlayerReportFeedbackFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="account"
                      label={t('common.playerAccount')}
                      placeholder={t('placeholder.inputPlayerAccount')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<PlayerReportFeedbackFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="serial_number"
                      label={t('common.orderNumber')}
                      placeholder={t('placeholder.inputOrderNumber')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<PlayerReportFeedbackFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="search_status"
                      label={t('common.status')}
                      options={statusOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField<PlayerReportFeedbackFormType, GameInputProps>
                      context={FormContext}
                      component={GameInput}
                      name="game"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormStateProvider>
        </LoadingAndErrorFrame>
      </Box>
    </Paper>
  )
}

export default React.memo(PlayerReportFeedbackForm)
