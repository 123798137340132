import { PermissionType } from '@golden/gdk-admin'
import RouteKey from './RouteKey'
import RoutePermission from './RoutePermission'
import Permission from '../../../enums/default/Permission'
import RouteType from '../../default/route/RouteType'
import PathOptionType from '../../default/route/PathOptionType'
import TKeyType from '../../../i18ns/admin/TKeyType'
import { encodePath } from '../../../utils/default/RouteHelper'
import NotFoundPage from '../../default/frames/NotFoundPage'
import HomePage from '../../../views/admin/HomePage'
import LoginPage from '../../../views/admin/LoginPage'
import LogoutPage from '../../../views/admin/LogoutPage'
import AnnouncementPage from '../../../views/admin/forestage/AnnouncementPage'
import AgentAnnouncementPage from '../../../views/admin/forestage/AgentAnnouncementPage'
import AnnouncementCreatePage from '../../../views/admin/forestage/AnnouncementCreatePage'
import AnnouncementUpdatePage from '../../../views/admin/forestage/AnnouncementUpdatePage'
import RoleManagementPage from '../../../views/admin/manager/RoleManagementPage'
import RoleManagementCreatePage from '../../../views/admin/manager/RoleManagementCreatePage'
import RoleManagementUpdatePage from '../../../views/admin/manager/RoleManagementUpdatePage'
import ManagerLoginHistoryPage from '../../../views/admin/manager/ManagerLoginHistoryPage'
import AccountEmperorPage from '../../../views/admin/account/AccountEmperorPage'
import AccountEmperorLayerPage from '../../../views/admin/account/AccountEmperorLayerPage'
import AccountEmperorCreatePage from '../../../views/admin/account/AccountEmperorCreatePage'
import AccountEmperorUpdatePage from '../../../views/admin/account/AccountEmperorUpdatePage'
import AccountAgentWalletPage from '../../../views/admin/account/AccountAgentWalletPage'
import AccountAgentWalletCreateRechargePage from '../../../views/admin/account/AccountAgentWalletCreateRechargePage'
import AccountAgentWalletCreateRevokePage from '../../../views/admin/account/AccountAgentWalletCreateRevokePage'
import AccountAgentOperationLogPage from '../../../views/admin/account/AccountAgentOperationLogPage'
import ExternalGamePage from '../../../views/admin/externalGame/ExternalGamePage'
import BaseDataPage from '../../../views/admin/BaseDataPage'
import ManagerAccountPage from '../../../views/admin/manager/ManagerAccountPage'
import ManagerAccountCreatePage from '../../../views/admin/manager/ManagerAccountCreatePage'
import ManagerAccountUpdatePage from '../../../views/admin/manager/ManagerAccountUpdatePage'
import FinanceBlacklistPage from '../../../views/admin/finance/FinanceBlacklistPage'
import FinanceBlacklistCreatePage from '../../../views/admin/finance/FinanceBlacklistCreatePage'
import FinanceCourierPage from '../../../views/admin/finance/FinanceCourierPage'
import FinanceCourierCreatePage from '../../../views/admin/finance/FinanceCourierCreatePage'
import FinanceCourierUpdatePage from '../../../views/admin/finance/FinanceCourierUpdatePage'
import FinanceDepositWayPage from '../../../views/admin/finance/FinanceDepositWayPage'
import FinanceDepositWayCreatePage from '../../../views/admin/finance/FinanceDepositWayCreatePage'
import FinanceDepositWayUpdatePage from '../../../views/admin/finance/FinanceDepositWayUpdatePage'
import FinanceParameterPage from '../../../views/admin/finance/FinanceParameterPage'
import PlayerReportBetPage from '../../../views/admin/playerReport/PlayerReportBetPage'
import GreetingPage from '../../../views/admin/GreetingPage'
import PlayerReportTransferPage from '../../../views/admin/playerReport/PlayerReportTransferPage'
import JournalOperationPage from '../../../views/admin/journal/JournalOperationPage'
import PlayerIpPage from '../../../views/admin/player/PlayerIpPage'
import PlayerGameLoginHistoryPage from '../../../views/admin/player/PlayerGameLoginHistoryPage'
import PlayerIpMonitoringPage from '../../../views/admin/player/PlayerIPMonitoringPage'
import PlayerJumpShipPage from '../../../views/admin/player/PlayerJumpShipPage'
import PlayerReportProfitPage from '../../../views/admin/playerReport/PlayerReportProfitPage'
import PlayerReportProfitExportPage from '../../../views/admin/playerReport/PlayerReportProfitExportPage'
import AnnouncementNotificationPage from '../../../views/admin/forestage/AnnouncementNotificationPage'
import AnnouncementNotificationUpdatePage from '../../../views/admin/forestage/AnnouncementNotificationUpdatePage'
import PlayerJumpShipCreatePage from '../../../views/admin/player/PlayerJumpShipCreatePage'
import FinanceWithdrawalWayPage from '../../../views/admin/finance/FinanceWithdrawalWayPage'
import FinanceWithdrawalWayCreatePage from '../../../views/admin/finance/FinanceWithdrawalWayCreatePage'
import FinanceWithdrawalWayUpdatePage from '../../../views/admin/finance/FinanceWithdrawalWayUpdatePage'
import FinanceBankPage from '../../../views/admin/finance/FinanceBankPage'
import FinanceBankCodePage from '../../../views/admin/finance/FinanceBankCodePage'
import FinanceBankUpdatePage from '../../../views/admin/finance/FinanceBankUpdatePage'
import JournalEventPage from '../../../views/admin/journal/JournalEventPage'
import SystemGooglePage from '../../../views/admin/system/SystemGooglePage'
import AdminReportProfitPage from '../../../views/admin/adminReport/AdminReportProfitPage'
import AdminReportProfitLayerPage from '../../../views/admin/adminReport/AdminReportProfitLayerPage'
import DepositThirdPartySlipPage from '../../../views/admin/deposit/DepositThirdPartySlipPage'
import AdminReportDepositStatisticPage from '../../../views/admin/adminReport/AdminReportDepositStatisticPage'
import AdminReportDepositStatisticDetailPage from '../../../views/admin/adminReport/AdminReportDepositStatisticDetailPage'
import AdminReportPlatformFeePage from '../../../views/admin/adminReport/AdminReportPlatformFeePage'
import WithdrawalFMSlipPage from '../../../views/admin/withdrawal/WithdrawalFMSlipPage'
import WithdrawalRCSlipPage from '../../../views/admin/withdrawal/WithdrawalRCSlipPage'
import WithdrawalLimitPage from '../../../views/admin/withdrawal/WithdrawalLimitPage'
import WithdrawalLimitCreatePage from '../../../views/admin/withdrawal/WithdrawalLimitCreatePage'
import WithdrawalLimitUpdatePage from '../../../views/admin/withdrawal/WithdrawalLimitUpdatePage'
import WithdrawalTipPage from '../../../views/admin/withdrawal/WithdrawalTipPage'
import ExternalGameFeedbackPage from '../../../views/admin/externalGame/ExternalGameFeedbackPage'
import ExternalGameFeedbackUpdatePage from '../../../views/admin/externalGame/ExternalGameFeedbackUpdatePage'
import PlayerPage from '../../../views/admin/player/PlayerPage'
import PlayerDetailPage from '../../../views/admin/player/PlayerDetailPage'
import FinancePiggyBankPage from '../../../views/admin/finance/FinancePiggyBankPage'
import FinancePiggyBankCreatePage from '../../../views/admin/finance/FinancePiggyBankCreatePage'
import FinancePiggyBankUpdatePage from '../../../views/admin/finance/FinancePiggyBankUpdatePage'
import FinancePiggyWechatPage from '../../../views/admin/finance/FinancePiggyWechatPage'
import FinancePiggyWechatCreatePage from '../../../views/admin/finance/FinancePiggyWechatCreatePage'
import FinancePiggyWechatUpdatePage from '../../../views/admin/finance/FinancePiggyWechatUpdatePage'
import FinancePiggyAlipayPage from '../../../views/admin/finance/FinancePiggyAlipayPage'
import FinancePiggyAlipayCreatePage from '../../../views/admin/finance/FinancePiggyAlipayCreatePage'
import FinancePiggyAlipayUpdatePage from '../../../views/admin/finance/FinancePiggyAlipayUpdatePage'
import PlayerLayerPage from '../../../views/admin/player/PlayerLayerPage'
import PlayerLayerUpdatePage from '../../../views/admin/player/PlayerLayerUpdatePage'
import AdminReportNewPeopleStatisticPage from '../../../views/admin/adminReport/AdminReportNewPeopleStatisticPage'
import ActivityManagementPage from '../../../views/admin/activity/ActivityManagementPage'
import ActivityManagementLayoutCreatePage from '../../../views/admin/activity/ActivityManagementLayoutCreatePage'
import ActivityManagementLayoutUpdatePage from '../../../views/admin/activity/ActivityManagementLayoutUpdatePage'
import ActivityManagementLayoutSystemUpdatePage from '../../../views/admin/activity/ActivityManagementLayoutSystemUpdatePage'
import ActivityManagementBannerCreatePage from '../../../views/admin/activity/ActivityManagementBannerCreatePage'
import ActivityManagementBannerUpdatePage from '../../../views/admin/activity/ActivityManagementBannerUpdatePage'
import ActivityRecordPage from '../../../views/admin/activity/ActivityRecordPage'
import ActivityRecordSubPage from '../../../views/admin/activity/ActivityRecordSubPage'
import ActivityRecordAgentSubPage from '../../../views/admin/activity/ActivityRecordAgentSubPage'
import ActivityRecordDetailPage from '../../../views/admin/activity/ActivityRecordDetailPage'
import SystemIpWhitelistPage from '../../../views/admin/system/SystemIpWhitelistPage'
import SystemIpWhitelistCreatePage from '../../../views/admin/system/SystemIpWhitelistCreatePage'
import ManualDepositPage from '../../../views/admin/manual/ManualDepositPage'
import ManualDepositCreatePage from '../../../views/admin/manual/ManualDepositCreatePage'
import ManualWithdrawPage from '../../../views/admin/manual/ManualWithdrawPage'
import ManualWithdrawCreatePage from '../../../views/admin/manual/ManualWithdrawCreatePage'
import FinanceInternalWithdrawPage from '../../../views/admin/finance/FinanceInternalWithdrawPage'
import FinanceInternalWithdrawExecutePage from '../../../views/admin/finance/FinanceInternalWithdrawExecutePage'
import FinanceInternalWithdrawCreatePage from '../../../views/admin/finance/FinanceInternalWithdrawCreatePage'
import FinanceInternalWithdrawHistoryPage from '../../../views/admin/finance/FinanceInternalWithdrawHistoryPage'
import DepositSlipPage from '../../../views/admin/deposit/DepositSlipPage'
import PlayerReportCashPage from '../../../views/admin/playerReport/PlayerReportCashPage'
import ManualEffectiveCashUpdatePage from '../../../views/admin/manual/ManualEffectiveCashUpdatePage'
import ManualEffectiveCashPage from '../../../views/admin/manual/ManualEffectiveCashPage'
import AdminReportDeviceStatisticPage from '../../../views/admin/adminReport/AdminReportDeviceStatisticPage'
import AdminReportCompanyStatisticPage from '../../../views/admin/adminReport/AdminReportCompanyStatisticPage'
import AdminReportInAndOutPage from '../../../views/admin/adminReport/AdminReportInAndOutPage'
import DepositCrawlerPage from '../../../views/admin/deposit/DepositCrawlerPage'
import FinanceInternalWithdrawAccountPage from '../../../views/admin/finance/FinanceInternalWithdrawAccountPage'
import DepositErrorPage from '../../../views/admin/deposit/DepositErrorPage'
import AnnouncementPersonalPage from '../../../views/admin/forestage/AnnouncementPersonalPage'
import AnnouncementPersonalCreatePage from '../../../views/admin/forestage/AnnouncementPersonalCreatePage'
import AnnouncementPersonalTemplatePage from '../../../views/admin/forestage/AnnouncementPersonalTemplatePage'
import AnnouncementPersonalTemplateManagementPage from '../../../views/admin/forestage/AnnouncementPersonalTemplateManagementPage'
import PlayerRankPage from '../../../views/admin/player/PlayerRankPage'
import PlayerRankUpdatePage from '../../../views/admin/player/PlayerRankUpdatePage'
import AnnouncementSmsPage from '../../../views/admin/forestage/AnnouncementSmsPage'
import PlayerReportFetchTaskPage from '../../../views/admin/playerReport/PlayerReportFetchTaskPage'
import PlayerReportCashAccountingPage from '../../../views/admin/playerReport/PlayerReportCashAccountingPage'
import PlayerReportWinlossAnalyze from '../../../views/admin/playerReport/PlayerReportWinlossAnalyze'
import PlayerReportFeedbackHistoryPage from '../../../views/admin/playerReport/PlayerReportFeedbackHistoryPage'
import PlayerRankEffectiveBetPage from '../../../views/admin/player/PlayerRankEffectiveBetPage'
import PlayerRankEffectiveBetUpdatePage from '../../../views/admin/player/PlayerRankEffectiveBetUpdatePage'
import ExternalGameChannelPage from '../../../views/admin/externalGame/ExternalGameChannelPage'
import ExternalGameChannelCreatePage from '../../../views/admin/externalGame/ExternalGameChannelCreatePage'
import DownloadLinkSettingPage from '../../../views/admin/forestage/DownloadLinkSettingPage'
import JointVentureSettingPage from '../../../views/admin/forestage/JointVentureManagementPage'
import PlayerOperationLogsPage from '../../../views/admin/player/PlayerOperationLogsPage'
import PlayerReportTransferUpdatePage from '../../../views/admin/playerReport/PlayerReportTransferUpdatePage'
import ActivityApplicationPage from '../../../views/admin/activity/ActivityApplicationPage'
import ActivityManagementLayoutPreviewPage from '../../../views/admin/activity/ActivityManagementPreviewPage'
import AdminReportPlayerLeaderboardPage from '../../../views/admin/adminReport/AdminReportPlayerLeaderboardPage'
import AdminReportPlayerLeaderboardDetailPage from '../../../views/admin/adminReport/AdminReportPlayerLeaderboardDetailPage'
import AnnouncementLookPage from '../../../views/admin/forestage/AnnouncementLookPage'
import ActivityManagementBannerLookPage from '../../../views/admin/activity/ActivityManagementBannerLookPage'
import ActivityManagementLayoutLookPage from '../../../views/admin/activity/ActivityManagementLayoutLookPage'
import ActivityManagementLayoutSystemLookPage from '../../../views/admin/activity/ActivityManagementLayoutSystemLookPage'
import ActivityWalletPage from '../../../views/admin/activity/ActivityWalletPage'
import ActivityWalletCreatePage from '../../../views/admin/activity/ActivityWalletCreatePage'
import BannerNotificationPage from '../../../views/admin/forestage/BannerNotificationPage'
import BannerNotificationCreatePage from '../../../views/admin/forestage/BannerNotificationCreatePage'
import AdminReportTotalWinLoseSnapshot from '../../../views/admin/adminReport/AdminReportRevenueSnapshot'

export enum Path {
  NOT_FOUND = '*',
  HOME = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  BASE_DATA = '/base-data',
  GREETING = '/greeting',
  CS = '/cs',
  FORESTAGE_ANNOUNCEMENT = '/forestage/announcement',
  FORESTAGE_ANNOUNCEMENT_CREATE = '/forestage/announcement/create',
  FORESTAGE_ANNOUNCEMENT_UPDATE = '/forestage/announcement/update/:id',
  FORESTAGE_ANNOUNCEMENT_LOOK = '/forestage/announcement/look/:id',
  FORESTAGE_AGENT_ANNOUNCEMENT = '/forestage/agent-announcement',
  FORESTAGE_NOTIFICATION = '/forestage/notification',
  FORESTAGE_NOTIFICATION_UPDATE = '/forestage/notification/update/:id',
  FORESTAGE_PERSONAL = '/forestage/personal',
  FORESTAGE_PERSONAL_CREATE = '/forestage/personal/create/',
  FORESTAGE_PERSONAL_CREATE_BY_TEMPLATE = '/forestage/personal/create/:id',
  FORESTAGE_PERSONAL_TEMPLATE = '/forestage/personal/template',
  FORESTAGE_PERSONAL_TEMPLATE_MANAGEMENT = '/forestage/personal/template/management',
  FORESTAGE_SMS = '/forestage/sms',
  FORESTAGE_THIRD_PART_DOWNLOAD_LINK = '/forestage/download-link',
  FORESTAGE_JOINT_VENTURE_SETTING = '/forestage/joint-venture-setting',
  FORESTAGE_BANNER_NOTIFICATION = '/forestage/banner-notification',
  FORESTAGE_BANNER_NOTIFICATION_CREATE = '/forestage/banner-notification/create/:target/',
  FORESTAGE_BANNER_NOTIFICATION_COPY = '/forestage/banner-notification/copy/:target/:id',
  EXTERNAL_GAME = '/external-game/external-game',
  EXTERNAL_GAME_FEEDBACK = '/external-game/feedback',
  EXTERNAL_GAME_FEEDBACK_UPDATE = '/external-game/feedback/update/:id',
  EXTERNAL_GAME_CHANNEL = '/external-game/channel',
  EXTERNAL_GAME_CHANNEL_CREATE = '/external-game/channel/create',
  ACCOUNT_EMPEROR = '/account/emperor',
  ACCOUNT_EMPEROR_LAYER = '/account/emperor/:id/layer',
  ACCOUNT_EMPEROR_CREATE = '/account/emperor/create',
  ACCOUNT_EMPEROR_UPDATE = '/account/emperor/update/:id',
  ACCOUNT_AGENT_WALLET = '/account/agent-wallet',
  ACCOUNT_AGENT_WALLET_RECHARGE = '/account/agent-wallet/recharge',
  ACCOUNT_AGENT_WALLET_REVOKE = '/account/agent-wallet/revoke',
  ACCOUNT_AGENT_OPERATION_LOG = '/account/agent-operation-log',
  PLAYER_REPORT_BET = '/player-report/bet',
  PLAYER_REPORT_FETCH_TASK = '/player-report/bet/fetch-task',
  PLAYER_REPORT_TRANSFER = '/player-report/transfer',
  PLAYER_REPORT_TRANSFER_UPDATE = '/player-report/transfer/update/:account',
  PLAYER_REPORT_CASH = '/player-report/cash',
  PLAYER_REPORT_PROFIT = '/admin-report/player-profit',
  PLAYER_REPORT_PROFIT_EXPORT = '/admin-report/player-profit/export',
  PLAYER_REPORT_CASH_ACCOUNTING = '/player-report/cash-accounting',
  PLAYER_REPORT_WINLOSS_ANALYZE = '/player-report/winloss-analyze',
  PLAYER_REPORT_FEEDBACK_HISTORY = '/player-report/feedback-record',
  ADMIN_REPORT_PROFIT = '/admin-report/profit',
  ADMIN_REPORT_PROFIT_LAYER = '/admin-report/profit/:id/layer',
  ADMIN_REPORT_DEPOSIT_STATISTIC = '/admin-report/deposit-statistic',
  ADMIN_REPORT_DEPOSIT_STATISTIC_DETAIL = '/admin-report/deposit-statistic/detail',
  ADMIN_REPORT_NEW_PEOPLE_STATISTIC = '/admin-report/new-people-statistic',
  ADMIN_REPORT_DEVICE_STATISTIC = '/admin-report/device-statistic',
  ADMIN_REPORT_COMPANY_STATISTIC = '/admin-report/company-statistic',
  ADMIN_REPORT_IN_AND_OUT = '/admin-report/in-and-out',
  ADMIN_REPORT_PLATFORM_FEE = '/admin-report/platform-fee',
  ADMIN_REPORT_PLAYER_LEADERBOARD = '/admin-report/player-leaderboard',
  ADMIN_REPORT_PLAYER_LEADERBOARD_DETAIL = '/admin-report/player-leaderboard/:id',
  ADMIN_REPORT_REVENUE_SNAPSHOT = '/admin-report/revenue-snapshot',
  FINANCE_PARAMETER = '/finance/parameter',
  FINANCE_BLACKLIST = '/finance/blacklist',
  FINANCE_BLACKLIST_CREATE = '/finance/blacklist/create',
  FINANCE_COURIER = '/finance/courier',
  FINANCE_COURIER_CREATE = '/finance/courier/create',
  FINANCE_COURIER_UPDATE = '/finance/courier/update/:id',
  FINANCE_DEPOSIT_WAY = '/finance/deposit/way',
  FINANCE_DEPOSIT_WAY_CREATE = '/finance/deposit/way/create',
  FINANCE_DEPOSIT_WAY_UPDATE = '/finance/deposit/way/update/:id',
  FINIANCE_WITHDRAWAL_COURIER = '/finance/withdrawal/courier',
  FINANCE_WITHDRAWAL_COURIER_CREATE = '/finance/withdrawal/courier/create',
  FINANCE_WITHDRAWAL_COURIER_UPDATE = '/finance/withdrawal/courier/update/:id',
  FINANCE_WITHDRAWAL_WAY = '/finance/withdrawal/way',
  FINANCE_WITHDRAWAL_WAY_CREATE = '/finance/withdrawal/way/create',
  FINANCE_WITHDRAWAL_WAY_UPDATE = '/finance/withdrawal/way/update/:id',
  FINANCE_BANK = '/finance/bank',
  FINANCE_BANK_CODE = '/finance/bank/code',
  FINANCE_BANK_UPDATE = '/finance/bank/update/:id',
  FINANCE_PIGGY_BANK = '/finance/piggy-bank',
  FINANCE_PIGGY_BANK_CREATE = '/finance/piggy-bank/create',
  FINANCE_PIGGY_BANK_UPDATE = '/finance/piggy-bank/update/:id',
  FINANCE_PIGGY_WECHAT = '/finance/piggy-wechat',
  FINANCE_PIGGY_WECHAT_CREATE = '/finance/piggy-wechat/create',
  FINANCE_PIGGY_WECHAT_UPDATE = '/finance/piggy-wechat/update/:id',
  FINANCE_PIGGY_ALIPAY = '/finance/piggy-alipay',
  FINANCE_PIGGY_ALIPAY_CREATE = '/finance/piggy-alipay/create',
  FINANCE_PIGGY_ALIPAY_UPDATE = '/finance/piggy-alipay/update/:id',
  FINANCE_INTERNAL_WITHDRAW = '/finance/internal-withdraw',
  FINANCE_INTERNAL_WITHDRAW_ACCOUNT = '/finance/internal-withdraw/account',
  FINANCE_INTERNAL_WITHDRAW_CREATE = '/finance/internal-withdraw/create',
  FINANCE_INTERNAL_WITHDRAW_EXECUTE = '/finance/internal-withdraw/execute/:id',
  FINANCE_INTERNAL_WITHDRAW_HISTORY = '/finance/internal-withdraw/history',
  DEPOSIT_SLIP = '/deposit/slip',
  DEPOSIT_CRAWLER = '/deposit/crawler',
  DEPOSIT_THIRD_PARTY_SLIP = '/deposit/third-party-slip',
  DEPOSIT_ERROR = '/deposit/error',
  WITHDRAWAL_FM_SLIP = '/withdrawal/fm-slip',
  WITHDRAWAL_RC_SLIP = '/withdrawal/rc-slip',
  WITHDRAWAL_TIP = '/withdrawal/tip',
  WITHDRAWAL_LIMIT = '/withdrawal/limit',
  WITHDRAWAL_LIMIT_CREATE = '/withdrawal/limit/create',
  WITHDRAWAL_LIMIT_UPDATE = '/withdrawal/limit/update/:id',
  MANUAL_DEPSOT = '/manual/deposit',
  MANUAL_DEPSOT_CREATE = '/manual/deposit/create',
  MANUAL_WITHDRAW = '/manual/withdraw',
  MANUAL_WITHDRAW_CREATE = '/manual/withdraw/create',
  MANUAL_EFFECTIVE_CASH_HISTORY = '/manual/effective-cash',
  MANUAL_EFFECTIVE_CASH_UPDATE = '/manual/effective-cash/update/:account',
  SYSTEM_GOOGLE = '/system/google',
  SYSTEM_IP_WHITELIST = '/system/ip-whitelist',
  SYSTEM_IP_WHITELIST_CREATE = '/system/ip-whitelist/create',
  PLAYER_IP_MONITORING = '/player/ip-monitoring',
  PLAYER = '/player/player',
  PLAYER_DETAIL = '/player/player/detail/:id',
  PLAYER_IP = '/player/ip',
  PLAYER_GAME_LOGIN_HISTORY = '/player/player-game-login-history',
  PLAYER_JUMP_SHIP = '/player/jump-ship',
  PLAYER_JUMP_SHIP_CREATE = '/player/jump-ship/create',
  PLAYER_LAYER = '/player/layer',
  PLAYER_LAYER_CREATE = '/player/layer/create',
  PLAYER_LAYER_UPDATE = '/player/layer/update/:id',
  PLAYER_RANK = '/player/rank',
  PLAYER_RANK_UPDATE = '/player/rank/update/:id',
  PLAYER_RANK_EFFECTIVE_BET = '/player/rank-effective-bet',
  PLAYER_RANK_EFFECTIVE_BET_UPDATE = '/player/rank-effective-bet/update/:account',
  PLAYER_OPERATION_LOGS = '/player/operation-logs',
  ROLE_MANAGEMENT = '/manager/role',
  ROLE_MANAGEMENT_CREATE = '/manager/role/create',
  ROLE_MANAGEMENT_UPDATE = '/manager/role/update/:id',
  MANAGER_ACCOUNT = '/manager/account',
  MANAGER_ACCOUNT_CREATE = '/manager/account/create',
  MANAGER_ACCOUNT_UPDATE = '/manager/account/update/:id',
  MANAGER_LOGIN_HISTORY = '/manager/manager-login-history',
  JOURNAL_OPERATION = '/journal/operation',
  JOURNAL_EVENT = '/journal/event',
  ACTIVITY_MANAGEMENT = '/activity/management',
  ACTIVITY_MANAGEMENT_BANNER_CREATE = '/activity/management/banner/create',
  ACTIVITY_MANAGEMENT_BANNER_UPDATE = '/activity/management/banner/update/:id',
  ACTIVITY_MANAGEMENT_BANNER_LOOK = '/activity/management/banner/look/:id',
  ACTIVITY_MANAGEMENT_LAYOUT_CREATE = '/activity/management/layout/create',
  ACTIVITY_MANAGEMENT_LAYOUT_UPDATE = '/activity/management/layout/update/:id',
  ACTIVITY_MANAGEMENT_LAYOUT_LOOK = '/activity/management/layout/look/:id',
  ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_UPDATE = '/activity/management/layout-system/update/:id',
  ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_LOOK = '/activity/management/layout-system/look/:id',
  ACTIVITY_MANAGEMENT_PREVIEW = '/activity/management/preview/:type',
  ACTIVITY_RECORD = '/activity/record',
  ACTIVITY_RECORD_SUB = '/activity/record/sub',
  ACTIVITY_RECORD_AGENT_SUB = '/activity/record/agent/:id',
  ACTIVITY_RECORD_DETAIL = '/activity/record-detail',
  ACTIVITY_APPLICATION = '/activity/application',
  ACTIVITY_WALLET = '/activity/wallet',
  ACTIVITY_WALLET_CREATE = '/activity/wallet/create'
}

export const routeOrder: RouteKey[] = [
  RouteKey.HOME,
  RouteKey.LOGIN,
  RouteKey.LOGOUT,
  RouteKey.BASE_DATA,
  RouteKey.GREETING,
  RouteKey.FORESTAGE_ANNOUNCEMENT_UPDATE,
  RouteKey.FORESTAGE_ANNOUNCEMENT_CREATE,
  RouteKey.FORESTAGE_ANNOUNCEMENT_LOOK,
  RouteKey.FORESTAGE_ANNOUNCEMENT,
  RouteKey.FORESTAGE_AGENT_ANNOUNCEMENT,
  RouteKey.FORESTAGE_NOTIFICATION,
  RouteKey.FORESTAGE_NOTIFICATION_UPDATE,
  RouteKey.FORESTAGE_PERSONAL,
  RouteKey.FORESTAGE_PERSONAL_CREATE,
  RouteKey.FORESTAGE_PERSONAL_CREATE_BY_TEMPLATE,
  RouteKey.FORESTAGE_PERSONAL_TEMPLATE,
  RouteKey.FORESTAGE_PERSONAL_TEMPLATE_MANAGEMENT,
  RouteKey.FORESTAGE_SMS,
  RouteKey.FORESTAGE_DOWNLOAD_LINK,
  RouteKey.FORESTAGE_JOINT_VENTURE_SETTING,
  RouteKey.FORESTAGE_BANNER_NOTIFICATION,
  RouteKey.FORESTAGE_BANNER_NOTIFICATION_CREATE,
  RouteKey.FORESTAGE_BANNER_NOTIFICATION_COPY,
  RouteKey.EXTERNAL_GAME,
  RouteKey.EXTERNAL_GAME_FEEDBACK,
  RouteKey.EXTERNAL_GAME_FEEDBACK_UPDATE,
  RouteKey.EXTERNAL_GAME_CHANNEL,
  RouteKey.EXTERNAL_GAME_CHANNEL_CREATE,
  RouteKey.ACCOUNT_EMPEROR_UPDATE,
  RouteKey.ACCOUNT_EMPEROR_CREATE,
  RouteKey.ACCOUNT_EMPEROR_LAYER,
  RouteKey.ACCOUNT_EMPEROR,
  RouteKey.ACCOUNT_AGENT_WALLET,
  RouteKey.ACCOUNT_AGENT_WALLET_RECHARGE,
  RouteKey.ACCOUNT_AGENT_WALLET_REVOKE,
  RouteKey.ACCOUNT_AGENT_OPERATION_LOG,
  RouteKey.PLAYER_REPORT_BET,
  RouteKey.PLAYER_REPORT_FETCH_TASK,
  RouteKey.PLAYER_REPORT_TRANSFER,
  RouteKey.PLAYER_REPORT_TRANSFER_UPDATE,
  RouteKey.PLAYER_REPORT_CASH,
  RouteKey.PLAYER_REPORT_PROFIT,
  RouteKey.PLAYER_REPORT_PROFIT_EXPORT,
  RouteKey.PLAYER_REPORT_CASH_ACCOUNTING,
  RouteKey.PLAYER_REPORT_WINLOSS_ANALYZE,
  RouteKey.PLAYER_REPORT_FEEDBACK_HISTORY,
  RouteKey.ADMIN_REPORT_PROFIT,
  RouteKey.ADMIN_REPORT_PROFIT_LAYER,
  RouteKey.ADMIN_REPORT_DEPOSIT_STATISTIC,
  RouteKey.ADMIN_REPORT_DEPOSIT_STATISTIC_DETAIL,
  RouteKey.ADMIN_REPORT_NEW_PEOPLE_STATISTIC,
  RouteKey.ADMIN_REPORT_DEVICE_STATISTIC,
  RouteKey.ADMIN_REPORT_COMPANY_STATISTIC,
  RouteKey.ADMIN_REPORT_IN_AND_OUT,
  RouteKey.ADMIN_REPORT_PLATFORM_FEE,
  RouteKey.ADMIN_REPORT_PLAYER_LEADERBOARD,
  RouteKey.ADMIN_REPORT_PLAYER_LEADERBOARD_DETAIL,
  RouteKey.ADMIN_REPORT_REVENUE_SNAPSHOT,
  RouteKey.FINANCE_PARAMETER,
  RouteKey.FINANCE_BLACKLIST,
  RouteKey.FINANCE_BLACKLIST_CREATE,
  RouteKey.FINANCE_COURIER_UPDATE,
  RouteKey.FINANCE_COURIER_CREATE,
  RouteKey.FINANCE_COURIER,
  RouteKey.FINANCE_DEPOSIT_WAY_UPDATE,
  RouteKey.FINANCE_DEPOSIT_WAY_CREATE,
  RouteKey.FINANCE_DEPOSIT_WAY,
  RouteKey.FINANCE_WITHDRAWAL_WAY_UPDATE,
  RouteKey.FINANCE_WITHDRAWAL_WAY_CREATE,
  RouteKey.FINANCE_WITHDRAWAL_WAY,
  RouteKey.FINANCE_BANK_UPDATE,
  RouteKey.FINANCE_BANK,
  RouteKey.FINANCE_BANK_CODE,
  RouteKey.FINANCE_PIGGY_BANK,
  RouteKey.FINANCE_PIGGY_BANK_CREATE,
  RouteKey.FINANCE_PIGGY_BANK_UPDATE,
  RouteKey.FINANCE_PIGGY_WECHAT,
  RouteKey.FINANCE_PIGGY_WECHAT_CREATE,
  RouteKey.FINANCE_PIGGY_WECHAT_UPDATE,
  RouteKey.FINANCE_PIGGY_ALIPAY,
  RouteKey.FINANCE_PIGGY_ALIPAY_CREATE,
  RouteKey.FINANCE_PIGGY_ALIPAY_UPDATE,
  RouteKey.FINANCE_INTERNAL_WITHDRAW,
  RouteKey.FINANCE_INTERNAL_WITHDRAW_ACCOUNT,
  RouteKey.FINANCE_INTERNAL_WITHDRAW_CREATE,
  RouteKey.FINANCE_INTERNAL_WITHDRAW_EXECUTE,
  RouteKey.FINANCE_INTERNAL_WITHDRAW_HISTORY,
  RouteKey.DEPOSIT_CRAWLER,
  RouteKey.DEPOSIT_SLIP,
  RouteKey.DEPOSIT_THIRD_PARTY_SLIP,
  RouteKey.DEPOSIT_ERROR,
  RouteKey.WITHDRAWAL_FM_SLIP,
  RouteKey.WITHDRAWAL_RC_SLIP,
  RouteKey.WITHDRAWAL_TIP,
  RouteKey.WITHDRAWAL_LIMIT,
  RouteKey.WITHDRAWAL_LIMIT_CREATE,
  RouteKey.WITHDRAWAL_LIMIT_UPDATE,
  RouteKey.MANUAL_EFFECTIVE_CASH_HISTORY,
  RouteKey.MANUAL_EFFECTIVE_CASH_UPDATE,
  RouteKey.MANUAL_DEPOSIT,
  RouteKey.MANUAL_DEPOSIT_CREATE,
  RouteKey.MANUAL_WITHDRAW,
  RouteKey.MANUAL_WITHDRAW_CREATE,
  RouteKey.ACTIVITY_MANAGEMENT,
  RouteKey.ACTIVITY_MANAGEMENT_BANNER_CREATE,
  RouteKey.ACTIVITY_MANAGEMENT_BANNER_UPDATE,
  RouteKey.ACTIVITY_MANAGEMENT_BANNER_LOOK,
  RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_CREATE,
  RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_LOOK,
  RouteKey.ACTIVITY_MANAGEMENT_PREVIEW,
  RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_UPDATE,
  RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_UPDATE,
  RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_LOOK,
  RouteKey.ACTIVITY_RECORD,
  RouteKey.ACTIVITY_RECORD_SUB,
  RouteKey.ACTIVITY_RECORD_AGENT_SUB,
  RouteKey.ACTIVITY_RECORD_DETAIL,
  RouteKey.ACTIVITY_APPLICATION,
  RouteKey.ACTIVITY_WALLET,
  RouteKey.ACTIVITY_WALLET_CREATE,
  RouteKey.SYSTEM_GOOGLE,
  RouteKey.SYSTEM_IP_WHITELIST,
  RouteKey.SYSTEM_IP_WHITELIST_CREATE,
  RouteKey.PLAYER,
  RouteKey.PLAYER_DETAIL,
  RouteKey.PLAYER_IP,
  RouteKey.PLAYER_GAME_LOGIN_HISTORY,
  RouteKey.PLAYER_IP_MONITORING,
  RouteKey.PLAYER_JUMP_SHIP,
  RouteKey.PLAYER_JUMP_SHIP_CREATE,
  RouteKey.PLAYER_LAYER,
  RouteKey.PLAYER_LAYER_UPDATE,
  RouteKey.PLAYER_RANK,
  RouteKey.PLAYER_RANK_UPDATE,
  RouteKey.PLAYER_RANK_EFFECTIVE_BET,
  RouteKey.PLAYER_RANK_EFFECTIVE_BET_UPDATE,
  RouteKey.PLAYER_OPERATION_LOGS,
  RouteKey.ROLE_MANAGEMENT_UPDATE,
  RouteKey.ROLE_MANAGEMENT_CREATE,
  RouteKey.ROLE_MANAGEMENT,
  RouteKey.MANAGER_ACCOUNT_UPDATE,
  RouteKey.MANAGER_ACCOUNT_CREATE,
  RouteKey.MANAGER_ACCOUNT,
  RouteKey.MANAGER_LOGIN_HISTORY,
  RouteKey.JOURNAL_OPERATION,
  RouteKey.JOURNAL_EVENT,
  RouteKey.NOT_FOUND
]

const route: {
  [key in RouteKey]: RouteType<RouteKey, Path, RoutePermission, TKeyType> & {
    copyright: 'absolute' | 'relative' | null
    hideEventHandler?: boolean
    hideUpdate?: boolean
  }
} = {
  [RouteKey.HOME]: {
    key: RouteKey.HOME,
    title: '',
    path: Path.HOME,
    encodePath: (option: PathOptionType) => encodePath(Path.HOME, option),
    component: HomePage,
    noFrame: false,
    copyright: 'absolute',
    permissions: [
      { type: 'accessible', value: Permission.EVERYONE }
    ]
  },
  [RouteKey.LOGIN]: {
    key: RouteKey.LOGIN,
    title: 'page.login',
    path: Path.LOGIN,
    encodePath: (option: PathOptionType) => encodePath(Path.LOGIN, option),
    component: LoginPage,
    noFrame: true,
    copyright: 'absolute',
    permissions: [
      { type: 'accessible', value: Permission.NOT_LOGIN },
      { type: 'accessible', value: Permission.NOT_GOOGLE_AUTHENTICATOR },
      { type: 'accessible', value: Permission.EVERYONE },
      { type: 'writable', value: Permission.EVERYONE }
    ]
  },
  [RouteKey.LOGOUT]: {
    key: RouteKey.LOGOUT,
    title: 'page.logout',
    path: Path.LOGOUT,
    encodePath: (option: PathOptionType) => encodePath(Path.LOGOUT, option),
    component: LogoutPage,
    noFrame: false,
    copyright: 'absolute',
    permissions: [
      { type: 'accessible', value: Permission.NOT_LOGIN },
      { type: 'accessible', value: Permission.NOT_GOOGLE_AUTHENTICATOR },
      { type: 'accessible', value: Permission.EVERYONE }
    ]
  },
  [RouteKey.NOT_FOUND]: {
    key: RouteKey.NOT_FOUND,
    title: '',
    path: Path.NOT_FOUND,
    encodePath: (option: PathOptionType) => encodePath(Path.NOT_FOUND, option),
    component: NotFoundPage,
    noFrame: false,
    copyright: 'absolute',
    permissions: [
      { type: 'accessible', value: Permission.NOT_LOGIN },
      { type: 'accessible', value: Permission.NOT_GOOGLE_AUTHENTICATOR },
      { type: 'accessible', value: Permission.EVERYONE }
    ]
  },
  [RouteKey.BASE_DATA]: {
    key: RouteKey.BASE_DATA,
    title: 'page.baseData',
    path: Path.BASE_DATA,
    encodePath: (option) => encodePath(Path.BASE_DATA, option),
    component: BaseDataPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: Permission.NOT_GOOGLE_AUTHENTICATOR },
      { type: 'accessible', value: Permission.EVERYONE },
      { type: 'writable', value: Permission.EVERYONE }
    ]
  },
  [RouteKey.GREETING]: {
    key: RouteKey.GREETING,
    title: 'page.greeting',
    path: Path.GREETING,
    encodePath: (option: PathOptionType) => encodePath(Path.GREETING, option),
    component: GreetingPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: Permission.EVERYONE },
      { type: 'writable', value: Permission.EVERYONE }
    ]
  },
  [RouteKey.EXTERNAL_GAME]: {
    key: RouteKey.EXTERNAL_GAME,
    title: 'page.externalGame',
    path: Path.EXTERNAL_GAME,
    encodePath: (Option: PathOptionType) => encodePath(Path.EXTERNAL_GAME, Option),
    component: ExternalGamePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.EXTERNAL_GAME_SETTING },
      { type: 'accessible', value: PermissionType.EXTERNAL_GAME_SETTING_READ }
    ]
  },
  [RouteKey.EXTERNAL_GAME_FEEDBACK]: {
    key: RouteKey.EXTERNAL_GAME_FEEDBACK,
    title: 'page.feedbackSetting',
    path: Path.EXTERNAL_GAME_FEEDBACK,
    encodePath: (option) => encodePath(Path.EXTERNAL_GAME_FEEDBACK, option),
    component: ExternalGameFeedbackPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.FEEDBACK_SETTING_READ },
      { type: 'writable', value: PermissionType.FEEDBACK_SETTING }
    ]
  },
  [RouteKey.EXTERNAL_GAME_FEEDBACK_UPDATE]: {
    key: RouteKey.EXTERNAL_GAME_FEEDBACK_UPDATE,
    title: 'page.updateFeedbackSetting',
    path: Path.EXTERNAL_GAME_FEEDBACK_UPDATE,
    encodePath: (option) => encodePath(Path.EXTERNAL_GAME_FEEDBACK_UPDATE, option),
    component: ExternalGameFeedbackUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.FEEDBACK_SETTING },
      { type: 'writable', value: PermissionType.FEEDBACK_SETTING }
    ]
  },
  [RouteKey.EXTERNAL_GAME_CHANNEL]: {
    key: RouteKey.EXTERNAL_GAME_CHANNEL,
    title: 'page.channel',
    path: Path.EXTERNAL_GAME_CHANNEL,
    encodePath: (option) => encodePath(Path.EXTERNAL_GAME_CHANNEL, option),
    component: ExternalGameChannelPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.CHANNEL_SETTING },
      { type: 'accessible', value: PermissionType.CHANNEL_SETTING_READ }
    ]
  },
  [RouteKey.EXTERNAL_GAME_CHANNEL_CREATE]: {
    key: RouteKey.EXTERNAL_GAME_CHANNEL_CREATE,
    title: 'page.createChannel',
    path: Path.EXTERNAL_GAME_CHANNEL_CREATE,
    encodePath: (option) => encodePath(Path.EXTERNAL_GAME_CHANNEL_CREATE, option),
    component: ExternalGameChannelCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.CHANNEL_SETTING },
      { type: 'writable', value: PermissionType.CHANNEL_SETTING }
    ]
  },
  [RouteKey.PLAYER_REPORT_FETCH_TASK]: {
    key: RouteKey.PLAYER_REPORT_FETCH_TASK,
    title: 'page.fetchTask',
    path: Path.PLAYER_REPORT_FETCH_TASK,
    encodePath: (option) => encodePath(Path.PLAYER_REPORT_FETCH_TASK, option),
    component: PlayerReportFetchTaskPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.BET_RECORD_FETCH_READ },
      { type: 'writable', value: PermissionType.BET_RECORD_FETCH }
    ]
  },
  [RouteKey.FORESTAGE_ANNOUNCEMENT]: {
    key: RouteKey.FORESTAGE_ANNOUNCEMENT,
    title: 'page.announcement',
    path: Path.FORESTAGE_ANNOUNCEMENT,
    encodePath: (option: PathOptionType) => encodePath(Path.FORESTAGE_ANNOUNCEMENT, option),
    component: AnnouncementPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.ANNOUNCEMENT },
      { type: 'accessible', value: PermissionType.ANNOUNCEMENT_READ }
    ]
  },
  [RouteKey.FORESTAGE_ANNOUNCEMENT_CREATE]: {
    key: RouteKey.FORESTAGE_ANNOUNCEMENT_CREATE,
    title: 'page.createAnnouncement',
    path: Path.FORESTAGE_ANNOUNCEMENT_CREATE,
    encodePath: (option: PathOptionType) => encodePath(Path.FORESTAGE_ANNOUNCEMENT_CREATE, option),
    component: AnnouncementCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ANNOUNCEMENT },
      { type: 'writable', value: PermissionType.ANNOUNCEMENT }
    ]
  },
  [RouteKey.FORESTAGE_ANNOUNCEMENT_UPDATE]: {
    key: RouteKey.FORESTAGE_ANNOUNCEMENT_UPDATE,
    title: 'page.updateAnnouncement',
    path: Path.FORESTAGE_ANNOUNCEMENT_UPDATE,
    encodePath: (option: PathOptionType<{}, { id: number }>) => encodePath(Path.FORESTAGE_ANNOUNCEMENT_UPDATE, option),
    component: AnnouncementUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.ANNOUNCEMENT },
      { type: 'accessible', value: PermissionType.ANNOUNCEMENT }
    ]
  },
  [RouteKey.FORESTAGE_ANNOUNCEMENT_LOOK]: {
    key: RouteKey.FORESTAGE_ANNOUNCEMENT_LOOK,
    title: 'page.lookAnnouncement',
    path: Path.FORESTAGE_ANNOUNCEMENT_LOOK,
    encodePath: (option: PathOptionType<{}, { id: number }>) => encodePath(Path.FORESTAGE_ANNOUNCEMENT_LOOK, option),
    component: AnnouncementLookPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ANNOUNCEMENT_READ }
    ]
  },
  [RouteKey.FORESTAGE_AGENT_ANNOUNCEMENT]: {
    key: RouteKey.FORESTAGE_AGENT_ANNOUNCEMENT,
    title: 'page.agentAnnouncement',
    path: Path.FORESTAGE_AGENT_ANNOUNCEMENT,
    encodePath: (option: PathOptionType) => encodePath(Path.FORESTAGE_AGENT_ANNOUNCEMENT, option),
    component: AgentAnnouncementPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.AGENT_ANNOUNCEMENT },
      { type: 'accessible', value: PermissionType.AGENT_ANNOUNCEMENT_READ }
    ]
  },
  [RouteKey.ACCOUNT_EMPEROR]: {
    key: RouteKey.ACCOUNT_EMPEROR,
    title: 'page.emperor',
    path: Path.ACCOUNT_EMPEROR,
    encodePath: (option) => encodePath(Path.ACCOUNT_EMPEROR, option),
    component: AccountEmperorPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.EMPEROR_MANAGEMENT },
      { type: 'accessible', value: PermissionType.EMPEROR_MANAGEMENT_READ }
    ]
  },
  [RouteKey.ACCOUNT_EMPEROR_LAYER]: {
    key: RouteKey.ACCOUNT_EMPEROR_LAYER,
    title: 'page.emperorLayer',
    path: Path.ACCOUNT_EMPEROR_LAYER,
    encodePath: (option) => encodePath(Path.ACCOUNT_EMPEROR_LAYER, option),
    component: AccountEmperorLayerPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.EMPEROR_MANAGEMENT },
      { type: 'accessible', value: PermissionType.EMPEROR_MANAGEMENT_READ }
    ]
  },
  [RouteKey.ACCOUNT_EMPEROR_CREATE]: {
    key: RouteKey.ACCOUNT_EMPEROR_CREATE,
    title: 'page.createEmperor',
    path: Path.ACCOUNT_EMPEROR_CREATE,
    encodePath: (option) => encodePath(Path.ACCOUNT_EMPEROR_CREATE, option),
    component: AccountEmperorCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.EMPEROR_MANAGEMENT },
      { type: 'writable', value: PermissionType.EMPEROR_MANAGEMENT }
    ]
  },
  [RouteKey.ACCOUNT_AGENT_WALLET]: {
    key: RouteKey.ACCOUNT_AGENT_WALLET,
    title: 'page.agentWallet',
    path: Path.ACCOUNT_AGENT_WALLET,
    encodePath: (option) => encodePath(Path.ACCOUNT_AGENT_WALLET, option),
    component: AccountAgentWalletPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.AGENT_WALLET_MANAGEMENT },
      { type: 'accessible', value: PermissionType.AGENT_WALLET_MANAGEMENT_READ }
    ]
  },
  [RouteKey.ACCOUNT_AGENT_WALLET_RECHARGE]: {
    key: RouteKey.ACCOUNT_AGENT_WALLET_RECHARGE,
    title: 'page.agentWallet',
    path: Path.ACCOUNT_AGENT_WALLET_RECHARGE,
    encodePath: (option) => encodePath(Path.ACCOUNT_AGENT_WALLET_RECHARGE, option),
    component: AccountAgentWalletCreateRechargePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.AGENT_WALLET_MANAGEMENT },
      { type: 'writable', value: PermissionType.AGENT_WALLET_MANAGEMENT }
    ]
  },
  [RouteKey.ACCOUNT_AGENT_WALLET_REVOKE]: {
    key: RouteKey.ACCOUNT_AGENT_WALLET_REVOKE,
    title: 'page.agentWallet',
    path: Path.ACCOUNT_AGENT_WALLET_REVOKE,
    encodePath: (option) => encodePath(Path.ACCOUNT_AGENT_WALLET_REVOKE, option),
    component: AccountAgentWalletCreateRevokePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.AGENT_WALLET_MANAGEMENT },
      { type: 'writable', value: PermissionType.AGENT_WALLET_MANAGEMENT }
    ]
  },
  [RouteKey.ACCOUNT_EMPEROR_UPDATE]: {
    key: RouteKey.ACCOUNT_EMPEROR_UPDATE,
    title: 'page.updateEmperor',
    path: Path.ACCOUNT_EMPEROR_UPDATE,
    encodePath: (option) => encodePath(Path.ACCOUNT_EMPEROR_UPDATE, option),
    component: AccountEmperorUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.EMPEROR_MANAGEMENT },
      { type: 'writable', value: PermissionType.EMPEROR_MANAGEMENT }
    ]
  },
  [RouteKey.ACCOUNT_AGENT_OPERATION_LOG]: {
    key: RouteKey.ACCOUNT_AGENT_OPERATION_LOG,
    title: 'page.agentOperationLog',
    path: Path.ACCOUNT_AGENT_OPERATION_LOG,
    encodePath: (option) => encodePath(Path.ACCOUNT_AGENT_OPERATION_LOG, option),
    component: AccountAgentOperationLogPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.AGENT_OPERATION_LOG_READ }
    ]

  },
  [RouteKey.PLAYER_REPORT_BET]: {
    key: RouteKey.PLAYER_REPORT_BET,
    title: 'page.betHistory',
    path: Path.PLAYER_REPORT_BET,
    encodePath: (option) => encodePath(Path.PLAYER_REPORT_BET, option),
    component: PlayerReportBetPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.BET_RECORD_READ },
      { type: 'writable', value: PermissionType.BET_RECORD_EXPORT }
    ]
  },
  [RouteKey.PLAYER_REPORT_TRANSFER]: {
    key: RouteKey.PLAYER_REPORT_TRANSFER,
    title: 'page.transferHistory',
    path: Path.PLAYER_REPORT_TRANSFER,
    encodePath: (option) => encodePath(Path.PLAYER_REPORT_TRANSFER, option),
    component: PlayerReportTransferPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.TRANSFER_RECORD_READ },
      { type: 'writable', value: PermissionType.TRANSFER_RECORD_TRANSFER },
      { type: 'writable', value: PermissionType.TRANSFER_RECORD_REVIEW }
    ]
  },
  [RouteKey.PLAYER_REPORT_TRANSFER_UPDATE]: {
    key: RouteKey.PLAYER_REPORT_TRANSFER_UPDATE,
    title: 'common.transfer',
    path: Path.PLAYER_REPORT_TRANSFER_UPDATE,
    encodePath: (option) => encodePath(Path.PLAYER_REPORT_TRANSFER_UPDATE, option),
    component: PlayerReportTransferUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.TRANSFER_RECORD_READ },
      { type: 'writable', value: PermissionType.TRANSFER_RECORD_TRANSFER }
    ]
  },
  [RouteKey.PLAYER_REPORT_PROFIT]: {
    key: RouteKey.PLAYER_REPORT_PROFIT,
    title: 'page.playerReportProfit',
    path: Path.PLAYER_REPORT_PROFIT,
    encodePath: (option) => encodePath(Path.PLAYER_REPORT_PROFIT, option),
    component: PlayerReportProfitPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.PROFIT_RECORD_EXPORT },
      { type: 'accessible', value: PermissionType.PROFIT_RECORD_READ }
    ]
  },
  [RouteKey.PLAYER_REPORT_PROFIT_EXPORT]: {
    key: RouteKey.PLAYER_REPORT_PROFIT_EXPORT,
    title: 'page.playerReportProfitExport',
    path: Path.PLAYER_REPORT_PROFIT_EXPORT,
    encodePath: (option) => encodePath(Path.PLAYER_REPORT_PROFIT_EXPORT, option),
    component: PlayerReportProfitExportPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PROFIT_RECORD_EXPORT },
      { type: 'writable', value: PermissionType.PROFIT_RECORD_EXPORT }
    ]
  },
  [RouteKey.PLAYER_REPORT_CASH_ACCOUNTING]: {
    key: RouteKey.PLAYER_REPORT_CASH_ACCOUNTING,
    title: 'page.cashAccounting',
    path: Path.PLAYER_REPORT_CASH_ACCOUNTING,
    encodePath: (option) => encodePath(Path.PLAYER_REPORT_CASH_ACCOUNTING, option),
    component: PlayerReportCashAccountingPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.CASH_ACCOUNTING_READ }
    ]
  },
  [RouteKey.PLAYER_REPORT_WINLOSS_ANALYZE]: {
    key: RouteKey.PLAYER_REPORT_WINLOSS_ANALYZE,
    title: 'page.winlossAnalyze',
    path: Path.PLAYER_REPORT_WINLOSS_ANALYZE,
    encodePath: (option) => encodePath(Path.PLAYER_REPORT_WINLOSS_ANALYZE, option),
    component: PlayerReportWinlossAnalyze,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PLAYER_REPORT_WINLOSSANALYZE }
    ]
  },
  [RouteKey.PLAYER_REPORT_FEEDBACK_HISTORY]: {
    key: RouteKey.PLAYER_REPORT_FEEDBACK_HISTORY,
    title: 'page.feedbackHistory',
    path: Path.PLAYER_REPORT_FEEDBACK_HISTORY,
    encodePath: (option) => encodePath(Path.PLAYER_REPORT_FEEDBACK_HISTORY, option),
    component: PlayerReportFeedbackHistoryPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.FEEDBACK_HISTORY }
    ]
  },
  [RouteKey.FINANCE_PARAMETER]: {
    key: RouteKey.FINANCE_PARAMETER,
    title: 'page.financeParameter',
    path: Path.FINANCE_PARAMETER,
    encodePath: (option) => encodePath(Path.FINANCE_PARAMETER, option),
    component: FinanceParameterPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.FINANCE_SETTING },
      { type: 'accessible', value: PermissionType.FINANCE_SETTING_READ }
    ]
  },
  [RouteKey.FINANCE_BLACKLIST]: {
    key: RouteKey.FINANCE_BLACKLIST,
    title: 'page.blacklist',
    path: Path.FINANCE_BLACKLIST,
    encodePath: (option) => encodePath(Path.FINANCE_BLACKLIST, option),
    component: FinanceBlacklistPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.DEBIT_CARD_BLACKLIST },
      { type: 'accessible', value: PermissionType.DEBIT_CARD_BLACKLIST_READ }
    ]
  },
  [RouteKey.FINANCE_BLACKLIST_CREATE]: {
    key: RouteKey.FINANCE_BLACKLIST_CREATE,
    title: 'page.createBlacklist',
    path: Path.FINANCE_BLACKLIST_CREATE,
    encodePath: (option) => encodePath(Path.FINANCE_BLACKLIST_CREATE, option),
    component: FinanceBlacklistCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.DEBIT_CARD_BLACKLIST },
      { type: 'writable', value: PermissionType.DEBIT_CARD_BLACKLIST }
    ]
  },
  [RouteKey.FINANCE_COURIER]: {
    key: RouteKey.FINANCE_COURIER,
    title: 'page.courier',
    path: Path.FINANCE_COURIER,
    encodePath: (option) => encodePath(Path.FINANCE_COURIER, option),
    component: FinanceCourierPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.FINANCE_FORWARDER },
      { type: 'accessible', value: PermissionType.FINANCE_FORWARDER_READ }
    ]
  },
  [RouteKey.FINANCE_COURIER_CREATE]: {
    key: RouteKey.FINANCE_COURIER_CREATE,
    title: 'page.courier',
    path: Path.FINANCE_COURIER_CREATE,
    encodePath: (option) => encodePath(Path.FINANCE_COURIER_CREATE, option),
    component: FinanceCourierCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.FINANCE_FORWARDER },
      { type: 'writable', value: PermissionType.FINANCE_FORWARDER }
    ]
  },
  [RouteKey.FINANCE_COURIER_UPDATE]: {
    key: RouteKey.FINANCE_COURIER_UPDATE,
    title: 'page.courier',
    path: Path.FINANCE_COURIER_UPDATE,
    encodePath: (option) => encodePath(Path.FINANCE_COURIER_UPDATE, option),
    component: FinanceCourierUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.FINANCE_FORWARDER },
      { type: 'writable', value: PermissionType.FINANCE_FORWARDER }
    ]
  },
  [RouteKey.FINANCE_DEPOSIT_WAY]: {
    key: RouteKey.FINANCE_DEPOSIT_WAY,
    title: 'page.deposit',
    path: Path.FINANCE_DEPOSIT_WAY,
    encodePath: (option) => encodePath(Path.FINANCE_DEPOSIT_WAY, option),
    component: FinanceDepositWayPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.THIRD_PARTY_PAYMENT_SETTING },
      { type: 'accessible', value: PermissionType.THIRD_PARTY_PAYMENT_SETTING_READ }
    ]
  },
  [RouteKey.FINANCE_DEPOSIT_WAY_CREATE]: {
    key: RouteKey.FINANCE_DEPOSIT_WAY_CREATE,
    title: 'page.createDeposit',
    path: Path.FINANCE_DEPOSIT_WAY_CREATE,
    encodePath: (option) => encodePath(Path.FINANCE_DEPOSIT_WAY_CREATE, option),
    component: FinanceDepositWayCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.THIRD_PARTY_PAYMENT_SETTING },
      { type: 'writable', value: PermissionType.THIRD_PARTY_PAYMENT_SETTING }
    ]
  },
  [RouteKey.FINANCE_DEPOSIT_WAY_UPDATE]: {
    key: RouteKey.FINANCE_DEPOSIT_WAY_UPDATE,
    title: 'page.updateDeposit',
    path: Path.FINANCE_DEPOSIT_WAY_UPDATE,
    encodePath: (option) => encodePath(Path.FINANCE_DEPOSIT_WAY_UPDATE, option),
    component: FinanceDepositWayUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.THIRD_PARTY_PAYMENT_SETTING },
      { type: 'writable', value: PermissionType.THIRD_PARTY_PAYMENT_SETTING }
    ]
  },
  [RouteKey.FINANCE_WITHDRAWAL_WAY]: {
    key: RouteKey.FINANCE_WITHDRAWAL_WAY,
    title: 'page.withdrawal',
    path: Path.FINANCE_WITHDRAWAL_WAY,
    encodePath: (option) => encodePath(Path.FINANCE_WITHDRAWAL_WAY, option),
    component: FinanceWithdrawalWayPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.WITHDRAW_WAY_SETTING },
      { type: 'accessible', value: PermissionType.WITHDRAW_WAY_SETTING_READ }
    ]
  },
  [RouteKey.FINANCE_WITHDRAWAL_WAY_CREATE]: {
    key: RouteKey.FINANCE_WITHDRAWAL_WAY_CREATE,
    title: 'page.createWithdrawal',
    path: Path.FINANCE_WITHDRAWAL_WAY_CREATE,
    encodePath: (option) => encodePath(Path.FINANCE_WITHDRAWAL_WAY_CREATE, option),
    component: FinanceWithdrawalWayCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.WITHDRAW_WAY_SETTING },
      { type: 'writable', value: PermissionType.WITHDRAW_WAY_SETTING }
    ]
  },
  [RouteKey.FINANCE_WITHDRAWAL_WAY_UPDATE]: {
    key: RouteKey.FINANCE_WITHDRAWAL_WAY_UPDATE,
    title: 'page.updateWithdrawal',
    path: Path.FINANCE_WITHDRAWAL_WAY_UPDATE,
    encodePath: (option) => encodePath(Path.FINANCE_WITHDRAWAL_WAY_UPDATE, option),
    component: FinanceWithdrawalWayUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.WITHDRAW_WAY_SETTING },
      { type: 'writable', value: PermissionType.WITHDRAW_WAY_SETTING }
    ]
  },
  [RouteKey.MANAGER_LOGIN_HISTORY]: {
    key: RouteKey.MANAGER_LOGIN_HISTORY,
    title: 'page.managerLoginHistory',
    path: Path.MANAGER_LOGIN_HISTORY,
    encodePath: (option) => encodePath(Path.MANAGER_LOGIN_HISTORY, option),
    component: ManagerLoginHistoryPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.LOGIN_SEARCH_READ }
    ]
  },
  [RouteKey.ROLE_MANAGEMENT]: {
    key: RouteKey.ROLE_MANAGEMENT,
    title: 'page.roleManagement',
    path: Path.ROLE_MANAGEMENT,
    encodePath: (option) => encodePath(Path.ROLE_MANAGEMENT, option),
    component: RoleManagementPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.ROLE_GROUP_SETTING },
      { type: 'accessible', value: PermissionType.ROLE_GROUP_SETTING_READ }
    ]
  },
  [RouteKey.ROLE_MANAGEMENT_CREATE]: {
    key: RouteKey.ROLE_MANAGEMENT_CREATE,
    title: 'page.createRole',
    path: Path.ROLE_MANAGEMENT_CREATE,
    encodePath: (option) => encodePath(Path.ROLE_MANAGEMENT_CREATE, option),
    component: RoleManagementCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ROLE_GROUP_SETTING },
      { type: 'writable', value: PermissionType.ROLE_GROUP_SETTING }
    ]
  },
  [RouteKey.ROLE_MANAGEMENT_UPDATE]: {
    key: RouteKey.ROLE_MANAGEMENT_UPDATE,
    title: 'page.updateRole',
    path: Path.ROLE_MANAGEMENT_UPDATE,
    encodePath: (option) => encodePath(Path.ROLE_MANAGEMENT_UPDATE, option),
    component: RoleManagementUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ROLE_GROUP_SETTING },
      { type: 'writable', value: PermissionType.ROLE_GROUP_SETTING }
    ]
  },
  [RouteKey.MANAGER_ACCOUNT]: {
    key: RouteKey.MANAGER_ACCOUNT,
    title: 'page.accountManagement',
    path: Path.MANAGER_ACCOUNT,
    encodePath: (option) => encodePath(Path.MANAGER_ACCOUNT, option),
    component: ManagerAccountPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.COMPANY_ACCOUNT_MANAGEMENT },
      { type: 'accessible', value: PermissionType.COMPANY_ACCOUNT_MANAGEMENT_READ }
    ]
  },
  [RouteKey.MANAGER_ACCOUNT_CREATE]: {
    key: RouteKey.MANAGER_ACCOUNT_CREATE,
    title: 'page.createAccount',
    path: Path.MANAGER_ACCOUNT_CREATE,
    encodePath: (option) => encodePath(Path.MANAGER_ACCOUNT_CREATE, option),
    component: ManagerAccountCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.COMPANY_ACCOUNT_MANAGEMENT },
      { type: 'writable', value: PermissionType.COMPANY_ACCOUNT_MANAGEMENT }
    ]
  },
  [RouteKey.MANAGER_ACCOUNT_UPDATE]: {
    key: RouteKey.MANAGER_ACCOUNT_UPDATE,
    title: 'page.updateAccount',
    path: Path.MANAGER_ACCOUNT_UPDATE,
    encodePath: (option) => encodePath(Path.MANAGER_ACCOUNT_UPDATE, option),
    component: ManagerAccountUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.COMPANY_ACCOUNT_MANAGEMENT },
      { type: 'writable', value: PermissionType.COMPANY_ACCOUNT_MANAGEMENT }
    ]
  },
  [RouteKey.JOURNAL_OPERATION]: {
    key: RouteKey.JOURNAL_OPERATION,
    title: 'page.operationHistory',
    path: Path.JOURNAL_OPERATION,
    encodePath: (option) => encodePath(Path.JOURNAL_OPERATION, option),
    component: JournalOperationPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.OPERATION_RECORD_READ }
    ]
  },
  [RouteKey.PLAYER_IP_MONITORING]: {
    key: RouteKey.PLAYER_IP_MONITORING,
    title: 'page.ipMonitoring',
    path: Path.PLAYER_IP_MONITORING,
    encodePath: (option) => encodePath(Path.PLAYER_IP_MONITORING, option),
    component: PlayerIpMonitoringPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PLAYER_IP_MONITORING_HISTORY_READ }
    ]
  },
  [RouteKey.PLAYER_IP]: {
    key: RouteKey.PLAYER_IP,
    title: 'page.ipHistory',
    path: Path.PLAYER_IP,
    encodePath: (option) => encodePath(Path.PLAYER_IP, option),
    component: PlayerIpPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.LOGIN_IP_SEARCH_READ }
    ]
  },
  [RouteKey.PLAYER_GAME_LOGIN_HISTORY]: {
    key: RouteKey.PLAYER_GAME_LOGIN_HISTORY,
    title: 'page.gameLoginHistory',
    path: Path.PLAYER_GAME_LOGIN_HISTORY,
    encodePath: (option) => encodePath(Path.PLAYER_GAME_LOGIN_HISTORY, option),
    component: PlayerGameLoginHistoryPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PLAYER_GAME_LOGIN_HISTORY_READ }
    ]
  },
  [RouteKey.PLAYER_JUMP_SHIP]: {
    key: RouteKey.PLAYER_JUMP_SHIP,
    title: 'page.playerJumpShip',
    path: Path.PLAYER_JUMP_SHIP,
    encodePath: (option) => encodePath(Path.PLAYER_JUMP_SHIP, option),
    component: PlayerJumpShipPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.PLAYER_JUMP_SHIP },
      { type: 'accessible', value: PermissionType.PLAYER_JUMP_SHIP_READ }
    ]
  },
  [RouteKey.PLAYER_JUMP_SHIP_CREATE]: {
    key: RouteKey.PLAYER_JUMP_SHIP_CREATE,
    title: 'page.createPlayerJumpShip',
    path: Path.PLAYER_JUMP_SHIP_CREATE,
    encodePath: (option) => encodePath(Path.PLAYER_JUMP_SHIP_CREATE, option),
    component: PlayerJumpShipCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PLAYER_JUMP_SHIP },
      { type: 'writable', value: PermissionType.PLAYER_JUMP_SHIP }
    ]
  },
  [RouteKey.PLAYER_RANK_EFFECTIVE_BET]: {
    key: RouteKey.PLAYER_RANK_EFFECTIVE_BET,
    title: 'page.rankEffectiveBet',
    path: Path.PLAYER_RANK_EFFECTIVE_BET,
    encodePath: (option) => encodePath(Path.PLAYER_RANK_EFFECTIVE_BET, option),
    component: PlayerRankEffectiveBetPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.PLAYER_RANK_EFFECTIVE_BET },
      { type: 'accessible', value: PermissionType.PLAYER_RANK_EFFECTIVE_BET_READ }
    ]
  },
  [RouteKey.PLAYER_RANK_EFFECTIVE_BET_UPDATE]: {
    key: RouteKey.PLAYER_RANK_EFFECTIVE_BET_UPDATE,
    title: 'page.rankEffectiveBetUpdate',
    path: Path.PLAYER_RANK_EFFECTIVE_BET_UPDATE,
    encodePath: (option) => encodePath(Path.PLAYER_RANK_EFFECTIVE_BET_UPDATE, option),
    component: PlayerRankEffectiveBetUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PLAYER_RANK_EFFECTIVE_BET },
      { type: 'writable', value: PermissionType.PLAYER_RANK_EFFECTIVE_BET }
    ]
  },
  [RouteKey.PLAYER_OPERATION_LOGS]: {
    key: RouteKey.PLAYER_OPERATION_LOGS,
    title: 'page.playerOperationLogs',
    path: Path.PLAYER_OPERATION_LOGS,
    encodePath: (option) => encodePath(Path.PLAYER_OPERATION_LOGS, option),
    component: PlayerOperationLogsPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PLAYER_MANAGEMENT_PLAYER_LOG_READ }
    ]
  },
  [RouteKey.FORESTAGE_NOTIFICATION]: {
    key: RouteKey.FORESTAGE_NOTIFICATION,
    title: 'page.notification',
    path: Path.FORESTAGE_NOTIFICATION,
    encodePath: (option) => encodePath(Path.FORESTAGE_NOTIFICATION, option),
    component: AnnouncementNotificationPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.NOTIFICATION_SETTING },
      { type: 'accessible', value: PermissionType.NOTIFICATION_SETTING_READ }
    ]
  },
  [RouteKey.FORESTAGE_NOTIFICATION_UPDATE]: {
    key: RouteKey.FORESTAGE_NOTIFICATION_UPDATE,
    title: 'page.updateSystemNotification',
    path: Path.FORESTAGE_NOTIFICATION_UPDATE,
    encodePath: (option) => encodePath(Path.FORESTAGE_NOTIFICATION_UPDATE, option),
    component: AnnouncementNotificationUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.NOTIFICATION_SETTING },
      { type: 'writable', value: PermissionType.NOTIFICATION_SETTING }
    ]
  },
  [RouteKey.FINANCE_BANK]: {
    key: RouteKey.FINANCE_BANK,
    title: 'page.bank',
    path: Path.FINANCE_BANK,
    encodePath: (option) => encodePath(Path.FINANCE_BANK, option),
    component: FinanceBankPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.BANK_SETTING },
      { type: 'accessible', value: PermissionType.BANK_SETTING_READ }
    ]
  },
  [RouteKey.FINANCE_BANK_UPDATE]: {
    key: RouteKey.FINANCE_BANK_UPDATE,
    title: 'page.updateBank',
    path: Path.FINANCE_BANK_UPDATE,
    encodePath: (option) => encodePath(Path.FINANCE_BANK_UPDATE, option),
    component: FinanceBankUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.BANK_SETTING },
      { type: 'writable', value: PermissionType.BANK_SETTING }
    ]
  },
  [RouteKey.FINANCE_BANK_CODE]: {
    key: RouteKey.FINANCE_BANK_CODE,
    title: '',
    path: Path.FINANCE_BANK_CODE,
    encodePath: (option) => encodePath(Path.FINANCE_BANK_CODE, option),
    component: FinanceBankCodePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.BANK_SETTING_CODE },
      { type: 'writable', value: PermissionType.BANK_SETTING_CODE }
    ]
  },
  [RouteKey.JOURNAL_EVENT]: {
    key: RouteKey.JOURNAL_EVENT,
    title: 'page.warningLog',
    path: Path.JOURNAL_EVENT,
    encodePath: (option) => encodePath(Path.JOURNAL_EVENT, option),
    component: JournalEventPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ALERT_RECORD_READ }
    ]
  },
  [RouteKey.SYSTEM_GOOGLE]: {
    key: RouteKey.SYSTEM_GOOGLE,
    title: 'page.systemGoogle',
    path: Path.SYSTEM_GOOGLE,
    encodePath: (option) => encodePath(Path.SYSTEM_GOOGLE, option),
    component: SystemGooglePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.SYS_GOOGLE_AUTH },
      { type: 'accessible', value: PermissionType.SYS_GOOGLE_AUTH_READ }
    ]
  },
  [RouteKey.ADMIN_REPORT_PROFIT]: {
    key: RouteKey.ADMIN_REPORT_PROFIT,
    title: 'page.adminReportProfit',
    path: Path.ADMIN_REPORT_PROFIT,
    encodePath: (option) => encodePath(Path.ADMIN_REPORT_PROFIT, option),
    component: AdminReportProfitPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.COMPANY_PROFIT_RECORD_READ }
    ]
  },
  [RouteKey.ADMIN_REPORT_PROFIT_LAYER]: {
    key: RouteKey.ADMIN_REPORT_PROFIT_LAYER,
    title: 'page.adminReportProfitLayer',
    path: Path.ADMIN_REPORT_PROFIT_LAYER,
    encodePath: (option) => encodePath(Path.ADMIN_REPORT_PROFIT_LAYER, option),
    component: AdminReportProfitLayerPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.COMPANY_PROFIT_RECORD_READ }
    ]
  },
  [RouteKey.DEPOSIT_SLIP]: {
    key: RouteKey.DEPOSIT_SLIP,
    title: 'page.depositSlip',
    path: Path.DEPOSIT_SLIP,
    encodePath: (option) => encodePath(Path.DEPOSIT_SLIP, option),
    component: DepositSlipPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.GOLDEN_DEPOSIT_MANAGEMENT },
      { type: 'accessible', value: PermissionType.GOLDEN_DEPOSIT_MANAGEMENT_READ }
    ]
  },
  [RouteKey.DEPOSIT_THIRD_PARTY_SLIP]: {
    key: RouteKey.DEPOSIT_THIRD_PARTY_SLIP,
    title: 'page.thirdPartyDepositSlip',
    path: Path.DEPOSIT_THIRD_PARTY_SLIP,
    encodePath: (option) => encodePath(Path.DEPOSIT_THIRD_PARTY_SLIP, option),
    component: DepositThirdPartySlipPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.THIRD_PARTY_DEPOSIT_MANAGEMENT },
      { type: 'accessible', value: PermissionType.THIRD_PARTY_DEPOSIT_MANAGEMENT_READ }
    ]
  },
  [RouteKey.ADMIN_REPORT_DEPOSIT_STATISTIC]: {
    key: RouteKey.ADMIN_REPORT_DEPOSIT_STATISTIC,
    title: 'page.depositStatistic',
    path: Path.ADMIN_REPORT_DEPOSIT_STATISTIC,
    encodePath: (option) => encodePath(Path.ADMIN_REPORT_DEPOSIT_STATISTIC, option),
    component: AdminReportDepositStatisticPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.DEPOSIT_STATISTIC_RECORD_READ }
    ]
  },
  [RouteKey.ADMIN_REPORT_DEPOSIT_STATISTIC_DETAIL]: {
    key: RouteKey.ADMIN_REPORT_DEPOSIT_STATISTIC_DETAIL,
    title: 'page.depositStatistic',
    path: Path.ADMIN_REPORT_DEPOSIT_STATISTIC_DETAIL,
    encodePath: (option) => encodePath(Path.ADMIN_REPORT_DEPOSIT_STATISTIC_DETAIL, option),
    component: AdminReportDepositStatisticDetailPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.DEPOSIT_STATISTIC_RECORD_READ }
    ]
  },
  [RouteKey.WITHDRAWAL_RC_SLIP]: {
    key: RouteKey.WITHDRAWAL_RC_SLIP,
    title: 'page.withdrawRCManage',
    path: Path.WITHDRAWAL_RC_SLIP,
    encodePath: (option) => encodePath(Path.WITHDRAWAL_RC_SLIP, option),
    component: WithdrawalRCSlipPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.WITHDRAW_RC_SLIP },
      { type: 'writable', value: PermissionType.WITHDRAW_RC_SLIP_EXPORT },
      { type: 'accessible', value: PermissionType.WITHDRAW_RC_SLIP_READ }
    ]
  },
  [RouteKey.WITHDRAWAL_FM_SLIP]: {
    key: RouteKey.WITHDRAWAL_FM_SLIP,
    title: 'page.withdrawFMManage',
    path: Path.WITHDRAWAL_FM_SLIP,
    encodePath: (option) => encodePath(Path.WITHDRAWAL_FM_SLIP, option),
    component: WithdrawalFMSlipPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.WITHDRAW_FM_SLIP_READ },
      { type: 'writable', value: PermissionType.WITHDRAW_FM_SLIP },
      { type: 'writable', value: PermissionType.WITHDRAW_FM_SLIP_EXPORT }
    ]
  },
  [RouteKey.WITHDRAWAL_TIP]: {
    key: RouteKey.WITHDRAWAL_TIP,
    title: 'page.withdrawSetting',
    path: Path.WITHDRAWAL_TIP,
    encodePath: (option) => encodePath(Path.WITHDRAWAL_TIP, option),
    component: WithdrawalTipPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.WITHDRAW_TIP_SETTING },
      { type: 'accessible', value: PermissionType.WITHDRAW_TIP_SETTING_READ }
    ]
  },
  [RouteKey.WITHDRAWAL_LIMIT]: {
    key: RouteKey.WITHDRAWAL_LIMIT,
    title: 'page.withdrawLimit',
    path: Path.WITHDRAWAL_LIMIT,
    encodePath: (option) => encodePath(Path.WITHDRAWAL_LIMIT, option),
    component: WithdrawalLimitPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.WITHDRAW_LIMIT_SETTING },
      { type: 'accessible', value: PermissionType.WITHDRAW_LIMIT_SETTING_READ }
    ]
  },
  [RouteKey.WITHDRAWAL_LIMIT_CREATE]: {
    key: RouteKey.WITHDRAWAL_LIMIT_CREATE,
    title: 'page.createWithdrawLimit',
    path: Path.WITHDRAWAL_LIMIT_CREATE,
    encodePath: (option) => encodePath(Path.WITHDRAWAL_LIMIT_CREATE, option),
    component: WithdrawalLimitCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.WITHDRAW_LIMIT_SETTING },
      { type: 'writable', value: PermissionType.WITHDRAW_LIMIT_SETTING }
    ]
  },
  [RouteKey.WITHDRAWAL_LIMIT_UPDATE]: {
    key: RouteKey.WITHDRAWAL_LIMIT_UPDATE,
    title: 'page.updateWithdrawLimit',
    path: Path.WITHDRAWAL_LIMIT_UPDATE,
    encodePath: (option) => encodePath(Path.WITHDRAWAL_LIMIT_UPDATE, option),
    component: WithdrawalLimitUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.WITHDRAW_LIMIT_SETTING },
      { type: 'writable', value: PermissionType.WITHDRAW_LIMIT_SETTING }
    ]
  },
  [RouteKey.PLAYER]: {
    key: RouteKey.PLAYER,
    title: 'page.player',
    path: Path.PLAYER,
    encodePath: (option) => encodePath(Path.PLAYER, option),
    component: PlayerPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PLAYER_MANAGEMENT_READ }
    ]
  },
  [RouteKey.PLAYER_DETAIL]: {
    key: RouteKey.PLAYER_DETAIL,
    title: 'page.player',
    path: Path.PLAYER_DETAIL,
    encodePath: (option) => encodePath(Path.PLAYER_DETAIL, option),
    component: PlayerDetailPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PLAYER_MANAGEMENT_INFO_READ },
      { type: 'writable', value: PermissionType.PLAYER_MANAGEMENT_INFO_NICKNAME },
      { type: 'writable', value: PermissionType.PLAYER_MANAGEMENT_INFO_PHONE },
      { type: 'writable', value: PermissionType.PLAYER_MANAGEMENT_INFO_EMAIL },
      { type: 'writable', value: PermissionType.PLAYER_MANAGEMENT_INFO_RC_MEMO },
      { type: 'writable', value: PermissionType.PLAYER_MANAGEMENT_INFO_CS_MEMO },
      { type: 'writable', value: PermissionType.PLAYER_MANAGEMENT_INFO_FM_MEMO },
      { type: 'writable', value: PermissionType.PLAYER_MANAGEMENT_INFO_GOUBAO_UNBIND },
      { type: 'accessible', value: PermissionType.PLAYER_MANAGEMENT_PERMISSION },
      { type: 'writable', value: PermissionType.PLAYER_MANAGEMENT_PERMISSION },
      { type: 'accessible', value: PermissionType.PLAYER_MANAGEMENT_PERMISSION_READ },
      { type: 'writable', value: PermissionType.PLAYER_MANAGEMENT_CENTER },
      { type: 'accessible', value: PermissionType.PLAYER_MANAGEMENT_CENTER_READ },
      { type: 'writable', value: PermissionType.PLAYER_MANAGEMENT_DEBIT_CARD },
      { type: 'writable', value: PermissionType.PLAYER_MANAGEMENT_DEBIT_CARD_BIND },
      { type: 'accessible', value: PermissionType.PLAYER_MANAGEMENT_DEBIT_CARD_READ },
      { type: 'accessible', value: PermissionType.PLAYER_MANAGEMENT_TRANSACTION_READ },
      { type: 'accessible', value: PermissionType.PLAYER_MANAGEMENT_WALLET_READ },
      { type: 'writable', value: PermissionType.PLAYER_MANAGEMENT_WITHDRAWAL_ACCOUNT },
      { type: 'writable', value: PermissionType.PLAYER_MANAGEMENT_WITHDRAWAL_ACCOUNT_BIND },
      { type: 'accessible', value: PermissionType.PLAYER_MANAGEMENT_WITHDRAWAL_ACCOUNT_READ }
    ]
  },
  [RouteKey.FINANCE_PIGGY_BANK]: {
    key: RouteKey.FINANCE_PIGGY_BANK,
    title: 'page.piggyBank',
    path: Path.FINANCE_PIGGY_BANK,
    encodePath: (option) => encodePath(Path.FINANCE_PIGGY_BANK, option),
    component: FinancePiggyBankPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.PIGGY_BANK_MANAGEMENT },
      { type: 'accessible', value: PermissionType.PIGGY_BANK_MANAGEMENT_READ }
    ]
  },
  [RouteKey.FINANCE_PIGGY_BANK_CREATE]: {
    key: RouteKey.FINANCE_PIGGY_BANK_CREATE,
    title: 'page.createPiggyBank',
    path: Path.FINANCE_PIGGY_BANK_CREATE,
    encodePath: (option) => encodePath(Path.FINANCE_PIGGY_BANK_CREATE, option),
    component: FinancePiggyBankCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PIGGY_BANK_MANAGEMENT },
      { type: 'writable', value: PermissionType.PIGGY_BANK_MANAGEMENT }
    ]
  },
  [RouteKey.FINANCE_PIGGY_BANK_UPDATE]: {
    key: RouteKey.FINANCE_PIGGY_BANK_UPDATE,
    title: 'page.updatePiggyBank',
    path: Path.FINANCE_PIGGY_BANK_UPDATE,
    encodePath: (option) => encodePath(Path.FINANCE_PIGGY_BANK_UPDATE, option),
    component: FinancePiggyBankUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PIGGY_BANK_MANAGEMENT },
      { type: 'writable', value: PermissionType.PIGGY_BANK_MANAGEMENT }
    ]
  },
  [RouteKey.FINANCE_PIGGY_WECHAT]: {
    key: RouteKey.FINANCE_PIGGY_WECHAT,
    title: 'page.piggyWechat',
    path: Path.FINANCE_PIGGY_WECHAT,
    encodePath: (option) => encodePath(Path.FINANCE_PIGGY_WECHAT, option),
    component: FinancePiggyWechatPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.PIGGY_WECHAT_MANAGEMENT },
      { type: 'accessible', value: PermissionType.PIGGY_WECHAT_MANAGEMENT_READ }
    ]
  },
  [RouteKey.FINANCE_PIGGY_WECHAT_CREATE]: {
    key: RouteKey.FINANCE_PIGGY_WECHAT_CREATE,
    title: 'page.createPiggyWechat',
    path: Path.FINANCE_PIGGY_WECHAT_CREATE,
    encodePath: (option) => encodePath(Path.FINANCE_PIGGY_WECHAT_CREATE, option),
    component: FinancePiggyWechatCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PIGGY_WECHAT_MANAGEMENT },
      { type: 'writable', value: PermissionType.PIGGY_WECHAT_MANAGEMENT }
    ]
  },
  [RouteKey.FINANCE_PIGGY_WECHAT_UPDATE]: {
    key: RouteKey.FINANCE_PIGGY_WECHAT_UPDATE,
    title: 'page.updatePiggyWechat',
    path: Path.FINANCE_PIGGY_WECHAT_UPDATE,
    encodePath: (option) => encodePath(Path.FINANCE_PIGGY_WECHAT_UPDATE, option),
    component: FinancePiggyWechatUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PIGGY_WECHAT_MANAGEMENT },
      { type: 'writable', value: PermissionType.PIGGY_WECHAT_MANAGEMENT }
    ]
  },
  [RouteKey.FINANCE_PIGGY_ALIPAY]: {
    key: RouteKey.FINANCE_PIGGY_ALIPAY,
    title: 'page.piggyAlipay',
    path: Path.FINANCE_PIGGY_ALIPAY,
    encodePath: (option) => encodePath(Path.FINANCE_PIGGY_ALIPAY, option),
    component: FinancePiggyAlipayPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.PIGGY_ALIPAY_MANAGEMENT },
      { type: 'accessible', value: PermissionType.PIGGY_ALIPAY_MANAGEMENT_READ }
    ]
  },
  [RouteKey.FINANCE_PIGGY_ALIPAY_CREATE]: {
    key: RouteKey.FINANCE_PIGGY_ALIPAY_CREATE,
    title: 'page.createPiggyAlipay',
    path: Path.FINANCE_PIGGY_ALIPAY_CREATE,
    encodePath: (option) => encodePath(Path.FINANCE_PIGGY_ALIPAY_CREATE, option),
    component: FinancePiggyAlipayCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PIGGY_ALIPAY_MANAGEMENT },
      { type: 'writable', value: PermissionType.PIGGY_ALIPAY_MANAGEMENT }
    ]
  },
  [RouteKey.FINANCE_PIGGY_ALIPAY_UPDATE]: {
    key: RouteKey.FINANCE_PIGGY_ALIPAY_UPDATE,
    title: 'page.updatePiggyAlipay',
    path: Path.FINANCE_PIGGY_ALIPAY_UPDATE,
    encodePath: (option) => encodePath(Path.FINANCE_PIGGY_ALIPAY_UPDATE, option),
    component: FinancePiggyAlipayUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PIGGY_ALIPAY_MANAGEMENT },
      { type: 'writable', value: PermissionType.PIGGY_ALIPAY_MANAGEMENT }
    ]
  },
  [RouteKey.PLAYER_LAYER]: {
    key: RouteKey.PLAYER_LAYER,
    title: 'page.playerLayer',
    path: Path.PLAYER_LAYER,
    encodePath: (option) => encodePath(Path.PLAYER_LAYER, option),
    component: PlayerLayerPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.PLAYER_LAYER_MANAGEMENT },
      { type: 'accessible', value: PermissionType.PLAYER_LAYER_MANAGEMENT_READ }
    ]
  },
  [RouteKey.PLAYER_LAYER_UPDATE]: {
    key: RouteKey.PLAYER_LAYER_UPDATE,
    title: 'page.updatePlayerLayer',
    path: Path.PLAYER_LAYER_UPDATE,
    encodePath: (option) => encodePath(Path.PLAYER_LAYER_UPDATE, option),
    component: PlayerLayerUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PLAYER_LAYER_MANAGEMENT },
      { type: 'writable', value: PermissionType.PLAYER_LAYER_MANAGEMENT }
    ]
  },
  [RouteKey.ADMIN_REPORT_NEW_PEOPLE_STATISTIC]: {
    key: RouteKey.ADMIN_REPORT_NEW_PEOPLE_STATISTIC,
    title: 'page.newPeopleStatistic',
    path: Path.ADMIN_REPORT_NEW_PEOPLE_STATISTIC,
    encodePath: (option) => encodePath(Path.ADMIN_REPORT_NEW_PEOPLE_STATISTIC, option),
    component: AdminReportNewPeopleStatisticPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.NEW_PEOPLE_STATISTIC_RECORD_READ }
    ]
  },
  [RouteKey.SYSTEM_IP_WHITELIST]: {
    key: RouteKey.SYSTEM_IP_WHITELIST,
    title: 'page.ipWhitelist',
    path: Path.SYSTEM_IP_WHITELIST,
    encodePath: (option) => encodePath(Path.SYSTEM_IP_WHITELIST, option),
    component: SystemIpWhitelistPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.IP_WHITELIST },
      { type: 'accessible', value: PermissionType.IP_WHITELIST_READ }
    ]
  },
  [RouteKey.SYSTEM_IP_WHITELIST_CREATE]: {
    key: RouteKey.SYSTEM_IP_WHITELIST_CREATE,
    title: 'page.createIpWhitelist',
    path: Path.SYSTEM_IP_WHITELIST_CREATE,
    encodePath: (option) => encodePath(Path.SYSTEM_IP_WHITELIST_CREATE, option),
    component: SystemIpWhitelistCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.IP_WHITELIST },
      { type: 'writable', value: PermissionType.IP_WHITELIST }
    ]
  },
  [RouteKey.MANUAL_DEPOSIT]: {
    key: RouteKey.MANUAL_DEPOSIT,
    title: 'page.manualDeposit',
    path: Path.MANUAL_DEPSOT,
    encodePath: (option) => encodePath(Path.MANUAL_DEPSOT, option),
    component: ManualDepositPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.MANUAL_DEPOSIT_READ },
      { type: 'writable', value: PermissionType.MANUAL_DEPOSIT },
      { type: 'writable', value: PermissionType.MANUAL_DEPOSIT_EXPORT }
    ]
  },
  [RouteKey.MANUAL_DEPOSIT_CREATE]: {
    key: RouteKey.MANUAL_DEPOSIT_CREATE,
    title: 'page.createManualDeposit',
    path: Path.MANUAL_DEPSOT_CREATE,
    encodePath: (option) => encodePath(Path.MANUAL_DEPSOT_CREATE, option),
    component: ManualDepositCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.MANUAL_DEPOSIT },
      { type: 'writable', value: PermissionType.MANUAL_DEPOSIT }
    ]
  },
  [RouteKey.MANUAL_WITHDRAW]: {
    key: RouteKey.MANUAL_WITHDRAW,
    title: 'page.manualWithdraw',
    path: Path.MANUAL_WITHDRAW,
    encodePath: (option) => encodePath(Path.MANUAL_WITHDRAW, option),
    component: ManualWithdrawPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.MANUAL_WITHDRAW_READ },
      { type: 'writable', value: PermissionType.MANUAL_WITHDRAW },
      { type: 'writable', value: PermissionType.MANUAL_WITHDRAW_EXPORT }
    ]
  },
  [RouteKey.MANUAL_WITHDRAW_CREATE]: {
    key: RouteKey.MANUAL_WITHDRAW_CREATE,
    title: 'page.createManualWithdraw',
    path: Path.MANUAL_WITHDRAW_CREATE,
    encodePath: (option) => encodePath(Path.MANUAL_WITHDRAW_CREATE, option),
    component: ManualWithdrawCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.MANUAL_WITHDRAW },
      { type: 'writable', value: PermissionType.MANUAL_WITHDRAW }
    ]
  },
  [RouteKey.ACTIVITY_MANAGEMENT]: {
    key: RouteKey.ACTIVITY_MANAGEMENT,
    title: 'page.activityManagement',
    path: Path.ACTIVITY_MANAGEMENT,
    encodePath: (option) => encodePath(Path.ACTIVITY_MANAGEMENT, option),
    component: ActivityManagementPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_MANAGEMENT_READ },
      { type: 'writable', value: PermissionType.ACTIVITY_MANAGEMENT }
    ]
  },
  [RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_CREATE]: {
    key: RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_CREATE,
    title: 'page.createActivity',
    path: Path.ACTIVITY_MANAGEMENT_LAYOUT_CREATE,
    encodePath: (option) => encodePath(Path.ACTIVITY_MANAGEMENT_LAYOUT_CREATE, option),
    component: ActivityManagementLayoutCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_MANAGEMENT },
      { type: 'writable', value: PermissionType.ACTIVITY_MANAGEMENT }
    ]
  },
  [RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_LOOK]: {
    key: RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_LOOK,
    title: 'page.lookActivity',
    path: Path.ACTIVITY_MANAGEMENT_LAYOUT_LOOK,
    encodePath: (option) => encodePath(Path.ACTIVITY_MANAGEMENT_LAYOUT_LOOK, option),
    component: ActivityManagementLayoutLookPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_MANAGEMENT_READ }
    ]
  },
  [RouteKey.ACTIVITY_MANAGEMENT_PREVIEW]: {
    key: RouteKey.ACTIVITY_MANAGEMENT_PREVIEW,
    title: 'page.createActivity',
    path: Path.ACTIVITY_MANAGEMENT_PREVIEW,
    encodePath: (option) => encodePath(Path.ACTIVITY_MANAGEMENT_PREVIEW, option),
    component: ActivityManagementLayoutPreviewPage,
    noFrame: true,
    copyright: null,
    hideEventHandler: true,
    hideUpdate: true,
    permissions: [
      { type: 'writable', value: PermissionType.ACTIVITY_MANAGEMENT },
      { type: 'accessible', value: PermissionType.ACTIVITY_MANAGEMENT_READ }
    ]
  },
  [RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_UPDATE]: {
    key: RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_UPDATE,
    title: 'page.updateActivity',
    path: Path.ACTIVITY_MANAGEMENT_LAYOUT_UPDATE,
    encodePath: (option) => encodePath(Path.ACTIVITY_MANAGEMENT_LAYOUT_UPDATE, option),
    component: ActivityManagementLayoutUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.ACTIVITY_MANAGEMENT },
      { type: 'accessible', value: PermissionType.ACTIVITY_MANAGEMENT_READ }
    ]
  },
  [RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_UPDATE]: {
    key: RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_UPDATE,
    title: 'page.updateActivity',
    path: Path.ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_UPDATE,
    encodePath: (option) => encodePath(Path.ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_UPDATE, option),
    component: ActivityManagementLayoutSystemUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_MANAGEMENT },
      { type: 'writable', value: PermissionType.ACTIVITY_MANAGEMENT }
    ]
  },
  [RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_LOOK]: {
    key: RouteKey.ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_LOOK,
    title: 'page.lookActivity',
    path: Path.ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_LOOK,
    encodePath: (option) => encodePath(Path.ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_LOOK, option),
    component: ActivityManagementLayoutSystemLookPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_MANAGEMENT_READ }
    ]
  },
  [RouteKey.ACTIVITY_MANAGEMENT_BANNER_CREATE]: {
    key: RouteKey.ACTIVITY_MANAGEMENT_BANNER_CREATE,
    title: 'page.createActivity',
    path: Path.ACTIVITY_MANAGEMENT_BANNER_CREATE,
    encodePath: (option) => encodePath(Path.ACTIVITY_MANAGEMENT_BANNER_CREATE, option),
    component: ActivityManagementBannerCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_MANAGEMENT },
      { type: 'writable', value: PermissionType.ACTIVITY_MANAGEMENT }
    ]
  },
  [RouteKey.ACTIVITY_MANAGEMENT_BANNER_UPDATE]: {
    key: RouteKey.ACTIVITY_MANAGEMENT_BANNER_UPDATE,
    title: 'page.updateActivity',
    path: Path.ACTIVITY_MANAGEMENT_BANNER_UPDATE,
    encodePath: (option) => encodePath(Path.ACTIVITY_MANAGEMENT_BANNER_UPDATE, option),
    component: ActivityManagementBannerUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.ACTIVITY_MANAGEMENT },
      { type: 'accessible', value: PermissionType.ACTIVITY_MANAGEMENT }
    ]
  },
  [RouteKey.ACTIVITY_MANAGEMENT_BANNER_LOOK]: {
    key: RouteKey.ACTIVITY_MANAGEMENT_BANNER_LOOK,
    title: 'page.lookActivity',
    path: Path.ACTIVITY_MANAGEMENT_BANNER_LOOK,
    encodePath: (option) => encodePath(Path.ACTIVITY_MANAGEMENT_BANNER_LOOK, option),
    component: ActivityManagementBannerLookPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_MANAGEMENT_READ }
    ]
  },
  [RouteKey.ACTIVITY_RECORD]: {
    key: RouteKey.ACTIVITY_RECORD,
    title: 'page.activity',
    path: Path.ACTIVITY_RECORD,
    encodePath: (option) => encodePath(Path.ACTIVITY_RECORD, option),
    component: ActivityRecordPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_RECORD_READ }
    ]
  },
  [RouteKey.ACTIVITY_RECORD_SUB]: {
    key: RouteKey.ACTIVITY_RECORD_SUB,
    title: 'page.activity',
    path: Path.ACTIVITY_RECORD_SUB,
    encodePath: (option) => encodePath(Path.ACTIVITY_RECORD_SUB, option),
    component: ActivityRecordSubPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_RECORD_READ }
    ]
  },
  [RouteKey.ACTIVITY_RECORD_AGENT_SUB]: {
    key: RouteKey.ACTIVITY_RECORD_AGENT_SUB,
    title: 'page.activity',
    path: Path.ACTIVITY_RECORD_AGENT_SUB,
    encodePath: (option) => encodePath(Path.ACTIVITY_RECORD_AGENT_SUB, option),
    component: ActivityRecordAgentSubPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_RECORD_READ }
    ]
  },
  [RouteKey.ACTIVITY_RECORD_DETAIL]: {
    key: RouteKey.ACTIVITY_RECORD_DETAIL,
    title: 'page.activityDetail',
    path: Path.ACTIVITY_RECORD_DETAIL,
    encodePath: (option) => encodePath(Path.ACTIVITY_RECORD_DETAIL, option),
    component: ActivityRecordDetailPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_RECORD_DETAIL_READ }
    ]
  },
  [RouteKey.ACTIVITY_APPLICATION]: {
    key: RouteKey.ACTIVITY_APPLICATION,
    title: 'page.activityApplication',
    path: Path.ACTIVITY_APPLICATION,
    encodePath: (option) => encodePath(Path.ACTIVITY_APPLICATION, option),
    component: ActivityApplicationPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_APPLICATION_READ },
      { type: 'writable', value: PermissionType.ACTIVITY_APPLICATION },
      { type: 'writable', value: PermissionType.ACTIVITY_APPLICATION_EXPORT }
    ]
  },
  [RouteKey.ACTIVITY_WALLET]: {
    key: RouteKey.ACTIVITY_WALLET,
    title: 'page.activityWallet',
    path: Path.ACTIVITY_WALLET,
    encodePath: (option) => encodePath(Path.ACTIVITY_WALLET, option),
    component: ActivityWalletPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_WALLET_READ },
      { type: 'writable', value: PermissionType.ACTIVITY_WALLET_EXPORT },
      { type: 'writable', value: PermissionType.ACTIVITY_WALLET }
    ]
  },
  [RouteKey.ACTIVITY_WALLET_CREATE]: {
    key: RouteKey.ACTIVITY_WALLET_CREATE,
    title: 'page.activityWalletCreate',
    path: Path.ACTIVITY_WALLET_CREATE,
    encodePath: (option) => encodePath(Path.ACTIVITY_WALLET_CREATE, option),
    component: ActivityWalletCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.ACTIVITY_WALLET_READ },
      { type: 'writable', value: PermissionType.ACTIVITY_WALLET }
    ]
  },
  [RouteKey.FINANCE_INTERNAL_WITHDRAW]: {
    key: RouteKey.FINANCE_INTERNAL_WITHDRAW,
    title: 'page.internalWithdraw',
    path: Path.FINANCE_INTERNAL_WITHDRAW,
    encodePath: (option) => encodePath(Path.FINANCE_INTERNAL_WITHDRAW, option),
    component: FinanceInternalWithdrawPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.INTERNAL_WITHDRAW },
      { type: 'accessible', value: PermissionType.INTERNAL_WITHDRAW_READ }
    ]
  },
  [RouteKey.FINANCE_INTERNAL_WITHDRAW_CREATE]: {
    key: RouteKey.FINANCE_INTERNAL_WITHDRAW_CREATE,
    title: 'page.createInternalWithdraw',
    path: Path.FINANCE_INTERNAL_WITHDRAW_CREATE,
    encodePath: (option) => encodePath(Path.FINANCE_INTERNAL_WITHDRAW_CREATE, option),
    component: FinanceInternalWithdrawCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.INTERNAL_WITHDRAW },
      { type: 'writable', value: PermissionType.INTERNAL_WITHDRAW }
    ]
  },
  [RouteKey.FINANCE_INTERNAL_WITHDRAW_EXECUTE]: {
    key: RouteKey.FINANCE_INTERNAL_WITHDRAW_EXECUTE,
    title: 'page.internalWithdraw',
    path: Path.FINANCE_INTERNAL_WITHDRAW_EXECUTE,
    encodePath: (option) => encodePath(Path.FINANCE_INTERNAL_WITHDRAW_EXECUTE, option),
    component: FinanceInternalWithdrawExecutePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.INTERNAL_WITHDRAW },
      { type: 'writable', value: PermissionType.INTERNAL_WITHDRAW }
    ]
  },
  [RouteKey.FINANCE_INTERNAL_WITHDRAW_HISTORY]: {
    key: RouteKey.FINANCE_INTERNAL_WITHDRAW_HISTORY,
    title: 'page.internalWithdrawHistory',
    path: Path.FINANCE_INTERNAL_WITHDRAW_HISTORY,
    encodePath: (option) => encodePath(Path.FINANCE_INTERNAL_WITHDRAW_HISTORY, option),
    component: FinanceInternalWithdrawHistoryPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.INTERNAL_WITHDRAW },
      { type: 'accessible', value: PermissionType.INTERNAL_WITHDRAW_READ }
    ]
  },
  [RouteKey.PLAYER_REPORT_CASH]: {
    key: RouteKey.PLAYER_REPORT_CASH,
    title: 'page.cashLog',
    path: Path.PLAYER_REPORT_CASH,
    encodePath: (option) => encodePath(Path.PLAYER_REPORT_CASH, option),
    component: PlayerReportCashPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.CASH_LOG_RECORD_READ }
    ]
  },
  [RouteKey.MANUAL_EFFECTIVE_CASH_UPDATE]: {
    key: RouteKey.MANUAL_EFFECTIVE_CASH_UPDATE,
    title: 'page.updateEffectiveCash',
    path: Path.MANUAL_EFFECTIVE_CASH_UPDATE,
    encodePath: (option) => encodePath(Path.MANUAL_EFFECTIVE_CASH_UPDATE, option),
    component: ManualEffectiveCashUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.EFFECTIVE_CASH },
      { type: 'writable', value: PermissionType.EFFECTIVE_CASH }
    ]
  },
  [RouteKey.MANUAL_EFFECTIVE_CASH_HISTORY]: {
    key: RouteKey.MANUAL_EFFECTIVE_CASH_HISTORY,
    title: 'page.effectiveCashHistory',
    path: Path.MANUAL_EFFECTIVE_CASH_HISTORY,
    encodePath: (option) => encodePath(Path.MANUAL_EFFECTIVE_CASH_HISTORY, option),
    component: ManualEffectiveCashPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.EFFECTIVE_CASH },
      { type: 'accessible', value: PermissionType.EFFECTIVE_CASH_READ }
    ]
  },
  [RouteKey.ADMIN_REPORT_DEVICE_STATISTIC]: {
    key: RouteKey.ADMIN_REPORT_DEVICE_STATISTIC,
    title: 'page.deviceStatistic',
    path: Path.ADMIN_REPORT_DEVICE_STATISTIC,
    encodePath: (option) => encodePath(Path.ADMIN_REPORT_DEVICE_STATISTIC, option),
    component: AdminReportDeviceStatisticPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.DEVICE_STATISTIC_RECORD_READ }
    ]
  },
  [RouteKey.ADMIN_REPORT_COMPANY_STATISTIC]: {
    key: RouteKey.ADMIN_REPORT_COMPANY_STATISTIC,
    title: 'page.companyStatistic',
    path: Path.ADMIN_REPORT_COMPANY_STATISTIC,
    encodePath: (option) => encodePath(Path.ADMIN_REPORT_COMPANY_STATISTIC, option),
    component: AdminReportCompanyStatisticPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.COMPANY_STATISTIC_RECORD_READ }
    ]
  },
  [RouteKey.ADMIN_REPORT_IN_AND_OUT]: {
    key: RouteKey.ADMIN_REPORT_IN_AND_OUT,
    title: 'page.inAndOut',
    path: Path.ADMIN_REPORT_IN_AND_OUT,
    encodePath: (option) => encodePath(Path.ADMIN_REPORT_IN_AND_OUT, option),
    component: AdminReportInAndOutPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.IN_AND_OUT_RECORD_READ }
    ]
  },
  [RouteKey.DEPOSIT_CRAWLER]: {
    key: RouteKey.DEPOSIT_CRAWLER,
    title: 'page.depositCrawler',
    path: Path.DEPOSIT_CRAWLER,
    encodePath: (option) => encodePath(Path.DEPOSIT_CRAWLER, option),
    component: DepositCrawlerPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.DEPOSIT_CRAWLER_READ },
      { type: 'writable', value: PermissionType.DEPOSIT_CRAWLER }
    ]
  },
  [RouteKey.FINANCE_INTERNAL_WITHDRAW_ACCOUNT]: {
    key: RouteKey.FINANCE_INTERNAL_WITHDRAW_ACCOUNT,
    title: 'page.internalWithdrawAccount',
    path: Path.FINANCE_INTERNAL_WITHDRAW_ACCOUNT,
    encodePath: (option) => encodePath(Path.FINANCE_INTERNAL_WITHDRAW_ACCOUNT, option),
    component: FinanceInternalWithdrawAccountPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.INTERNAL_WITHDRAW },
      { type: 'accessible', value: PermissionType.INTERNAL_WITHDRAW_READ }
    ]
  },
  [RouteKey.CS]: {
    key: RouteKey.CS,
    title: '',
    path: Path.CS,
    encodePath: (option) => encodePath(Path.CS, option),
    component: NotFoundPage,
    noFrame: false,
    copyright: 'absolute',
    permissions: []
  },
  [RouteKey.ADMIN_REPORT_PLATFORM_FEE]: {
    key: RouteKey.ADMIN_REPORT_PLATFORM_FEE,
    title: 'page.platformFeeStatistic',
    path: Path.ADMIN_REPORT_PLATFORM_FEE,
    encodePath: (option) => encodePath(Path.ADMIN_REPORT_PLATFORM_FEE, option),
    component: AdminReportPlatformFeePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PLATFORM_FEE_RECORD_READ }
    ]
  },
  [RouteKey.DEPOSIT_ERROR]: {
    key: RouteKey.DEPOSIT_ERROR,
    title: 'page.depositError',
    path: Path.DEPOSIT_ERROR,
    encodePath: (option) => encodePath(Path.DEPOSIT_ERROR, option),
    component: DepositErrorPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.DEPOSIT_ERROR_READ }
    ]
  },
  [RouteKey.FORESTAGE_PERSONAL]: {
    key: RouteKey.FORESTAGE_PERSONAL,
    title: 'page.announcementPersonal',
    path: Path.FORESTAGE_PERSONAL,
    encodePath: (option) => encodePath(Path.FORESTAGE_PERSONAL, option),
    component: AnnouncementPersonalPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.SEND_MESSAGE },
      { type: 'accessible', value: PermissionType.SEND_MESSAGE_READ }
    ]
  },
  [RouteKey.FORESTAGE_PERSONAL_CREATE]: {
    key: RouteKey.FORESTAGE_PERSONAL_CREATE,
    title: 'page.announcementPersonalCreate',
    path: Path.FORESTAGE_PERSONAL_CREATE,
    encodePath: (option) => encodePath(Path.FORESTAGE_PERSONAL_CREATE, option),
    component: AnnouncementPersonalCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.SEND_MESSAGE },
      { type: 'accessible', value: PermissionType.SEND_MESSAGE }
    ]
  },
  [RouteKey.FORESTAGE_PERSONAL_CREATE_BY_TEMPLATE]: {
    key: RouteKey.FORESTAGE_PERSONAL_CREATE_BY_TEMPLATE,
    title: 'page.announcementPersonalCreate',
    path: Path.FORESTAGE_PERSONAL_CREATE_BY_TEMPLATE,
    encodePath: (option) => encodePath(Path.FORESTAGE_PERSONAL_CREATE_BY_TEMPLATE, option),
    component: AnnouncementPersonalCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.SEND_MESSAGE },
      { type: 'accessible', value: PermissionType.SEND_MESSAGE }
    ]
  },
  [RouteKey.FORESTAGE_PERSONAL_TEMPLATE]: {
    key: RouteKey.FORESTAGE_PERSONAL_TEMPLATE,
    title: 'page.announcementPersonalCreate',
    path: Path.FORESTAGE_PERSONAL_TEMPLATE,
    encodePath: (option) => encodePath(Path.FORESTAGE_PERSONAL_TEMPLATE, option),
    component: AnnouncementPersonalTemplatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.SEND_MESSAGE },
      { type: 'accessible', value: PermissionType.SEND_MESSAGE }
    ]
  },
  [RouteKey.FORESTAGE_PERSONAL_TEMPLATE_MANAGEMENT]: {
    key: RouteKey.FORESTAGE_PERSONAL_TEMPLATE_MANAGEMENT,
    title: 'page.announcementPersonalCreate',
    path: Path.FORESTAGE_PERSONAL_TEMPLATE_MANAGEMENT,
    encodePath: (option) => encodePath(Path.FORESTAGE_PERSONAL_TEMPLATE_MANAGEMENT, option),
    component: AnnouncementPersonalTemplateManagementPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.SEND_MESSAGE_TEMPLATE },
      { type: 'accessible', value: PermissionType.SEND_MESSAGE_TEMPLATE }
    ]
  },
  [RouteKey.PLAYER_RANK]: {
    key: RouteKey.PLAYER_RANK,
    title: 'page.playerRank',
    path: Path.PLAYER_RANK,
    encodePath: (option) => encodePath(Path.PLAYER_RANK, option),
    component: PlayerRankPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.PLAYER_RANK_MANAGEMENT },
      { type: 'accessible', value: PermissionType.PLAYER_RANK_MANAGEMENT_READ }
    ]
  },
  [RouteKey.PLAYER_RANK_UPDATE]: {
    key: RouteKey.PLAYER_RANK_UPDATE,
    title: 'page.playerRank',
    path: Path.PLAYER_RANK_UPDATE,
    encodePath: (option) => encodePath(Path.PLAYER_RANK_UPDATE, option),
    component: PlayerRankUpdatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PLAYER_RANK_MANAGEMENT },
      { type: 'writable', value: PermissionType.PLAYER_RANK_MANAGEMENT }
    ]
  },
  [RouteKey.FORESTAGE_SMS]: {
    key: RouteKey.FORESTAGE_SMS,
    title: 'page.sms',
    path: Path.FORESTAGE_SMS,
    encodePath: (option) => encodePath(Path.FORESTAGE_SMS, option),
    component: AnnouncementSmsPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.SMS_RECORD_RESEND },
      { type: 'accessible', value: PermissionType.SMS_RECORD_READ }
    ]
  },
  [RouteKey.FORESTAGE_DOWNLOAD_LINK]: {
    key: RouteKey.FORESTAGE_DOWNLOAD_LINK,
    title: 'page.downloadLink',
    path: Path.FORESTAGE_THIRD_PART_DOWNLOAD_LINK,
    encodePath: (option) => encodePath(Path.FORESTAGE_THIRD_PART_DOWNLOAD_LINK, option),
    component: DownloadLinkSettingPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.FORWARDER_DOWNLOAD_LINK_SETTING },
      { type: 'accessible', value: PermissionType.FORWARDER_DOWNLOAD_LINK_SETTING_READ }
    ]
  },
  [RouteKey.FORESTAGE_JOINT_VENTURE_SETTING]: {
    key: RouteKey.FORESTAGE_JOINT_VENTURE_SETTING,
    title: 'page.jointVenture',
    path: Path.FORESTAGE_JOINT_VENTURE_SETTING,
    encodePath: (option) => encodePath(Path.FORESTAGE_JOINT_VENTURE_SETTING, option),
    component: JointVentureSettingPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.JOINT_VENTURE_LINK_SETTING },
      { type: 'accessible', value: PermissionType.JOINT_VENTURE_LINK_SETTING_READ }
    ]
  },
  [RouteKey.ADMIN_REPORT_PLAYER_LEADERBOARD]: {
    key: RouteKey.ADMIN_REPORT_PLAYER_LEADERBOARD,
    title: 'page.playerLeaderboard',
    path: Path.ADMIN_REPORT_PLAYER_LEADERBOARD,
    encodePath: (option) => encodePath(Path.ADMIN_REPORT_PLAYER_LEADERBOARD, option),
    component: AdminReportPlayerLeaderboardPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PLAYER_LEADERBOARD_READ }
    ]
  },
  [RouteKey.ADMIN_REPORT_PLAYER_LEADERBOARD_DETAIL]: {
    key: RouteKey.ADMIN_REPORT_PLAYER_LEADERBOARD_DETAIL,
    title: 'page.playerLeaderboard',
    path: Path.ADMIN_REPORT_PLAYER_LEADERBOARD_DETAIL,
    encodePath: (option) => encodePath(Path.ADMIN_REPORT_PLAYER_LEADERBOARD_DETAIL, option),
    component: AdminReportPlayerLeaderboardDetailPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.PLAYER_LEADERBOARD_READ }
    ]
  },
  [RouteKey.ADMIN_REPORT_REVENUE_SNAPSHOT]: {
    key: RouteKey.ADMIN_REPORT_REVENUE_SNAPSHOT,
    title: 'page.revenueSnapshot',
    path: Path.ADMIN_REPORT_REVENUE_SNAPSHOT,
    encodePath: (option) => encodePath(Path.ADMIN_REPORT_REVENUE_SNAPSHOT, option),
    component: AdminReportTotalWinLoseSnapshot,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'accessible', value: PermissionType.REVENUE_SNAPSHOT_READ }
    ]
  },
  [RouteKey.FORESTAGE_BANNER_NOTIFICATION]: {
    key: RouteKey.FORESTAGE_BANNER_NOTIFICATION,
    title: 'page.bannerNotification',
    path: Path.FORESTAGE_BANNER_NOTIFICATION,
    encodePath: (option) => encodePath(Path.FORESTAGE_BANNER_NOTIFICATION, option),
    component: BannerNotificationPage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.BANNER_NOTIFICATION },
      { type: 'accessible', value: PermissionType.BANNER_NOTIFICATION_READ }
    ]
  },
  [RouteKey.FORESTAGE_BANNER_NOTIFICATION_CREATE]: {
    key: RouteKey.FORESTAGE_BANNER_NOTIFICATION_CREATE,
    title: 'page.bannerNotification',
    path: Path.FORESTAGE_BANNER_NOTIFICATION_CREATE,
    encodePath: (option) => encodePath(Path.FORESTAGE_BANNER_NOTIFICATION_CREATE, option),
    component: BannerNotificationCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.BANNER_NOTIFICATION },
      { type: 'accessible', value: PermissionType.BANNER_NOTIFICATION }
    ]
  },
  [RouteKey.FORESTAGE_BANNER_NOTIFICATION_COPY]: {
    key: RouteKey.FORESTAGE_BANNER_NOTIFICATION_COPY,
    title: 'page.bannerNotification',
    path: Path.FORESTAGE_BANNER_NOTIFICATION_COPY,
    encodePath: (option) => encodePath(Path.FORESTAGE_BANNER_NOTIFICATION_COPY, option),
    component: BannerNotificationCreatePage,
    noFrame: false,
    copyright: 'relative',
    permissions: [
      { type: 'writable', value: PermissionType.BANNER_NOTIFICATION },
      { type: 'accessible', value: PermissionType.BANNER_NOTIFICATION }
    ]
  }
}

export default route
