import React, { useState, createContext, useMemo, useContext, useEffect, Dispatch, SetStateAction, useCallback } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import Tooltip from '@material-ui/core/Tooltip'
import { useCommonStyles, useDetailStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useGDKFuncHandleSubmit, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { WithdrawSlipDetail, AdminWithdrawStatusType, Branch, WithdrawWayType, AdminModule, CryptoProtocolType, AccountWarnedType } from '@golden/gdk-admin'
import { BcMath, RoundingMode } from '@golden/bcmath'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { formatDateTime, formatMoney } from '../../../utils/default/TableHelper'
import adminWithdrawStatusName from '../../../constants/admin/adminWithdrawStatusName'
import adminWithdrawStatusColor from '../../../constants/admin/adminWithdrawStatusColor'
import rcStatusColor from '../../../constants/admin/rcStatusColor'
import rcStatusName from '../../../constants/admin/rcStatusName'
import WithdrawalCashAccountingTable from './WithdrawalCashAccountingTable'
import WithdrawalTransferTable from './WithdrawalTransferTable'
import EditWithdrawalFMSlipForm, { WithdrawSlipType, WithdrawSlipFormType, initialForm } from './EditWithdrawalFMSlipForm'
import { ChangedFormGetter, createDefaultFormState, FormState, FormValidation, ValueGetter } from '../../../utils/default/FormHook'
import FormStateProvider from '../../default/form/FormStateProvider'
import { getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import { createValidateNotEmpty, createCorrectResult, createErrorResult, createValidateCash } from '../../../utils/default/Validator'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import WithdrawalFMSlipCourierDetail from './WithdrawalFMSlipCourierDetail'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import PlayerIsWarnedIcon from '../PlayerIsWarnedIcon'
import { uniq } from '@golden/utils'
import StateProvider from '../../../providers/default/StateProvider'
import useTimeBasedValue from '../../../utils/default/TimeBasedValueHook'
import { of, map } from 'rxjs'

import allRoutes from '../route/route'
import { getTime, startOfDay, endOfDay, subDays } from 'date-fns'
import { Link } from 'react-router-dom'
import { protocolTypeName } from '../../../constants/default/protocolTypeName'

const useStyles = makeStyles((theme) => ({
  cell: {
    width: '25%',
    padding: theme.spacing(1.5)
  },
  log: {
    background: theme.palette.grey[200]
  },
  tableFont: {
    fontSize: 12
  }
}))

const getValueFromEvent: ValueGetter<WithdrawSlipFormType> = {
  type: getValueFromChangeEvent,
  cashCrypto: getValueFromChangeEvent,
  memo: getValueFromChangeEvent,
  courier: getValueFromChangeEvent,
  code: getValueFromChangeEvent
}

const formToRequest = (form: WithdrawSlipFormType): WithdrawSlipFormType => form

export const FormContext = createContext<FormState<WithdrawSlipFormType>>(createDefaultFormState(initialForm()))

const FormSubmitButton: React.FC<{ loading: boolean }> = React.memo((props) => {
  const { loading } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const { disableSubmit, value } = useContext(FormContext)
  if (value.type === WithdrawSlipType.CANCEL) {
    return (
      <Button
        disabled={disableSubmit || loading}
        className={commonClasses.purpleGradualButton}
        type="submit"
      >
        {loading
          ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={24} />
          </Box>
            )
          : t('withdrawSlipType.cancel')}
      </Button>
    )
  }
  return (
    <Button
      disabled={disableSubmit || loading || value.type === WithdrawSlipType.PENDING}
      className={commonClasses.purpleGradualButton}
      type="submit"
    >
      {loading
        ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={24} />
        </Box>
          )
        : t('common.confirmWithdraw')}
    </Button>
  )
})

export interface Payload {
  id: number
  open: boolean
  type: WithdrawSlipType
  readonly: boolean
}

export const defaultPayload: Payload = {
  id: 0,
  open: false,
  type: WithdrawSlipType.PENDING,
  readonly: true
}

export const DialogPayloadContext = createContext<[
  Payload,
  Dispatch<SetStateAction<Payload>>
]>([
  defaultPayload,
  () => {}
])

interface PropTypes {
  reload: () => void
}

const defaultValue = {
  sell: '1.0000', // 分母不可為 0
  min: '00',
  sec: '00',
  loading: false
}

export const Context = createContext<[WithdrawExchangeRateType, Dispatch<SetStateAction<WithdrawExchangeRateType>>]>([
  defaultValue,
  () => {}
])

const useWithdrawExchangeRate = (gdk: AdminModule, payload: Payload) => {
  return useTimeBasedValue(
    useCallback(
      () => (payload.id)
        ? gdk.withdraw.getWithdrawSlipExchangeRate(payload.id).pipe(
          map((data) => ({ ...data, sell: Number(data.sell) === 0 ? '1.0000' : data.sell }))
        )
        : of({ sell: '1.0000', expired_at: null })
      ,
      [gdk, payload]
    ),
    defaultValue
  )
}

export type WithdrawExchangeRateType = ReturnType<typeof useWithdrawExchangeRate>

const ButtonLinkToIpMonitoring: React.FC<{ id: number, account: string }> = (props) => {
  const { account } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const past = getTime(startOfDay(subDays(new Date(), 30)))
  const endOfToday = getTime(endOfDay(new Date()))

  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={allRoutes.playerIpMonitoring.encodePath({ search: { start_at: past, end_at: endOfToday, account, page: 1 }, param: {} })}
      target="_blank"
    >
      <Button
        className={commonClasses.blueGradualOutlineButton}
      >
        {t('page.ipMonitoring')}
      </Button>
    </Link>
  )
}

const ExchangeRateProvider: React.FC<PropTypes> = (props) => {
  const gdk = useGDK()
  const [payload] = useContext(DialogPayloadContext)
  const exchangeRate = useWithdrawExchangeRate(gdk, payload)
  const [, setInfo] = useContext(Context)

  useEffect(() => {
    setInfo(exchangeRate)
  }, [exchangeRate])

  return (
    <WithdrawalFMSlipDetailDialogContent {...props} sell={exchangeRate.sell} />
  )
}

const WithdrawalFMSlipDetailDialog: React.FC<PropTypes> = (props) => {
  return (
    <StateProvider
      context={Context}
      defaultValue={defaultValue}
    >
      <ExchangeRateProvider {...props} />
    </StateProvider>
  )
}

const WithdrawalFMSlipDetailDialogContent: React.FC<PropTypes & { sell: string }> = React.memo((props) => {
  const { reload, sell } = props
  const [payload, setPayload] = useContext(DialogPayloadContext)
  const { id, open, readonly } = payload
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const detailClasses = useDetailStyles()
  const globalDialog = useGlobalDialog()
  const { t } = useT()
  const gdk = useGDK()
  const me = useGDKStore.admin.me()
  const writable = useChecker()
  const detailPageFlow = usePageFlow()
  const [detail, setDetail] = useState<WithdrawSlipDetail | null>(null)

  useEffect(() => {
    gdk.trunk.trigger([Branch.PUBLISHERS])
  }, [gdk])
  useGetDataByPayload({
    payload: id,
    gdkFunc: (payload) => gdk.withdraw.getWithdrawSlipDetail(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: detailPageFlow.setLoadingStart,
    onSuccess: (res: WithdrawSlipDetail) => {
      setDetail(res)
      detailPageFlow.setContentShow()
    },
    onError: detailPageFlow.setGDKError,
    canLoadData: id !== 0
  })
  const isMobileRealnameMatch = detail?.is_mobile_realname_match
  const hasDuplicateBankAccount = detail?.has_duplicate_bank_account ?? false
  const isSelf = detail?.locked_by === me?.id
  const isNoMatchRealName = useMemo(() => detail?.real_name_unmatched_by_forwarder && detail.real_name_unmatched_by_forwarder.length > 0, [detail])
  const isCrypto = detail?.method === WithdrawWayType.CURRENCY_CRYPTO_ERC || detail?.method === WithdrawWayType.CURRENCY_CRYPTO_TRC || detail?.method === WithdrawWayType.CURRENCY_CGPAY_USDT
  const headCellClass = clsx(detailClasses.greyHead, classes.cell, commonClasses.pre, classes.tableFont)
  const bodyCellClass = clsx(detailClasses.cell, classes.cell, commonClasses.pre, classes.tableFont)
  const getChangedForm = useMemo(() => {
    return {
      type: (value, form) => ({ ...form, type: value, memo: value === WithdrawSlipType.CANCEL ? t('common.cancelWithdrawPleaseCallCS') : '' })
    } as ChangedFormGetter<WithdrawSlipFormType>
  }, [t])
  const defaultForm = useMemo(() => {
    const fee = detail?.fee ?? BcMath.base(detail?.crypto_fee ?? 0).mul(sell).get()
    return initialForm({
      cashCrypto: Number(sell)
        ? BcMath.base(detail?.cash ?? 0).sub(detail?.cash_withdraw ?? 0).sub(fee).div(sell).round(4, RoundingMode.RoundDown).get()
        : '',
      type: payload.type,
      memo: payload.type === WithdrawSlipType.CANCEL ? t('common.cancelWithdrawPleaseCallCS') : ''
    })
  }, [payload.type, t, detail, sell])
  const validation = useMemo(() => {
    return {
      type: [],
      cashCrypto: [
        {
          func: createValidateCash('cashCrypto', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form, lastForm) => {
            if (form.type === WithdrawSlipType.GOLDEN_WITHDRAW) {
              return createValidateNotEmpty('cashCrypto', t)(value, form, lastForm, null)
            }
            return createCorrectResult('cashCrypto')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      memo: [
        {
          func: (value, form, lastSubmitForm, error) => {
            if (form.type === WithdrawSlipType.CANCEL) {
              return createValidateNotEmpty('memo', t)(value, form, lastSubmitForm, error)
            }
            return createCorrectResult('memo')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      courier: [
        {
          func: (value, form) => {
            if (form.type === WithdrawSlipType.THIRD_PARTY_WITHDRAW && (value as number | '--') === '--') {
              return createErrorResult('courier', t('error.mustNotEmpty'))
            }
            return createCorrectResult('courier')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ],
      code: [
        {
          func: (value, form) => {
            if (form.type === WithdrawSlipType.THIRD_PARTY_WITHDRAW && (detail?.is_first ?? false) && value === '') {
              return createErrorResult('code', t('error.mustNotEmpty'))
            }
            return createCorrectResult('code')
          },
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<WithdrawSlipFormType>
  }, [t, detail])
  const handleClose = useCallback(() => {
    setPayload({
      id: 0,
      open: false,
      type: WithdrawSlipType.PENDING,
      readonly: true
    })
  }, [])

  const { handleSubmit, loading } = useGDKFuncHandleSubmit({
    formToRequest,
    gdkFunc: (form) => {
      const id = detail?.id ?? 0
      const cashCrypto = isCrypto ? { cash_crypto: BcMath.base(form.cashCrypto).goldenFormat() } : {}
      if (form.type === WithdrawSlipType.GOLDEN_WITHDRAW) return gdk.withdraw.goldenWithdrawSlip(id, { memo: form.memo, ...cashCrypto })
      if (form.type === WithdrawSlipType.THIRD_PARTY_WITHDRAW) return gdk.withdraw.thirdPartyWithdrawSlip(id, (detail?.is_first ?? false) ? { forwarder_id: Number(form.courier), memo: form.memo, code: form.code, ...cashCrypto } : { forwarder_id: Number(form.courier), memo: form.memo, ...cashCrypto })
      return gdk.withdraw.cancelWithdrawSlip(id, { memo: form.memo })
    },
    gdkFuncDependencies: [gdk, detail],
    onSuccess: (_, form) => {
      let message = ''
      if (form.type === WithdrawSlipType.GOLDEN_WITHDRAW) message = t('dialog.articialWithdrawSuccess')
      if (form.type === WithdrawSlipType.THIRD_PARTY_WITHDRAW) message = t('dialog.autoWithdrawSuccess')
      if (form.type === WithdrawSlipType.CANCEL) message = t('dialog.cancelWithdrawSuccess')
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        message,
        showCancel: false
      }))
      globalDialog.setOpen({
        id: 'withdrawSuccess',
        value: true,
        isOK: false
      })
    },
    onError: (error) => {
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: true,
        variant: 'error',
        message: error.message
      }))
      globalDialog.setOpen({
        id: 'withdrawError',
        value: true,
        isOK: false
      })
    }
  })

  useDialogFlow(globalDialog, 'withdrawSuccess', () => {
    handleClose()
    reload()
    globalDialog.clearState()
  })
  const bankAndProtocolMap = {
    [WithdrawWayType.CURRENCY_DEBIT_CARD]: detail?.bank_name ?? '',
    [WithdrawWayType.CURRENCY_GOUBAO]: t('withdraw.goubao'),
    [WithdrawWayType.CURRENCY_CRYPTO_ERC]: t(protocolTypeName[CryptoProtocolType.ERC20]),
    [WithdrawWayType.CURRENCY_CRYPTO_TRC]: t(protocolTypeName[CryptoProtocolType.TRC20]),
    [WithdrawWayType.CURRENCY_TOPAY]: t(protocolTypeName[CryptoProtocolType.TOPAY]),
    [WithdrawWayType.CURRENCY_EBPAY]: t(protocolTypeName[CryptoProtocolType.EBPAY]),
    [WithdrawWayType.CURRENCY_CGPAY]: t(protocolTypeName[CryptoProtocolType.CGPAY]),
    [WithdrawWayType.CURRENCY_CGPAY_USDT]: t(protocolTypeName[CryptoProtocolType.CGPAY_USDT]),
    [WithdrawWayType.CURRENCY_KOIPAY]: t(protocolTypeName[CryptoProtocolType.KOIPAY]),
    [WithdrawWayType.CURRENCY_PAY988]: t(protocolTypeName[CryptoProtocolType.PAY988]),
    [WithdrawWayType.CURRENCY_PAY808]: t(protocolTypeName[CryptoProtocolType.PAY808]),
    [WithdrawWayType.CURRENCY_KDPAY]: t(protocolTypeName[CryptoProtocolType.KDPAY]),
    [WithdrawWayType.CURRENCY_ALIPAY]: t('withdraw.alipay'),
    [WithdrawWayType.AGENT_TRANSFER_WITHDRAW]: t('withdraw.agent_transfer_withdraw')
  }
  const hasDetailSuccess = useMemo(() => (detail?.details?.filter((el) => el.status === AdminWithdrawStatusType.SUCCESSFUL)?.length ?? 0) > 0, [detail])
  return (
    <Dialog open={open} scroll="paper" maxWidth="md" fullWidth onClose={handleClose}>
      <FormStateProvider
        context={FormContext}
        defaultValue={defaultForm}
        validation={validation}
        getValueFromEvent={getValueFromEvent}
        getChangedForm={getChangedForm}
        onSubmit={handleSubmit}
      >
        <Box padding={4}>
          <LoadingAndErrorFrame { ...detailPageFlow.status }>
            <Box overflow="auto">
              <Box minWidth={800}>
                <Box
                  paddingY={1}
                  paddingX={1.5}
                  className={clsx(commonClasses.blackTitleBar, commonClasses.pre)}
                >
                  <Typography variant="body1">
                    {`${t('common.playerInformation')} ( ${uniq((detail?.agent_chain ?? [])).concat(detail?.player_account ?? '').join(' > ')} )`}
                  </Typography>
                </Box>
                <table className={detailClasses.table}>
                  <tbody>
                    <tr>
                      <td className={headCellClass} align="center">{t('common.playerAccount')}</td>
                      <td className={bodyCellClass}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap="nowrap">
                          <Grid item>
                            {// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                              (detail?.fm_warned || detail?.rc_warned) &&
                              <Grid container item direction="column" wrap="nowrap" alignItems="center" justifyContent="center" >
                                <PlayerIsWarnedIcon isWarned={detail.fm_warned} />
                                <PlayerIsWarnedIcon isWarned={detail.rc_warned} warnedType={AccountWarnedType.RISK_CONTROL} />
                              </Grid>
                            }
                          </Grid>
                          <Grid item>{detail?.player_account ?? ''}</Grid>
                        </Grid>
                      </td>
                      <td className={headCellClass} align="center">{t('common.orderNumber')}</td>
                      <td className={bodyCellClass}>{detail?.order_number ?? ''}</td>
                    </tr>
                    <tr>
                      <td className={headCellClass} align="center">{t('common.bankAccount')}</td>
                      <td className={bodyCellClass}>{detail?.bank_account ?? '--'}</td>
                      <td className={headCellClass} align="center">{t('common.processStatus')}</td>
                      <td className={bodyCellClass}>
                        {detail?.status
                          ? (
                          <span className={commonClasses.chipText} style={{ backgroundColor: adminWithdrawStatusColor[detail.status] }}>
                            {t(adminWithdrawStatusName[detail.status])}
                          </span>
                            )
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className={headCellClass} align="center">{t('common.realName')}</td>
                      <td className={bodyCellClass}>{detail?.real_name ?? '-'}</td>
                      <td className={headCellClass} align="center">{t('page.withdrawRCManage')}</td>
                      <td className={bodyCellClass}>
                        {detail?.status
                          ? (
                          <span className={commonClasses.chipText} style={{ color: rcStatusColor[detail.rc_status] }}>
                            {t(rcStatusName[detail.rc_status])}
                          </span>
                            )
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className={headCellClass} align="center">{`${t('common.bankName')}/${t('common.walletProtocol')}`}</td>
                      <td className={bodyCellClass}>{ detail?.method ? bankAndProtocolMap[detail?.method] : '' }</td>
                      <td className={headCellClass} align="center">{t('common.cashHasWithdraw')}</td>
                      {
                        isCrypto
                          ? <td className={bodyCellClass}>{detail?.cash_withdraw ? `${formatMoney(detail.cash_withdraw)} (${formatMoney(detail.cash_crypto_withdraw)} USDT)` : ''}</td>
                          : <td className={bodyCellClass}>{detail?.cash_withdraw ? formatMoney(detail.cash_withdraw) : ''}</td>
                      }
                    </tr>
                    <tr>
                      <td className={headCellClass} align="center">{t('common.playerLayer')}</td>
                      <td className={bodyCellClass}>{detail?.layer_name ?? t('common.none')}</td>
                      <td className={headCellClass} align="center">{t('common.cashHasNotWithdraw')}</td>
                      <td className={bodyCellClass}>{detail?.cash_not_withdraw ? formatMoney(detail.cash_not_withdraw) : '-'}</td>
                    </tr>
                    <tr>
                      <td className={headCellClass} align="center">{t('common.registeredAt')}</td>
                      <td className={bodyCellClass}>{detail?.register_at ? formatDateTime(detail.register_at) : ''}</td>
                      <td className={headCellClass} align="center">{t('common.systemFee')}</td>
                      <td className={bodyCellClass}>{`${detail?.fee ? formatMoney(detail.fee) : '-'}${detail?.crypto_fee ? ` (${formatMoney(detail.crypto_fee)} USDT)` : ''}`}</td>
                    </tr>
                    <tr>
                      <td className={headCellClass} align="center">{t('common.withdrawCash')}</td>
                      <td className={bodyCellClass}>{detail?.cash ? formatMoney(detail.cash) : ''}</td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </Box>
          </LoadingAndErrorFrame>
          <Box marginTop={3}>
            <Grid container direction="row" justifyContent="space-between" spacing={2}>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    {
                      !isMobileRealnameMatch && <Typography color="secondary">
                        {t('common.playerCardRealnameNotMatch')}
                      </Typography>
                    }
                  </Grid>
                  <Grid item>
                    {
                      hasDuplicateBankAccount && <Typography color="secondary">
                        {t('common.duplicateBindPre')}
                        {detail?.duplicate_bank_accounts.map((item) => (
                          <Tooltip title={
                            item.deleted_at ? t('common.bindPeriod', { startAt: formatDateTime(item.created_at), endAt: formatDateTime(item.deleted_at) }) : t('common.bindPeriod2', { startAt: formatDateTime(item.created_at) })
                          }>
                            <span>
                              【<a target="_blank" href={allRoutes.player.encodePath({ param: {}, search: { account: item.account, page: 1 } })} rel="noreferrer">{item.account}</a>】
                            </span>
                          </Tooltip>
                        ))}
                        {t('common.duplicateBind')}
                      </Typography>
                    }
                  </Grid>
                  <Grid item>
                    {
                      isNoMatchRealName && <Typography color="secondary">
                        {t('common.thirdPartyNoMatch', { name: detail?.real_name_unmatched_by_forwarder.map(item => t(`thirdPartName.${item}`)).join('、') })}
                      </Typography>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <ButtonLinkToIpMonitoring id={id} account={detail?.player_account ?? ''} />
              </Grid>
            </Grid>
          </Box>
          <WithdrawalCashAccountingTable id={id} account={detail?.player_account ?? ''} />
          <WithdrawalTransferTable id={id} />
          <Box marginBottom={3}>
            <Box
              paddingY={1}
              paddingX={1.5}
              marginBottom={2}
              className={commonClasses.blackTitleBar}
            >
              <Typography variant="body1">
                {t('common.playerWithdrawProcess')}
              </Typography>
            </Box>
            {detail?.status === AdminWithdrawStatusType.REVIEWING && isSelf && writable && !readonly && (
              <EditWithdrawalFMSlipForm
                id={id}
                isFirst={detail?.is_first || false}
                isCrypto={isCrypto}
                hasDetailSuccess={hasDetailSuccess}
              />
            )}
          </Box>
          <LoadingAndErrorFrame error={null} loading={false} showContent={detailPageFlow.status.showContent}>
            <Box className={classes.log} whiteSpace="pre" padding={2} overflow="auto">
              {detail?.change_log ?? ''}
            </Box>
          </LoadingAndErrorFrame>
          {!!detail?.details?.length && (
            <Box marginTop={3}>
              <Typography>{t('common.thirdPartyWithdrawDetail')}</Typography>
              <Box marginTop={2}>
                <WithdrawalFMSlipCourierDetail items={detail.details} isCrypto={isCrypto} />
              </Box>
            </Box>
          )}
          <Box marginTop={3}>
            <Grid container direction="row" justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  onClick={handleClose}
                  className={commonClasses.greyButton}
                >
                  {t('common.close')}
                </Button>
              </Grid>
              {[AdminWithdrawStatusType.REVIEWING].includes(detail?.status ?? AdminWithdrawStatusType.IN_PROGRESS) && isSelf && writable && !readonly && (
                <Grid item>
                  <FormSubmitButton loading={loading} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </FormStateProvider>
    </Dialog>
  )
})

export default React.memo(WithdrawalFMSlipDetailDialog)
