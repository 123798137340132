import React, { useMemo, createContext, useContext, useCallback, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  GameType,
  GameCategoryType,
  Game as GamePayload,
  PaginationReq,
  BetHistoryQuery,
  RecordSearchTimeType,
  PermissionType,
  OrderStatusType,
  BetTransactionStatusType,
  SpecifyLeague
} from '@golden/gdk-admin'
import { omitBy, isUndefined, enumValues } from '@golden/utils'
import { getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DateInputBase, { DateInputValue } from '../../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../../default/form/DropDown'
import FormField from '../../../default/form/FormField'
import FormSubmitButton from '../../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import useT from '../../../../i18ns/admin/useT'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { getValueFromChangeEvent, getTimeFromDateInputValue, convertEmptyToUndefined, convertFalseToUndefined, acceptUndefined, pipe, parseArray, convertUndefinedToDefault, guaranteeBeKey, guaranteeNotUndefined, guaranteeBetween, getValueFromValue, getValueFromCheckboxEvent, parseBoolean, guaranteeBeOneOf } from '../../../../utils/default/FormHelper'
import { InitialFormFunc, useGetData, useChangeUrlSubmit, useRequestFromSearch, SearchToRequestFunc } from '../../../../utils/default/ComplexFlowHook'
import FormStateProvider from '../../../default/form/FormStateProvider'
import { ValueGetter, FormValidation, createDefaultFormState, ChangedFormGetter } from '../../../../utils/default/FormHook'
import {
  setToday,
  setYesterday,
  setThisMonth,
  setLastMonth,
  getAllowedTimeStamps,
  createShouldDisableFuture,
  getCorrectedDateRange
} from '../../../../utils/default/TimeHelper'
import allRoute from '../../route/route'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import RequiredText from '../../../default/form/RequiredText'
import { parsePath } from '../../../../utils/default/RouteHelper'
import recordSearchTimeName from '../../../../constants/admin/recordSearchTimeName'
import { useChecker } from '../../../../utils/admin/AdminRouteHook'
import MultipleSelector, { PropTypes as MultipleSelectorProps } from '../../../../components/default/form/MultipleSelector'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../../default/form/OnOffCheckbox'
import betTransactionStatusName from '../../../../constants/admin/betTransactionStatusName'
import orderStatusName from '../../../../constants/default/orderStatusName'
import GameInput, { PropTypes as GameCategoryProps } from '../../GameInput'
import { createCorrectResult, createErrorResult } from '../../../../utils/default/Validator'
import SportLeagueDialog from '../SportLeagueDialog'
import SpecifyLeagueDialogButton from '../SpecifyLeagueDialogButton'

export type PlayerReportBetRequest = PaginationReq & BetHistoryQuery
export type PlayerReportBetRequestWithJson = Omit<PlayerReportBetRequest, 'specify_league'> & { specify_league?: string }

export interface PlayerReportBetFormType {
  search_type: RecordSearchTimeType
  time: DateInputValue
  playerAccount: string
  game: {
    game_category: GameCategoryType
    game_id: GameType | 'noGame' | 'all'
    channel_id: number[] // 非彩票遊戲：遊戲
    lottery_channel_id: number[] // 彩票遊戲：彩種
  }
  serial_number: string
  draw_date: string // 彩票遊戲：期數
  transaction_status: BetTransactionStatusType | 'all'
  order_status: OrderStatusType[]
  is_buyback: boolean // 體育賽事：兌現
  feedback_serial_number: string
  is_activity_excluding: boolean | '--'
  specify_league: SpecifyLeague[]
  is_combo: boolean | '--'
}

export const initialForm: InitialFormFunc<PlayerReportBetFormType> = (defaultForm) => ({
  search_type: RecordSearchTimeType.CREATED_AT,
  time: setToday(),
  playerAccount: '',
  game: {
    game_category: GameCategoryType.SPORT_LOTTERY,
    game_id: 'noGame',
    channel_id: [],
    lottery_channel_id: [-1]
  },
  serial_number: '',
  draw_date: '',
  transaction_status: 'all',
  order_status: [],
  is_buyback: false,
  feedback_serial_number: '',
  is_activity_excluding: '--',
  specify_league: [],
  is_combo: '--',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<PlayerReportBetFormType> = {
  search_type: getValueFromChangeEvent,
  time: getTimeFromDateInputValue,
  playerAccount: getValueFromChangeEvent,
  game: getValueFromValue,
  serial_number: getValueFromChangeEvent,
  draw_date: getValueFromChangeEvent,
  transaction_status: getValueFromChangeEvent,
  order_status: getValueFromValue,
  is_buyback: getValueFromCheckboxEvent,
  feedback_serial_number: getValueFromChangeEvent,
  is_activity_excluding: getValueFromChangeEvent,
  specify_league: getValueFromValue,
  is_combo: getValueFromChangeEvent
}

const getChangedForm: ChangedFormGetter<PlayerReportBetFormType> = {
  game: (value, form) => {
    if (value.game_category !== form.game.game_category) {
      if (value.game_category === GameCategoryType.LOTTERY) return initialForm({ ...form, game: value, order_status: [1, 2, 3, 5], serial_number: '', specify_league: [] })
      return initialForm({ ...form, game: value, order_status: [], serial_number: '', specify_league: [] })
    }
    return { ...form, game: value }
  }
}

const formToRequest = (form: PlayerReportBetFormType): PlayerReportBetRequestWithJson => {
  const converted = {
    page: 1,
    search_type: form.search_type,
    game_category: form.game.game_category,
    game_id: form.game.game_id === 'all' || form.game.game_id === 'noGame' ? undefined : form.game.game_id,
    start_at: form.time.start === null ? undefined : getTime(form.time.start),
    end_at: form.time.end === null ? undefined : getTime(form.time.end),
    account: convertEmptyToUndefined(form.playerAccount),
    serial_number: convertEmptyToUndefined(form.serial_number),
    transaction_status: form.transaction_status === 'all' ? undefined : form.transaction_status,
    order_status: form.order_status.length === 0 ? undefined : form.order_status,
    feedback_serial_number: convertEmptyToUndefined(form.feedback_serial_number),
    is_buyback: form.game.game_category === GameCategoryType.SPORT_LOTTERY ? convertFalseToUndefined(form.is_buyback) : undefined,
    channel_id: form.game.channel_id.length === 0 || form.game.game_category === GameCategoryType.LOTTERY ? undefined : form.game.channel_id,
    ...(form.game.game_category === GameCategoryType.LOTTERY
      ? {
          draw_date: convertEmptyToUndefined(form.draw_date),
          channel_id: form.game.lottery_channel_id[0] === -1 ? undefined : form.game.lottery_channel_id
        }
      : {}
    ),
    is_activity_excluding: form.is_activity_excluding === '--' ? undefined : form.is_activity_excluding,
    specify_league: form.specify_league.length ? JSON.stringify(form.specify_league) : undefined,
    is_combo: form.is_combo === '--' ? undefined : form.is_combo
  } as PlayerReportBetRequestWithJson
  return omitBy(converted, isUndefined) as PlayerReportBetRequestWithJson
}

export const searchToRequest: SearchToRequestFunc<PlayerReportBetRequest> = (search) => {
  const startTimeStamp = Number(search.start_at)
  const endTimeStamp = Number(search.end_at)
  const { minAllowedStartTimeStamp, maxAllowedEndTimeStamp } = getAllowedTimeStamps(startTimeStamp, endTimeStamp)
  const converted = {
    search_type: pipe(
      (value) => convertUndefinedToDefault(value, RecordSearchTimeType.CREATED_AT),
      (value) => guaranteeBeKey(value, Object.keys(recordSearchTimeName))
    )(search.search_type),
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    game_id: acceptUndefined(search.game_id, pipe(
      (value) => guaranteeBeOneOf(Number(value), enumValues(GameType)),
      parseInt
    )),
    game_category: acceptUndefined(search.game_category, pipe(
      (value) => guaranteeBeOneOf(Number(value), enumValues(GameCategoryType)),
      parseInt
    )),
    start_at: acceptUndefined(search.start_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, minAllowedStartTimeStamp, endTimeStamp)
    )),
    end_at: acceptUndefined(search.end_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, startTimeStamp, maxAllowedEndTimeStamp)
    )),
    account: search.account,
    is_buyback: acceptUndefined(search.is_buyback, parseBoolean),
    feedback_serial_number: search.feedback_serial_number,
    serial_number: search.serial_number,
    draw_date: search.draw_date,
    family_id: acceptUndefined(
      search.family_id,
      parseInt
    ),
    publisher_id: acceptUndefined(
      search.publisher_id,
      parseInt
    ),
    channel_id: acceptUndefined(
      search.channel_id,
      pipe(
        parseArray,
        (values) => values.map((item: string) => Number(item))
      )
    ),
    lottery_channel_id: acceptUndefined(
      search.channel_id,
      pipe(
        parseArray,
        (values) => values.map((item: string) => Number(item))
      )
    ),
    transaction_status: acceptUndefined(
      search.transaction_status,
      parseInt
    ),
    order_status: acceptUndefined(
      search.order_status,
      pipe(
        parseArray,
        (values) => values.map((item: string) => Number(item) as OrderStatusType)
      )
    ),
    is_activity_excluding: acceptUndefined(search.is_activity_excluding, parseBoolean),
    specify_league: acceptUndefined(search.specify_league, pipe(
      (value) => JSON.parse(value)
    )),
    is_combo: acceptUndefined(search.is_combo, parseBoolean)
  }

  if (converted.start_at && converted.end_at && converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')

  return omitBy(converted, isUndefined) as unknown as PlayerReportBetRequest
}

export const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const SearchDropDown: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange, handleOther, error } = useContext(FormContext)
  const searchTimeOptions = useMemo(() => {
    return Object.keys(recordSearchTimeName)
      .map((key) => ({ name: t(recordSearchTimeName[key as RecordSearchTimeType]), value: key }))
  }, [t])
  return (
    <Grid item xs={12} md={6} lg={3}>
      <DropDown
        value={value.search_type}
        onChange={handleChange('search_type')}
        onBlur={handleOther('blur', 'search_type')}
        options={searchTimeOptions}
        label={t('common.searchDate')}
        fullWidth
        required
        error={error.search_type !== null}
        helperText={error.search_type ?? ''}
      />
    </Grid>
  )
})

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => createShouldDisableFuture(), [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onChange = useCallback((value) => {
    handleChange('time')(getCorrectedDateRange(value))
  }, [])

  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      FrontComponent={SearchDropDown}
    />
  )
})

const GameQuery: React.FC = React.memo(() => {
  const { t } = useT()
  const { value } = useContext(FormContext)
  const transactionOptions = useMemo(() => {
    return [{ name: t('common.all'), value: 'all' as BetTransactionStatusType | 'all' }]
      .concat(
        Object.keys(betTransactionStatusName)
          .map((key) => Number(key) as BetTransactionStatusType)
          .map((key) => ({ name: t(betTransactionStatusName[key]), value: key }))
      )
  }, [t])

  const betTypeOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }, { name: t('common.singleOdd'), value: false }, { name: t('common.comboOdd'), value: true }]
  }, [t])

  return (
    <Grid item xs={12}>
      <FormField<PlayerReportBetFormType, GameCategoryProps>
        context={FormContext}
        component={GameInput}
        name="game"
        categoryNoAll
        FrontComponent={(
        <Grid item lg={3} md={6} xs={12}>
          <FormField<PlayerReportBetFormType, TextFieldProps>
            context={FormContext}
            component={TextField}
            name="playerAccount"
            label={t('common.playerAccount')}
            placeholder={t('placeholder.inputPlayerAccount')}
            fullWidth
          />
        </Grid>)}
        EndComponent={(<>
          <Grid item xs={12} md={6} lg={3}>
            { value.game.game_category === GameCategoryType.SPORT_LOTTERY && (
              <FormField<PlayerReportBetFormType, OnOffCheckboxProps>
                context={FormContext}
                component={OnOffCheckbox}
                name="is_buyback"
                label={t('common.buybackSimple')}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormField<PlayerReportBetFormType, TextFieldProps>
              context={FormContext}
              component={TextField}
              name="serial_number"
              label={t('common.serial')}
              placeholder={t('placeholder.inputSerial')}
              fullWidth
            />
          </Grid>
          { value.game.game_category === GameCategoryType.LOTTERY && (<Grid item xs={12} md={6} lg={3}>
            <FormField<PlayerReportBetFormType, TextFieldProps>
              context={FormContext}
              component={TextField}
              name="draw_date"
              label={t('common.drawDate')}
              placeholder={t('placeholder.inputDrawDate')}
              fullWidth
            />
          </Grid>) }
          <Grid item xs={12} md={6} lg={3}>
            <FormField<PlayerReportBetFormType, DropDownProps>
              context={FormContext}
              component={DropDown}
              name="transaction_status"
              label={t('common.transactionStatus')}
              options={transactionOptions}
              fullWidth
            />
          </Grid>
          {
            value.game.game_category === GameCategoryType.SPORT_LOTTERY && (<Grid item xs={12} md={6} lg={3}>
              <FormField<PlayerReportBetFormType, DropDownProps>
                context={FormContext}
                component={DropDown}
                name="is_combo"
                label={t('common.betType')}
                options={betTypeOptions}
                fullWidth
              />
            </Grid>
            )
          }
          </>)}
      />
    </Grid>
  )
})

const PlayerReportBetForm: React.FC = () => {
  const classes = useCommonStyles()
  const gdk = useGDK()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const location = useLocation()
  const readableFetch = useChecker([PermissionType.BET_RECORD_FETCH_READ])

  const request = useRequestFromSearch({ searchToRequest })
  const search = useMemo(() => {
    return parsePath(location.search, location.pathname).search
  }, [location.search, location.pathname])

  const [games, setGames] = useState<GamePayload[]>([])
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  useGetData({
    gdkFunc: () => gdk.platform.getGameList(),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setGames(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const defaultForm = useMemo(() => {
    const options = games.filter((game) => (game.category as number) === GameCategoryType.SPORT_LOTTERY)
    if (request === undefined) {
      return initialForm({
        game: {
          game_category: GameCategoryType.SPORT_LOTTERY,
          game_id: options[0] === undefined ? 'noGame' as const : 'all' as const,
          channel_id: [],
          lottery_channel_id: [-1]
        }
      })
    }
    return initialForm({
      ...request,
      time: {
        start: request.start_at ? new Date(request.start_at) : null,
        end: request.end_at ? new Date(request.end_at) : null
      },
      playerAccount: request.account ?? '',
      feedback_serial_number: request.feedback_serial_number ?? '',
      game: {
        game_category: request.game_category ?? (games.find((game) => game.id === request.game_id)?.category as number) ?? GameCategoryType.SPORT_LOTTERY,
        game_id: request.game_id ?? 'all',
        channel_id: request.channel_id ?? [],
        lottery_channel_id: request.channel_id ?? [-1]
      }
    })
  }, [games, request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.playerReportBet.encodePath
  })

  const validation = useMemo(() => ({
    search_type: [],
    time: [],
    playerAccount: [],
    game: [
      {
        func: (value) => {
          if ((value as { game_id: number | string }).game_id !== -1) return createCorrectResult('game')
          return createErrorResult('game', '')
        },
        when: ['beforeClickSubmit']
      }
    ],
    family_id: [],
    publisher_id: [],
    serial_number: [],
    feedback_serial_number: [],
    issue_number: [],
    draw_date: [],
    transaction_status: [],
    order_status: [],
    channel_id: [],
    lottery_channel_id: [],
    is_buyback: [],
    is_activity_excluding: [],
    specify_league: [],
    is_combo: []
  } as FormValidation<PlayerReportBetFormType>), [])

  const orderOptions = useMemo(() => {
    return Object.keys(orderStatusName)
      .map((key) => Number(key) as OrderStatusType)
      .map((key) => ({ name: t(orderStatusName[key]), value: key }))
  }, [t])

  const activityExcludingTypeOptions = useMemo(() => ([
    {
      name: t('common.noLimit'),
      value: '--'
    },
    {
      name: t('common.notExclude'),
      value: false
    },
    {
      name: t('common.exclude'),
      value: true
    }
  ]), [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Grid container direction="column" spacing={2}>
              { readableFetch && <Grid item>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <MuiButton
                      component={Link}
                      to={allRoute.playerReportFetchTask.encodePath({ search, param: {} })}
                      className={classes.purpleGradualButton}
                    >
                      {t('page.fetchTask')}
                    </MuiButton>
                  </Grid>
                </Grid>
              </Grid> }
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('common.betHistory')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <DateInput />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <GameQuery />
                  <Grid item xs={12} md={6}>
                    <FormField<PlayerReportBetFormType, MultipleSelectorProps>
                      context={FormContext}
                      component={MultipleSelector}
                      name="order_status"
                      label={t('common.orderStatus')}
                      options={orderOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<PlayerReportBetFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="feedback_serial_number"
                      label={t('common.feedbackSerial')}
                      placeholder={t('placeholder.inputFeedbackSerial')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<PlayerReportBetFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="is_activity_excluding"
                      options={activityExcludingTypeOptions}
                      label={t('common.searchingEffectiveCash')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <SpecifyLeagueDialogButton<PlayerReportBetFormType>
                      setOpen={setOpenDialog}
                      context={FormContext}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <RequiredText />
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <SportLeagueDialog<PlayerReportBetFormType>
              open={openDialog}
              onClose={() => { setOpenDialog(false) }}
              context={FormContext}
            />
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(PlayerReportBetForm)
