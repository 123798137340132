import React, { useState, useMemo, useRef, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ProfitAgentItem, ProfitHistoryGame, ProfitTeamItem } from '@golden/gdk-admin'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { searchToRequest } from './AdminReportProfitForm'
import CoreTable from '../../default/present/CoreTable'
import PointsCell from '../../default/present/PointsCell'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useRequestFromSearch, useGetDataByPayload, useGetData } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createTableData, formatMoney } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute from '../route/route'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import PlatformFeeCell from '../../default/present/PlatformFeeCell'
import { useSnackbar } from 'notistack'
import { mergeArray } from '@golden/utils'
import AdminReportProfitFeeDetailsDialog, { PropTypes as DetailsDialogProps, defaultProps as detailsDialogDefaultProps } from './AdminReportProfitFeeDetailsDialog'

const useStyles = makeStyles(() => ({
  sum: {
    '&>tr:nth-last-child(1)': {
      backgroundColor: '#f5f4ff'
    }
  }
}))

interface SumRowType {
  id: number
  title: string
  bet: string
  result: string
  feedback: string
  activity: string
  winlost: React.ReactElement
  profit: React.ReactElement
  donate: string
  buyback: string
  platformFee: React.ReactElement
  financeFee: string
  other: string
  risk: string
}

interface GameRowType {
  id: number
  game: string
  effectiveCash: string
  bet: string
  result: string
  feedback: string
  winlost: React.ReactElement
  profit: React.ReactElement
  donate: string
  buyback: string
  platformFee: React.ReactElement
}

interface RowType {
  id: number
  betPlayerCount: number
  account: React.ReactElement
  effectiveCash: string
  bet: string
  result: string
  feedback: string
  activity: string
  deposit: string
  withdrawal: string
  profit: React.ReactElement
  donate: string
  buyback: string
  platformFee: React.ReactElement
  financeFee: React.ReactElement
  net: React.ReactElement
  percentage: string
  commission: React.ReactElement
  other: string
  risk: string
  agent_transfer: string
}

const SumPayoutTip: React.FC = React.memo(() => {
  const commonClasses = useCommonStyles()
  const { t } = useT()
  return (
    <Box paddingTop={1}>
      <Typography color="error" className={commonClasses.pre}>{t('common.payoutTip')}</Typography>
      <Typography color="error" className={commonClasses.pre}>{t('common.profitTeamReportTip2')}</Typography>
    </Box>
  )
})

const GamePayoutTip: React.FC = React.memo(() => {
  const commonClasses = useCommonStyles()
  const { t } = useT()
  return (
    <Box paddingTop={1}>
      <Typography color="error" className={commonClasses.pre}>{t('common.payoutTip')}</Typography>
      <Typography color="error" className={commonClasses.pre}>{t('common.profitGameReportTip')}</Typography>
    </Box>
  )
})

const AgentPayoutTip: React.FC = React.memo(() => {
  const commonClasses = useCommonStyles()
  const { t } = useT()
  return (
    <Box paddingTop={1}>
      <Typography color="error" className={commonClasses.pre}>{t('common.payoutTip')}</Typography>
      <Typography color="error" className={commonClasses.pre}>{t('common.profitAgentReportTip3')}</Typography>
      <Typography color="error" className={commonClasses.pre}>{t('common.riskAdjustmentTip')}</Typography>
      <Typography color="error" className={commonClasses.pre}>{t('common.splitPercentageTip')}</Typography>
    </Box>
  )
})

const AdminReportProfitTable: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const { enqueueSnackbar } = useSnackbar()
  const games = useGDKStore.platform.games()
  const [updatedPlatformFeeGames, setUpdatedPlatformFeeGames] = useState<number[]>([])
  const [sumRes, setSumRes] = useState<ProfitTeamItem>({
    bet_cash: '0.0000',
    cash_winning: '0.0000',
    buyback_amount: '0.0000',
    feedback: '0.0000',
    revenue: '0.0000',
    platform_fee: '0.0000',
    finance_fee: '0.0000',
    team_revenue: '0.0000',
    donate: '0.0000',
    other: '0.0000',
    risk_adjustment: '0.0000',
    activity: '0.0000'
  })
  const [gameRes, setGameRes] = useState<ProfitHistoryGame>({
    games: [],
    sum: {
      bet_cash: '0.0000',
      effective_cash: '0.0000',
      cash_winning: '0.0000',
      feedback: '0.0000',
      revenue: '0.0000',
      platform_revenue: '0.0000',
      donate: '0.0000',
      buyback_amount: '0.0000',
      platform_fee: '0.0000'
    }
  })
  const [agentRes, setAgentRes] = useState<ProfitAgentItem[]>([])
  const [dialogParams, setDialogParams] = useState<DetailsDialogProps>(detailsDialogDefaultProps)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const sumPageFlow = usePageFlow()
  const gamePageFlow = usePageFlow()
  const agentPageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getProfitHistoryTeam(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: sumPageFlow.setLoadingStart,
    onSuccess: (res: ProfitTeamItem) => {
      setSumRes(res)
      sumPageFlow.setContentShow()
    },
    onError: sumPageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getProfitHistoryGame(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: gamePageFlow.setLoadingStart,
    onSuccess: (res: ProfitHistoryGame) => {
      setGameRes(res)
      gamePageFlow.setContentShow()
    },
    onError: gamePageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getProfitHistoryAgent(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: agentPageFlow.setLoadingStart,
    onSuccess: (res: ProfitAgentItem[]) => {
      setAgentRes(res)
      agentPageFlow.setContentShow()
    },
    onError: agentPageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  useGetData({
    gdkFunc: () => gdk.platform.getPlatformFeeStatus(),
    gdkFuncDependencies: [gdk, request],
    onSuccess: setUpdatedPlatformFeeGames,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const gameTableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    body: classes.sum,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses, classes])
  const sumRows: SumRowType[] = useMemo(() => {
    return [{
      id: 0,
      title: t('common.summary'),
      bet: formatMoney(sumRes.bet_cash),
      result: formatMoney(sumRes.cash_winning),
      buyback: formatMoney(sumRes.buyback_amount),
      winlost: (<PointsCell points={sumRes.revenue} />),
      feedback: formatMoney(sumRes.feedback),
      activity: formatMoney(sumRes.activity),
      platformFee: (<PlatformFeeCell updatedGames={updatedPlatformFeeGames}>{formatMoney(sumRes.platform_fee)}</PlatformFeeCell>),
      risk: formatMoney(sumRes.risk_adjustment),
      financeFee: formatMoney(sumRes.finance_fee),
      profit: (<PointsCell points={sumRes.team_revenue} />),
      donate: formatMoney(sumRes.donate),
      other: formatMoney(sumRes.other)
    } as SumRowType]
  }, [sumRes, updatedPlatformFeeGames, t])
  const gameRows: GameRowType[] = useMemo(() => {
    const gameData = mergeArray(games, gameRes.games, (game, item) => game.id === item.game_id).map(([game, item]) => ({
      id: item.game_id,
      game: game.nameWithStatus,
      effectiveCash: formatMoney(item.effective_cash),
      bet: formatMoney(item.bet_cash),
      result: formatMoney(item.cash_winning),
      feedback: formatMoney(item.feedback),
      winlost: (<PointsCell points={item.revenue} />),
      profit: (<PointsCell points={item.platform_revenue} />),
      donate: formatMoney(item.donate),
      buyback: formatMoney(item.buyback_amount),
      platformFee: (<PlatformFeeCell updatedGames={updatedPlatformFeeGames} gameId={item.game_id}>{formatMoney(item.platform_fee)}</PlatformFeeCell>)
    }))
    const sumData: GameRowType = {
      id: 9999,
      game: t('common.sum'),
      effectiveCash: formatMoney(gameRes.sum.effective_cash),
      bet: formatMoney(gameRes.sum.bet_cash),
      result: formatMoney(gameRes.sum.cash_winning),
      feedback: formatMoney(gameRes.sum.feedback),
      winlost: (<PointsCell points={gameRes.sum.revenue} />),
      profit: (<PointsCell points={gameRes.sum.platform_revenue} />),
      donate: formatMoney(gameRes.sum.donate),
      buyback: formatMoney(gameRes.sum.buyback_amount),
      platformFee: (<PlatformFeeCell updatedGames={updatedPlatformFeeGames}>{formatMoney(gameRes.sum.platform_fee)}</PlatformFeeCell>)
    }
    return gameData.concat(sumData)
  }, [gameRes.games, updatedPlatformFeeGames, gameRes.sum, t, games])
  const rows: RowType[] = useMemo(() => {
    return agentRes.map((item, index) => {
      return {
        id: index,
        betPlayerCount: item.bet_player_count,
        account: (
          <Link to={allRoute.adminReportProfitLayer.encodePath({
            search: { ...request, nextPage: 1, nodeId: item.id },
            param: { id: item.account.toLocaleUpperCase() === 'RMB' ? item.account : item.id }
          })}>
            {item.account}
          </Link>
        ),
        bet: formatMoney(item.bet_cash),
        effectiveCash: formatMoney(item.effective_cash),
        result: formatMoney(item.cash_winning),
        feedback: formatMoney(item.feedback),
        activity: formatMoney(item.activity),
        deposit: formatMoney(item.deposit),
        withdrawal: formatMoney(item.withdraw),
        profit: (<PointsCell points={item.revenue} />),
        donate: formatMoney(item.donate),
        buyback: formatMoney(item.buyback_amount),
        platformFee: (<PlatformFeeCell updatedGames={updatedPlatformFeeGames}>{formatMoney(item.platform_fee)}</PlatformFeeCell>),
        financeFee: (
          <span
            className={commonClasses.anchor}
            onClick={() => {
              setDialogParams({
                target_id: (item.id ?? -1),
                type: item.account.toLocaleUpperCase() === 'RMB' ? 'golden' : 'agent',
                target_name: item.account
              })
              setDialogOpen(true)
            }}
          >
            {formatMoney(item.finance_fee)}
          </span>
        ),
        net: (<PointsCell points={item.net_revenue} />),
        percentage: item.account.toLocaleUpperCase() === 'RMB' ? '--' : `${Number(item.percentage)}%${item.is_changed ? '*' : ''}`,
        commission: item.account.toLocaleUpperCase() === 'RMB' || item.commission === undefined || item.commission === null ? '--' : (<PointsCell points={item.commission} />),
        other: formatMoney(item.other),
        risk: formatMoney(item.risk_adjustment),
        agent_transfer: formatMoney(item.agent_transfer)
      } as RowType
    })
  }, [agentRes, updatedPlatformFeeGames, request, setDialogParams, setDialogOpen])
  const sumData = useMemo(() => {
    return createTableData<SumRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        title: {
          label: '',
          value: 'title',
          align: 'center'
        },
        bet: {
          label: t('common.allBetCash'),
          value: 'bet',
          align: 'right'
        },
        result: {
          label: t('common.payedCash'),
          value: 'result',
          align: 'right'
        },
        feedback: {
          label: t('common.allFeedback'),
          value: 'feedback',
          align: 'right'
        },
        activity: {
          label: t('common.allActivityCash'),
          value: 'activity',
          align: 'right'
        },
        winlost: {
          label: t('common.totalWinAndLose'),
          value: 'winlost',
          align: 'right'
        },
        profit: {
          label: t('common.netResult'),
          value: 'profit',
          align: 'right'
        },
        donate: {
          label: t('common.totalDonate'),
          value: 'donate',
          align: 'right'
        },
        buyback: {
          label: t('common.totalBuyback'),
          value: 'buyback',
          align: 'right'
        },
        platformFee: {
          label: t('common.platformFee'),
          value: 'platformFee',
          align: 'right'
        },
        financeFee: {
          label: t('common.financeFee'),
          value: 'financeFee',
          align: 'right'
        },
        other: {
          label: t('common.other'),
          value: 'other',
          align: 'right'
        },
        risk: {
          label: t('common.riskAdjustment'),
          value: 'risk',
          align: 'right'
        }
      },
      [
        'title',
        'bet',
        'result',
        'feedback',
        'activity',
        'winlost',
        'financeFee',
        'profit',
        'donate',
        'buyback',
        'platformFee',
        'other',
        'risk'
      ],
      sumRows,
      'id'
    )
  }, [sumRows, t])
  const gameData = useMemo(() => {
    return createTableData<GameRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        game: {
          label: t('common.game'),
          value: 'game',
          align: 'center'
        },
        bet: {
          label: t('common.allBetCash'),
          value: 'bet',
          align: 'right'
        },
        effectiveCash: {
          label: t('common.successBetCash'),
          value: 'effectiveCash',
          align: 'right'
        },
        result: {
          label: t('common.payedCash'),
          value: 'result',
          align: 'right'
        },
        feedback: {
          label: t('common.allFeedback'),
          value: 'feedback',
          align: 'right'
        },
        winlost: {
          label: t('common.totalWinAndLose'),
          value: 'winlost',
          align: 'right'
        },
        profit: {
          label: t('common.gameRevenue'),
          value: 'profit',
          align: 'right'
        },
        donate: {
          label: t('common.totalDonate'),
          value: 'donate',
          align: 'right'
        },
        buyback: {
          label: t('common.totalBuyback'),
          value: 'buyback',
          align: 'right'
        },
        platformFee: {
          label: t('common.platformFee'),
          value: 'platformFee',
          align: 'right'
        }
      },
      [
        'game',
        'effectiveCash',
        'bet',
        'result',
        'buyback',
        'winlost',
        'feedback',
        'platformFee',
        'profit',
        'donate'
      ],
      gameRows,
      'id'
    )
  }, [gameRows, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        betPlayerCount: {
          label: t('common.betPeople'),
          value: 'betPlayerCount',
          align: 'center'
        },
        account: {
          label: t('common.account'),
          value: 'account',
          align: 'center'
        },
        bet: {
          label: t('common.allBetCash'),
          value: 'bet',
          align: 'right'
        },
        effectiveCash: {
          label: t('common.successBetCash'),
          value: 'effectiveCash',
          align: 'right'
        },
        result: {
          label: t('common.payedCash'),
          value: 'result',
          align: 'right'
        },
        feedback: {
          label: t('common.allFeedback'),
          value: 'feedback',
          align: 'right'
        },
        activity: {
          label: t('common.allActivityCash'),
          value: 'activity',
          align: 'right'
        },
        deposit: {
          label: t('common.allDepositCash'),
          value: 'deposit',
          align: 'right'
        },
        withdrawal: {
          label: t('common.allWithdrawalCash'),
          value: 'withdrawal',
          align: 'right'
        },
        profit: {
          label: t('common.totalWinAndLose'),
          value: 'profit',
          align: 'right'
        },
        donate: {
          label: t('common.totalDonate'),
          value: 'donate',
          align: 'right'
        },
        buyback: {
          label: t('common.totalBuyback'),
          value: 'buyback',
          align: 'right'
        },
        platformFee: {
          label: t('common.platformFee'),
          value: 'platformFee',
          align: 'right'
        },
        financeFee: {
          label: t('common.financeFee'),
          value: 'financeFee',
          align: 'right'
        },
        net: {
          label: t('common.netResult'),
          value: 'net',
          align: 'right'
        },
        percentage: {
          label: t('common.splitPercentage'),
          value: 'percentage',
          align: 'right'
        },
        commission: {
          label: t('common.commission'),
          value: 'commission',
          align: 'right'
        },
        other: {
          label: t('common.other'),
          value: 'other',
          align: 'right'
        },
        risk: {
          label: t('common.riskAdjustment'),
          value: 'risk',
          align: 'right'
        },
        agent_transfer: {
          label: t('common.agentTransfer'),
          value: 'agent_transfer',
          align: 'right'
        }
      },
      [
        'account',
        'betPlayerCount',
        'effectiveCash',
        'bet',
        'result',
        'buyback',
        'feedback',
        'activity',
        'platformFee',
        'risk',
        'profit',
        'financeFee',
        'net',
        'percentage',
        'commission',
        'deposit',
        'withdrawal',
        'donate',
        'other',
        'agent_transfer'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const handleCopy = () => {
    if (!textareaRef.current) return
    textareaRef.current.value = `${t('common.account')}\t${t('common.netResult')}\t${t('common.financeFee')}\t${t('common.splitPercentage')}\t${t('common.commission')}\n` +
      agentRes.map((el) => `${el.account}\t${el.net_revenue}\t${el.finance_fee}\t${Number(el.percentage) ?? ''}%\t${el.commission ?? ''}`).join('\n')
    textareaRef.current.setAttribute('style', 'display: block;')
    textareaRef.current.select()
    document.execCommand('copy')
    textareaRef.current.setAttribute('style', 'display: none;')
    enqueueSnackbar(t('common.oneClickCopyDone'), {
      autoHideDuration: 2 * 1000
    })
  }

  if (request === undefined) return null
  return (
    <React.Fragment>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...gamePageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                marginBottom={3}
                className={commonClasses.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('common.platformProfit')}
                </Typography>
              </Box>
              <CoreTable
                classes={tableClasses}
                data={sumData}
                total={1}
                tableFooterMemo={SumPayoutTip}
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...gamePageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                marginBottom={3}
                className={commonClasses.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('common.gameRevenue')}
                </Typography>
              </Box>
              <CoreTable
                classes={gameTableClasses}
                data={gameData}
                total={gameRows.length}
                tableFooterMemo={() => (<GamePayoutTip />)}
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...agentPageFlow.status }>
            <Box
              paddingY={1.25}
              paddingX={2}
              marginBottom={3}
              className={commonClasses.pinkTitleBar}
            >
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="h5">
                  {t('common.layerProfit')}
                </Typography>
                <Button
                  onClick={handleCopy}
                  className={commonClasses.purpleGradualButton}
                >
                  {t('common.accountantCopy')}
                </Button>
                <textarea
                  ref={textareaRef}
                  style={{ display: 'none' }}
                />
              </Grid>
            </Box>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={agentRes.length}
              tableFooterMemo={AgentPayoutTip}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
      { dialogOpen && (<AdminReportProfitFeeDetailsDialog
        handleClose={() => { setDialogOpen(false) }}
        target_id={dialogParams.target_id}
        target_name={dialogParams.target_name}
        type={dialogParams.type}
      />) }
    </React.Fragment>
  )
}

export default React.memo(AdminReportProfitTable)
