import React, { useCallback, useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FeedbackFrequency, PlayerRankType } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FeedbackInput from './FeedbackInput'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import playerRankName from '../../../constants/default/playerRankName'
import { PageFlowType } from '../../../utils/default/PageFlowHook'
import DropDown from '../../default/form/DropDown'
import useT from '../../../i18ns/admin/useT'
import { createTableData } from '../../../utils/default/TableHelper'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { Typography } from '@material-ui/core'
import { FormState } from '../../../utils/default/FormHook'

const useStyles = makeStyles((theme) => ({
  dropDown: {
    ...theme.typography.button,
    lineHeight: 1.4,
    padding: 10
  }
}))

type RowType = Record<string, React.ReactElement> & {
  id: string
  channel: React.ReactElement
  delete: React.ReactElement
}

interface FormType {
  period: FeedbackFrequency
  ranks: Array<{ rank: PlayerRankType, feedback: string }>
  channels: Array<{
    channel_id: number
    channel_code: string
    ranks: Array<{ rank: PlayerRankType, feedback: string }>
  }>
}

export interface PropTypes {
  context: React.Context<FormState<FormType>>
  channels: Array<{ id: number, name: string }>
  pageFlow: PageFlowType
  tmpChannels: Array<{
    channel_id: number
    channel_code: string
  }>
}

export const DEFAULT_CHANNEL = -100
export const DEFAULT_CHANNEL_CODE = ''

const RankInput: React.FC<{
  channel: number
  rank: PlayerRankType
  value: string
  context: React.Context<FormState<FormType>>
  disabled?: boolean
}> = React.memo((props) => {
  const { channel, rank, value, disabled, context } = props
  const { value: form, dispatch } = useContext(context)
  const handleChange = useCallback((value: string) => {
    const newChannals = form.channels?.map((item) => {
      if (item.channel_id === channel) {
        return {
          ...item,
          ranks: item.ranks.map((el) => {
            if (el.rank === rank) {
              return {
                rank,
                feedback: value
              }
            } else {
              return el
            }
          })
        }
      } else {
        return item
      }
    })
    dispatch({
      type: 'change',
      label: 'channels',
      value: newChannals
    })
  }, [form.channels, channel, rank])
  return (
    <FeedbackInput
      value={value}
      onChange={handleChange}
      disabled={disabled ?? channel === DEFAULT_CHANNEL}
    />
  )
})

const ChannelInput: React.FC<{
  index: number
  disabled?: boolean
  options: Array<{ name: string, value: number, disabled?: boolean }>
  value: number
  onChange: (index: number, value: number) => void
}> = React.memo((props) => {
  const { index, disabled, options, value, onChange } = props
  const classes = useStyles()
  return (
    <DropDown
      className={classes.dropDown}
      label=""
      options={options}
      variant="outlined"
      value={value}
      disabled={disabled}
      fullWidth
      onChange={useCallback((event) => {
        const newValue = event.target.value
        onChange(index, newValue)
      }, [value, onChange])}
    />
  )
})

const FeedbackChannelInput: React.FC<PropTypes> = (props) => {
  const { context, channels, pageFlow, tmpChannels } = props
  const { value: form, dispatch } = useContext(context)
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const tableClasses = useMemo(() => ({
    head: commonClasses.blackTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const options = useMemo(() => {
    const ids = form.channels?.map((item) => item.channel_id) ?? []
    const channelOptions = channels.map((item) => {
      const disabled = ids.includes(item.id)
      return { name: item.name, value: item.id, disabled }
    })
    const channelOptionIds = channelOptions.map((item) => item.value)
    const unexisted = tmpChannels
      .filter((item) => !channelOptionIds.includes(item.channel_id))
      .map((item) => ({ name: `${t('common.thirdPartyCreatedGame')}${item.channel_code}`, value: item.channel_id, disabled: true })) ?? []
    return [
      { name: t('common.pleaseChoose'), value: DEFAULT_CHANNEL },
      ...channelOptions,
      ...unexisted
    ]
  }, [channels, form.channels, tmpChannels, t])
  const handleRankChange = useCallback((channel: number, rank: PlayerRankType, newValue: string) => {
    const newChannals = form.channels?.map((item) => {
      if (item.channel_id === channel) {
        return {
          ...item,
          ranks: item.ranks.map((el) => {
            if (el.rank === rank) {
              return {
                rank,
                feedback: newValue
              }
            } else {
              return el
            }
          })
        }
      } else {
        return item
      }
    })
    dispatch({
      type: 'change',
      label: 'channels',
      value: newChannals
    })
  }, [form.channels, dispatch])
  const handleChannelChange = useCallback((index: number, newValue: number) => {
    dispatch({
      type: 'change',
      label: 'channels',
      value: form.channels?.map((item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            channel_id: newValue
          }
        } else {
          return item
        }
      })
    })
  }, [form.channels, dispatch])
  const handleCreate = useCallback(() => {
    dispatch({
      type: 'change',
      label: 'channels',
      value: (form.channels ?? []).concat({
        channel_id: DEFAULT_CHANNEL,
        channel_code: DEFAULT_CHANNEL_CODE,
        ranks: Object.keys(playerRankName)
          .map((key) => key as PlayerRankType)
          .map((key) => ({
            rank: key,
            feedback: '0.00'
          }))
      })
    })
  }, [form, dispatch])
  const handleDelete = useCallback((index: number) => {
    dispatch({
      type: 'change',
      label: 'channels',
      value: form.channels.slice(0, index).concat(form.channels.slice(index + 1, form.channels.length + 1))
    })
  }, [form.channels, dispatch])
  const rows = useMemo(() => {
    return form.channels?.map((item, index) => {
      const rankDataObject = Object.keys(playerRankName)
        .map((key) => key as PlayerRankType)
        .map((key) => {
          const target = item.ranks.find((item) => item.rank === key)
          return {
            [key.toString()]: (
              <RankInput
                channel={item.channel_id}
                rank={key}
                context={context}
                value={target?.feedback ?? ''}
              />
            )
          }
        })
        .reduce((item, obj) => Object.assign({}, obj, item), {})
      return {
        id: `${item.channel_id} - ${index}`,
        channel: (
          <ChannelInput
            index={index}
            options={options}
            value={item.channel_id}
            onChange={handleChannelChange}
          />
        ),
        delete: (
          <Button
            className={commonClasses.pinkGradualOutlineButton}
            onClick={() => { handleDelete(index) }}
          >
            {t('common.delete')}
          </Button>
        ),
        ...rankDataObject
      } as RowType
    }) ?? []
  }, [form.channels, options, handleChannelChange, handleDelete, handleRankChange, commonClasses, t])
  const data = useMemo(() => {
    const rankColumnObject = Object.keys(playerRankName)
      .map((key) => key as PlayerRankType)
      .map((key) => {
        return {
          [key.toString()]: {
            label: `${t(playerRankName[key])}*`,
            value: key.toString(),
            align: 'center'
          }
        }
      })
      .reduce((item, obj) => Object.assign({}, item, obj), {})
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        channel: {
          label: t('common.game'),
          value: 'channel',
          align: 'center'
        },
        delete: {
          label: '',
          value: 'delete',
          align: 'center'
        },
        ...rankColumnObject
      },
      [
        'channel',
        ...Object.keys(playerRankName),
        'delete'
      ],
      rows,
      'id'
    )
  }, [rows, t])
  return (
    <LoadingAndErrorFrame {...pageFlow.status}>
      <Box paddingBottom={2} display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          <Typography color="error">
            {t('common.feedbackTip1')}
          </Typography>
          <Typography color="error">
            {t('common.feedbackTip2')}
          </Typography>
        </Box>
        <Button className={commonClasses.purpleGradualButton} onClick={handleCreate}>
          {t('common.batchCreate')}
        </Button>
      </Box>
      <CoreTable
        dense
        data={data}
        classes={tableClasses}
        total={form.channels?.length ?? 0}
      />
    </LoadingAndErrorFrame>
  )
}

export default React.memo(FeedbackChannelInput)
