import React, { useState, useMemo, useCallback, Dispatch, SetStateAction, useContext } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { useGetDataByPayload, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { AdminPlayerWithdrawalAccount, AdminPlayerWithdrawalAccounts, PermissionType, PlayerStatusType } from '@golden/gdk-admin'
import DateTime from '../../default/present/DateTime'
import { createTableData } from '../../../utils/default/TableHelper'
import CoreTable from '../../default/present/CoreTable'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import WithdrawalAccountDeleteButton from './WithdrawalAccountDeleteButton'
import WithdrawalAccountCreateButton from './WithdrawalAccountCreateButton'
import TableTabs from '../TableTabs'
import BoundRelateRecordButton from './BoundRelateRecordButton'
import { InfoContext } from '../../../views/admin/player/PlayerDetailPage'

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}))

interface PropTypes {
  id: number
  section: string
  reload: () => void
  loaded: Dispatch<SetStateAction<string>>
}

type CardsTab = 'binding' | 'bound'

interface RowType {
  id: number
  time: React.ReactElement
  name: string
  account: React.ReactElement
  function: React.ReactElement
}

const Buttons: React.FC<{
  index: number
  id: number
  WithdrawalAccount: AdminPlayerWithdrawalAccount
  isTabBinding: boolean
  reload: () => void
}> = React.memo((props) => {
  const { index, id, WithdrawalAccount, reload, isTabBinding } = props
  return (
    <Grid container direction="row" spacing={1} justifyContent="center">
      {isTabBinding && (
        <React.Fragment>
          <Grid item>
            <WithdrawalAccountDeleteButton index={index} id={id} account={WithdrawalAccount} reload={reload} />
          </Grid>
        </React.Fragment>
      )}
      <Grid item>
        <BoundRelateRecordButton relatedRecords={WithdrawalAccount.related_records} />
      </Grid>
    </Grid>
  )
})

const PlayerDetailWithdrawalAccountSection: React.FC<PropTypes> = (props) => {
  const { id, section, reload: reloadProps, loaded } = props
  const { reload, reloadFlag } = useReload()
  const [userRealName, setUserRealName] = useState<string>('')
  const [accounts, setAccounts] = useState<AdminPlayerWithdrawalAccounts['accounts']>({ binding: [], bound: [] })
  const [tab, setTab] = useState<CardsTab>('binding')
  const [info] = useContext(InfoContext)
  const writableEdit = useChecker([PermissionType.PLAYER_MANAGEMENT_WITHDRAWAL_ACCOUNT])
  const writableBind = useChecker([PermissionType.PLAYER_MANAGEMENT_WITHDRAWAL_ACCOUNT_BIND])
  const { t } = useT()
  const classes = useStyles()
  const gdk = useGDK()
  const commonClasses = useCommonStyles()
  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const pageFlow = usePageFlow()

  const tabs = useMemo(() => [
    { label: t('common.currentBinding'), value: 'binding' },
    { label: t('common.pastBound'), value: 'bound' }
  ], [t])
  const isTabBinding = useMemo(() => (tab === 'binding'), [tab])

  useGetDataByPayload({
    payload: id,
    gdkFunc: (payload) => gdk.player.getPlayerWithdrawalAccounts(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: AdminPlayerWithdrawalAccounts) => {
      setUserRealName(res.real_name)
      setAccounts(res.accounts)
      pageFlow.setContentShow()
      loaded(section)
    },
    onError: pageFlow.setGDKError
  })
  const reloadAll = useCallback(() => {
    reload()
    reloadProps()
  }, [])
  const accountList = useMemo(() => {
    if (isTabBinding) return accounts.binding
    return accounts.bound
  }, [accounts, tab])
  const disabled = useMemo(() => {
    if (info.status === PlayerStatusType.NO_CARD) return true
    return Boolean(accounts.binding.length)
  }, [accountList, info])
  const rows = useMemo(() => {
    return accountList.map((item, index) => {
      return {
        id: item.id,
        time: (<DateTime time={isTabBinding ? item.created_at : item.deleted_at} />),
        name: item.real_name,
        account: (
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            {item.account}
          </Box>
        ),
        function: (
          <Buttons index={index} id={id} WithdrawalAccount={item} reload={reloadAll} isTabBinding={isTabBinding}/>
        )
      } as RowType
    })
  }, [accountList, commonClasses, classes, id, reloadAll, tab])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        time: {
          label: isTabBinding ? t('common.bindData') : t('common.unbindData'),
          value: 'time',
          align: 'center'
        },
        name: {
          label: t('common.realName'),
          value: 'name',
          align: 'center'
        },
        account: {
          label: t('common.account'),
          value: 'account',
          align: 'center'
        },
        function: {
          label: t('common.function'),
          value: 'function',
          align: 'center',
          width: 300
        }
      },
      writableEdit
        ? [
            'time',
            'name',
            'account',
            'function'
          ]
        : [
            'time',
            'name',
            'account'
          ],
      rows,
      'id'
    )
  }, [rows, t, writableEdit, tab])
  return (
    <div id={section}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={commonClasses.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('common.withdrawalAccountData')}
            </Typography>
          </Box>
          <Box>
            <Box display="flex" paddingTop={2}>
              <TableTabs
                value={tab}
                tabs={tabs}
                onChange={(value) => {
                  setTab(value as CardsTab)
                }}
              />
            </Box>
          </Box>
          <Box paddingY={2}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={data}
                total={accountList.length}
              />
            </LoadingAndErrorFrame>
          </Box>
          <Box whiteSpace="pre">
            <Typography style={{ color: '#808080' }}>
              {t('common.playerWithdrawalAccountTip')}
            </Typography>
          </Box>
          {writableBind && (
            <WithdrawalAccountCreateButton
              id={id}
              userName={userRealName}
              disabled={disabled}
              reload={reloadAll}
            />)}
        </Box>
      </ScrollablePaper>
    </div>
  )
}

export default React.memo(PlayerDetailWithdrawalAccountSection)
