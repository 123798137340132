import React, { useMemo, useState } from 'react'
import { WithdrawalWay, PaginationRes } from '@golden/gdk-admin'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useGetDataByPayload, useReload, useRequestFromSearch, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatMoney } from '../../../utils/default/TableHelper'
import TableStatus from '../../default/present/TableStatus'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import FinanceWithdrawalWayButtons from './FinanceWithdrawalWayButtons'
import { searchToRequest, RequestType } from '../../../views/admin/finance/FinanceWithdrawalWayPage'
import allRoute from '../../../components/admin/route/route'
import WithdrawWayTypeName from '../../../constants/admin/withdrawWayTypeName'
import { isUndefined, omitBy } from '@golden/utils'

interface RowType {
  id: number
  forwarder: string
  courier: React.ReactElement
  method: string
  withdrawCash: string
  min: string
  max: string
  ranks: React.ReactElement
  status: React.ReactElement
  functions: React.ReactElement
}

const FinanceWithdrawalWayTable: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const commonClasses = useCommonStyles()
  const { reloadFlag, reload } = useReload()
  const pageFlow = usePageFlow(true)
  const gdk = useGDK()
  const writable = useChecker()
  const { t } = useT()

  const [list, setList] = useState<PaginationRes<WithdrawalWay[]>>(createDefaultPaginationData([]))

  const payload = useMemo(() => {
    const converted = { ...request, page: request?.page ?? 1, is_active: request?.is_active === 2 ? undefined : request?.is_active } as RequestType
    return omitBy(converted, isUndefined) as RequestType
  }, [request])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.finance.getWithdrawalWayList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<WithdrawalWay[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      row: commonClasses.tableRow,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses])

  const rows = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        forwarder: item.slug_name,
        courier: (
          <React.Fragment>
            {
              item.is_auto_withdraw && (
                <p>
                  <span className={commonClasses.chipText} style={{ backgroundColor: '#72d476' }}>
                    {t('common.autoWithdrawal')}
                  </span>
                </p>
              )
            }
            <p>{item.forwarder_name}</p>
          </React.Fragment>
        ),
        method: t(WithdrawWayTypeName[item.method]),
        withdrawCash: '',
        min: formatMoney(item.cash_min),
        max: formatMoney(item.cash_max),
        ranks: (
          <React.Fragment>
            { item.layers.map(layer => layer.name).join('、') }
          </React.Fragment>
        ),
        status: (
          <TableStatus status={item.is_active} />
        ),
        functions: (
          <FinanceWithdrawalWayButtons
            item={item}
            reload={reload}
          />
        )
      } as RowType
    })
  }, [list.data, commonClasses.chipText, t, reload, writable])
  const data = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        forwarder: {
          label: t('common.forwarder'),
          value: 'forwarder',
          align: 'center'
        },
        courier: {
          label: t('common.courierName'),
          value: 'courier',
          align: 'center'
        },
        method: {
          label: t('common.playerWithdrawProcess'),
          value: 'method',
          align: 'center'
        },
        withdrawCash: {
          label: `${t('common.withdrawCash')} (RMB)`,
          value: 'withdrawCash',
          align: 'center',
          groupItems: ['min', 'max']
        },
        min: {
          label: t('common.min'),
          value: 'min',
          align: 'center'
        },
        max: {
          label: t('common.max'),
          value: 'max',
          align: 'center'
        },
        ranks: {
          label: t('common.openLayer'),
          value: 'ranks',
          align: 'center',
          width: 120
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        }
      },
      writable
        ? [
            'forwarder',
            'courier',
            'method',
            'withdrawCash',
            'ranks',
            'status',
            'functions'
          ]
        : [
            'forwarder',
            'courier',
            'method',
            'withdrawCash',
            'ranks',
            'status'
          ],
      rows,
      'id'
    )
  }, [t, rows, writable])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.financeWithdrawalWay.encodePath,
    pageFlow
  })

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <CoreTable
        classes={tableClasses}
        data={data}
        total={list.total}
        showPagination
        page={request?.page ?? 1}
        onChangePage={handlePagination}
        loading={pageFlow.status.loading}
      />
    </LoadingAndErrorFrame>
  )
}

export default FinanceWithdrawalWayTable
