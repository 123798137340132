import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CustomThemeType from '../../../themes/default/CustomThemeType'
import { Box } from '@material-ui/core'

interface NestedCellPropTypes {
  info: Array<string | React.ReactElement | undefined>
  justifyContent?: 'center' | 'start' | 'end'
  computedLine?: boolean
  boxHeight?: number
}

const useStyles = makeStyles((theme: CustomThemeType) => ({
  bottomBorder: {
    borderBottom: '1px solid rgba(233, 233, 233, 1)'
  },
  fourPadding: {
    padding: theme.spacing(1)
  },
  topLine: {
    'border-top': '3px solid #797979'
  }
}))

const NestedCell: React.FC<NestedCellPropTypes> = (props) => {
  const { info, justifyContent = 'center', computedLine, boxHeight = 60 } = props
  const classes = useStyles()
  return (
    <React.Fragment>
      {info.map((el, index) => (
        <Box
          className={clsx(classes.fourPadding, (index < (info.length - 1) ? classes.bottomBorder : ''), (computedLine && index === info.length - 1) ? classes.topLine : '')}
          alignItems="center"
          justifyContent={justifyContent}
          display="flex"
          key={`'nested-cell'-${index}`}
          height={boxHeight}
        >
          {el}
        </Box>
      ))}
    </React.Fragment>
  )
}

export default React.memo(NestedCell)
