import React, { useState, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import WithdrawalAccountInfoDialog, { initialWithdrawalAccountForm, WithdrawalAccountFormType } from './WithdrawalAccountInfoDialog'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { AdminPlayerWithdrawalAccountForm } from '@golden/gdk-admin'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useGDKFuncHandleSubmit } from '../../../utils/default/ComplexFlowHook'

interface PropTypes {
  id: number
  userName: string
  reload: () => void
  disabled: boolean
}

const formToRequest = (form: WithdrawalAccountFormType): AdminPlayerWithdrawalAccountForm => ({
  real_name: form.name,
  account: form.account
})

const WithdrawalAccountCreateButton: React.FC<PropTypes> = (props) => {
  const { id, userName, disabled, reload } = props
  const { t } = useT()
  const gdk = useGDK()
  const commonClasses = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const [open, setOpen] = useState<boolean>(false)

  const defaultInfoForm = initialWithdrawalAccountForm({ name: userName })

  const { handleSubmit } = useGDKFuncHandleSubmit({
    formToRequest,
    gdkFunc: (payload) => gdk.player.createPlayerWithdrawalAccount(id, payload),
    onSuccessDependencies: [reload, setOpen],
    onErrorDependencies: [reload, setOpen],
    gdkFuncDependencies: [gdk, id],
    onSuccess: () => {
      reload()
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        showCancel: false,
        message: t('dialog.bankCardBindSuccess')
      }))
      globalDialog.setOpen({ id: 'createDebitCardSuccess', value: true, isOK: false })
      setOpen(false)
    },
    onError: (error) => {
      globalDialog.clearState()
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: true,
        variant: 'error',
        showCancel: false,
        message: error.message
      }))
      globalDialog.setOpen({ id: 'createDebitCardFail', value: true, isOK: false })
      setOpen(false)
    }
  })
  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        className={commonClasses.purpleGradualButton}
        onClick={useCallback(() => { setOpen(true) }, [])}
        disabled={disabled}
      >
        {t('common.bindWithdrawalAccount')}
      </Button>
      <WithdrawalAccountInfoDialog
        open={open}
        title={t('common.bindWithdrawalAccount')}
        onClose={useCallback(() => { setOpen(false) }, [])}
        onSubmit={handleSubmit}
        defaultValue={defaultInfoForm}
      />
    </Box>
  )
}

export default React.memo(WithdrawalAccountCreateButton)
