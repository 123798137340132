import React, { createContext, useContext, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { getTime, startOfYear, startOfDay, endOfDay, subYears } from 'date-fns'
import MuiButton from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { NormalActivityQuery, ActivityNewMemberBonusQuery, ActivityRecommendFriendQuery, PaginationReq, ActivitySearchTimeType, ActivityType, ActivityDailySignInQuery, ActivityNewYear2023Query, ActivityDragonBoatFestival2023Query, ActivityNewYear2024Query, ActivityDepositBonusQuery, ActivityUefaBuildingUpQuery, ActivityUefaStarsQuery, ActivityWeeklyBonusDrawQuery, ActivityDailyBonusDrawQuery, ActivityNewYear2025DrawQuery, ActivityNewYear2025RedEnvelopeQuery } from '@golden/gdk-admin'
import { SearchToRequestFunc, InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { guaranteeNotUndefined, parseInt, guaranteeBetween, guaranteeBeOneOf, getTimeFromDateInputValue, getValueFromChangeEvent, pipe, convertEmptyToUndefined } from '../../../utils/default/FormHelper'
import activitySearchTimeName from '../../../constants/admin/activitySearchTimeName'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import { ValueGetter, ChangedFormGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import useT from '../../../i18ns/admin/useT'
import DropDown from '../../default/form/DropDown'
import { setToday, setYesterday, setThisMonth, setThisWeek, setLastMonth, setLastWeek, createShouldDisableDate } from '../../../utils/default/TimeHelper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import FormStateProvider from '../../default/form/FormStateProvider'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import allRoute from '../route/route'
import RequiredText from '../../default/form/RequiredText'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import {
  NormalActivityFormType,
  initialForm as initialNormalActivityForm,
  getValueFromEvent as getNormalActivityValue,
  formToRequest as normalActivityFormToRequest,
  searchToRequest as normalActivitySearchToRequest
} from './ActivityRecordNormalActivityForm'
import ActivityRecommendFriendForm, {
  ActivityRecordRecommendFriendFormType,
  initialForm as initialActivityRecommendFriendForm,
  getValueFromEvent as getActivityRecommendFriendValue,
  formToRequest as activityRecommendFriendFormToRequest,
  searchToRequest as activityRecommendFriendSearchToRequest
} from './ActivityRecordRecommendFriendForm'
import ActivityNewMemberBonusForm, {
  ActivityNewMemberBonusFormType,
  initialForm as initialActivityNewMemberBonusForm,
  getValueFromEvent as getActivityNewMemberBonusValue,
  formToRequest as activityNewMemberBonusFormToRequest,
  searchToRequest as activityNewMemberBonusSearchToRequest
} from './ActivityRecordNewMemberBonusForm'
import ActivityRankBonusForm, {
  ActivityRankBonusFormType,
  initialForm as initialActivityRankBonusForm,
  getValueFromEvent as getActivityRankBonusValue,
  formToRequest as activityRankBonusFormToRequest,
  searchToRequest as activityRankBonusSearchToRequest
} from './ActivityRecordRankBonusForm'
import ActivityDailySignInForm, {
  ActivityDailySignInFormType,
  initialForm as initialActivityDailySignInForm,
  getValueFromEvent as getActivityDailySignInValue,
  formToRequest as activityDailySignInFormToRequest,
  searchToRequest as activityDailySignInSearchToRequest
} from './ActivityRecordDailySignInForm'
import ActivityNewYear2023Form, {
  ActivityNewYear2023FormType,
  initialForm as initialActivityNewYear2023Form,
  getValueFromEvent as getActivityNewYear2023Value,
  formToRequest as activityNewYear2023FormToRequest,
  searchToRequest as activityNewYear2023SearchToRequest
} from './ActivityRecordNewYear2023Form'
import ActivityDragonBoatFestival2023Form, {
  ActivityDragonBoatFestival2023FormType,
  initialForm as initialActivityDragonBoatFestival2023Form,
  getValueFromEvent as getActivityDragonBoatFestival2023Value,
  formToRequest as activityDragonBoatFestival2023FormToRequest,
  searchToRequest as activityDragonBoatFestival2023SearchToRequest
} from './ActivityRecordDragonBoatFestival2023Form'
import ActivityNewYear2024Form, {
  ActivityNewYear2024FormType,
  initialForm as initialActivityNewYear2024Form,
  getValueFromEvent as getActivityNewYear2024Value,
  formToRequest as activityNewYear2024FormToRequest,
  searchToRequest as activityNewYear2024SearchToRequest
} from './ActivityRecordNewYear2024Form'
import ActivityDepositBonusForm, {
  ActivityDepositBonusFormType,
  initialForm as initialActivityDepositBonusForm,
  getValueFromEvent as getActivityDepositBonusValue,
  formToRequest as activityDepositBonusFormToRequest,
  searchToRequest as activityDepositBonusSearchToRequest
} from './ActivityRecordDepositBonusForm'
import ActivityUefaBuildingUpForm, {
  ActivityUefaBuildingUpFormType,
  initialForm as initialActivityUefaBuildingUpForm,
  getValueFromEvent as getActivityUefaBuildingUpValue,
  formToRequest as activityUefaBuildingUpFormToRequest,
  searchToRequest as activityUefaBuildingUpSearchToRequest
} from './ActivityRecordUefaBuildingUpForm'
import ActivityUefaStarsForm, {
  ActivityUefaStarsFormType,
  initialForm as initialActivityUefaStarsForm,
  getValueFromEvent as getActivityUefaStarsValue,
  formToRequest as activityUefaStarsFormToRequest,
  searchToRequest as activityUefaStarsSearchToRequest
} from './ActivityRecordUefaStarsForm'
import ActivityWeeklyBonusDrawForm, {
  ActivityWeeklyBonusDrawFormType,
  initialForm as initialActivityWeeklyBonusDrawForm,
  getValueFromEvent as getActivityWeeklyBonusDrawValue,
  formToRequest as activityWeeklyBonusDrawFormToRequest,
  searchToRequest as activityWeeklyBonusDrawSearchToRequest
} from './ActivityRecordWeeklyBonusDrawForm'
import ActivityDailyBonusDrawForm, {
  ActivityDailyBonusDrawFormType,
  initialForm as initialActivityDailyBonusDrawForm,
  getValueFromEvent as getActivityDailyBonusDrawValue,
  formToRequest as activityDailyBonusDrawFormToRequest,
  searchToRequest as activityDailyBonusDrawSearchToRequest
} from './ActivityRecordDailyBonusDrawForm'
import ActivityNewYear2025DrawForm, {
  ActivityNewYear2025DrawFormType,
  initialForm as initialActivityNewYear2025DrawForm,
  getValueFromEvent as getActivityNewYear2025DrawValue,
  formToRequest as activityNewYear2025DrawFormToRequest,
  searchToRequest as activityNewYear2025DrawSearchToRequest
} from './ActivityRecordNewYear2025DrawForm'
import ActivityNewYear2025RedEnvelopeForm, {
  ActivityNewYear2025RedEnvelopeFormType,
  initialForm as initialActivityNewYear2025RedEnvelopeForm,
  getValueFromEvent as getActivityNewYear2025RedEnvelopeValue,
  formToRequest as activityNewYear2025RedEnvelopeFormToRequest,
  searchToRequest as activityNewYear2025RedEnvelopeSearchToRequest
} from './ActivityRecordNewYear2025RedEnvelopeForm'

import { isUndefined, omitBy } from '@golden/utils'

export interface PropTypes {
  isSub?: boolean
}

export type ActivityRequest = (
  { activity: ActivityType.NEW_MEMBER_BONUS } & ActivityNewMemberBonusQuery |
  { activity: ActivityType.RECOMMEND_FRIEND } & ActivityRecommendFriendQuery |
  { activity: ActivityType.UPGRADE_BONUS_GOLD } & Omit<NormalActivityQuery, 'status'> |
  { activity: ActivityType.DAILY_SIGN_IN_BONUS } & ActivityDailySignInQuery |
  { activity: ActivityType.WEEKLY_BONUS_GOLD } & Omit<NormalActivityQuery, 'status'> |
  { activity: ActivityType.BIRTHDAY_BONUS_GOLD } & NormalActivityQuery |
  { activity: ActivityType.NEW_YEAR_2023 } & ActivityNewYear2023Query |
  { activity: ActivityType.DRAGON_BOAT_FESTIVAL_2023 } & ActivityDragonBoatFestival2023Query |
  { activity: ActivityType.NEW_YEAR_2024 } & ActivityNewYear2024Query |
  { activity: ActivityType.DEPOSIT_BONUS } & ActivityDepositBonusQuery |
  { activity: ActivityType.UEFA_BUILDING_UP } & ActivityUefaBuildingUpQuery |
  { activity: ActivityType.UEFA_STARS } & ActivityUefaStarsQuery |
  { activity: ActivityType.WEEKLY_BONUS_DRAW } & ActivityWeeklyBonusDrawQuery |
  { activity: ActivityType.DAILY_BONUS_DRAW } & ActivityDailyBonusDrawQuery |
  { activity: ActivityType.NEW_YEAR_2025_DRAW } & ActivityNewYear2025DrawQuery |
  { activity: ActivityType.NEW_YEAR_2025_RED_ENVELOPE } & ActivityNewYear2025RedEnvelopeQuery
) & PaginationReq & {
  former_page_type?: ActivityType
  account?: string
  agent_account?: string
}

export type ActivityFormType = {
  type: ActivitySearchTimeType
  time: DateInputValue
  activity: ActivityType
}
& NormalActivityFormType
& ActivityRecordRecommendFriendFormType
& ActivityNewMemberBonusFormType
& ActivityRankBonusFormType
& ActivityDailySignInFormType
& ActivityNewYear2023FormType
& ActivityDragonBoatFestival2023FormType
& ActivityNewYear2024FormType
& ActivityDepositBonusFormType
& ActivityUefaBuildingUpFormType
& ActivityUefaStarsFormType
& ActivityWeeklyBonusDrawFormType
& ActivityDailyBonusDrawFormType
& ActivityNewYear2025DrawFormType
& ActivityNewYear2025RedEnvelopeFormType

export const initialForm: InitialFormFunc<ActivityFormType> = (defaultForm) => ({
  type: ActivitySearchTimeType.APPLIED_TIME,
  time: {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  },
  activity: ActivityType.NEW_MEMBER_BONUS,
  ...initialNormalActivityForm(),
  ...initialActivityNewMemberBonusForm(),
  ...initialActivityRecommendFriendForm(),
  ...initialActivityRankBonusForm(),
  ...initialActivityDailySignInForm(),
  ...initialActivityNewYear2023Form(),
  ...initialActivityDragonBoatFestival2023Form(),
  ...initialActivityNewYear2024Form(),
  ...initialActivityDepositBonusForm(),
  ...initialActivityUefaBuildingUpForm(),
  ...initialActivityUefaStarsForm(),
  ...initialActivityWeeklyBonusDrawForm(),
  ...initialActivityDailyBonusDrawForm(),
  ...initialActivityNewYear2025DrawForm(),
  ...initialActivityNewYear2025RedEnvelopeForm(),
  ...defaultForm
})

const getValueFromEvent: ValueGetter<ActivityFormType> = {
  time: getTimeFromDateInputValue,
  type: getValueFromChangeEvent,
  activity: getValueFromChangeEvent,
  ...getNormalActivityValue,
  ...getActivityNewMemberBonusValue,
  ...getActivityRecommendFriendValue,
  ...getActivityRecommendFriendValue,
  ...getActivityRankBonusValue,
  ...getActivityDailySignInValue,
  ...getActivityNewYear2023Value,
  ...getActivityDragonBoatFestival2023Value,
  ...getActivityNewYear2024Value,
  ...getActivityDepositBonusValue,
  ...getActivityUefaBuildingUpValue,
  ...getActivityUefaStarsValue,
  ...getActivityWeeklyBonusDrawValue,
  ...getActivityDailyBonusDrawValue,
  ...getActivityNewYear2025DrawValue,
  ...getActivityNewYear2025RedEnvelopeValue
}

const getChangedForm: ChangedFormGetter<ActivityFormType> = {
  activity: (value, form) => ({
    ...form,
    activity: value,
    ...initialNormalActivityForm(),
    ...initialActivityNewMemberBonusForm(),
    ...initialActivityRecommendFriendForm(),
    ...initialActivityRecommendFriendForm(),
    ...initialActivityRankBonusForm(),
    ...initialActivityDailySignInForm(),
    ...initialActivityNewYear2023Form(),
    ...initialActivityDragonBoatFestival2023Form(),
    ...initialActivityNewYear2024Form(),
    ...initialActivityDepositBonusForm(),
    ...initialActivityUefaBuildingUpForm(),
    ...initialActivityUefaStarsForm(),
    ...initialActivityWeeklyBonusDrawForm(),
    ...initialActivityDailyBonusDrawForm(),
    ...initialActivityNewYear2025DrawForm,
    ...initialActivityNewYear2025RedEnvelopeForm
  })
}

const f2RMap: { [key in ActivityType as string]: ((form: ActivityFormType) => ActivityRequest) } = {
  [ActivityType.NEW_MEMBER_BONUS]: activityNewMemberBonusFormToRequest,
  [ActivityType.RECOMMEND_FRIEND]: activityRecommendFriendFormToRequest,
  [ActivityType.UPGRADE_BONUS_GOLD]: activityRankBonusFormToRequest,
  [ActivityType.DAILY_SIGN_IN_BONUS]: activityDailySignInFormToRequest,
  [ActivityType.WEEKLY_BONUS_GOLD]: activityRankBonusFormToRequest,
  [ActivityType.BIRTHDAY_BONUS_GOLD]: normalActivityFormToRequest,
  [ActivityType.NEW_YEAR_2023]: activityNewYear2023FormToRequest,
  [ActivityType.DRAGON_BOAT_FESTIVAL_2023]: activityDragonBoatFestival2023FormToRequest,
  [ActivityType.NEW_YEAR_2024]: activityNewYear2024FormToRequest,
  [ActivityType.DEPOSIT_BONUS]: activityDepositBonusFormToRequest,
  [ActivityType.UEFA_BUILDING_UP]: activityUefaBuildingUpFormToRequest,
  [ActivityType.UEFA_STARS]: activityUefaStarsFormToRequest,
  [ActivityType.WEEKLY_BONUS_DRAW]: activityWeeklyBonusDrawFormToRequest,
  [ActivityType.DAILY_BONUS_DRAW]: activityDailyBonusDrawFormToRequest,
  [ActivityType.NEW_YEAR_2025_DRAW]: activityNewYear2025DrawFormToRequest,
  [ActivityType.NEW_YEAR_2025_RED_ENVELOPE]: activityNewYear2025RedEnvelopeFormToRequest
}

const formToRequest = (form: ActivityFormType): ActivityRequest => {
  const converted = {
    activity: form.activity,
    type: form.type,
    account: convertEmptyToUndefined(form.account),
    agent_account: convertEmptyToUndefined(form.agent_account),
    start_at: form.time.start === null ? undefined : getTime(form.time.start),
    end_at: form.time.end === null ? undefined : getTime(form.time.end),
    page: 1
  } as ActivityRequest
  const omited = omitBy(converted, isUndefined)
  const convertOthersFunc = f2RMap[form.activity]
  if (convertOthersFunc) {
    const others = convertOthersFunc(form)
    return Object.assign({}, omited, others)
  }
  return omited as ActivityRequest
}

const commonSearchToRequest: SearchToRequestFunc<{
  page: number
  start_at: number
  end_at: number
  activity: ActivityType
  type: ActivitySearchTimeType
  former_page_type?: ActivityType
}> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    page: pipe(
      (value) => guaranteeNotUndefined(value as string),
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    activity: pipe(
      (value) => guaranteeNotUndefined(value as string),
      (value) => guaranteeBeOneOf(value, Object.values(ActivityType))
    )(search.activity),
    type: pipe(
      (value) => guaranteeNotUndefined(value as string),
      parseInt,
      (value) => guaranteeBeOneOf(value, Object.keys(activitySearchTimeName).map((key) => Number(key)))
    )(search.type),
    start_at: pipe(
      (value) => guaranteeNotUndefined(value as string),
      parseInt
    )(search.start_at),
    end_at: pipe(
      (value) => guaranteeNotUndefined(value as string),
      parseInt
    )(search.end_at),
    former_page_type: search.former_page_type
      ? pipe(
        (value) => guaranteeNotUndefined(value as string),
        (value) => guaranteeBeOneOf(value, Object.values(ActivityType))
      )(search.former_page_type)
      : undefined
  }
  return omitBy(converted, isUndefined) as {
    page: number
    start_at: number
    end_at: number
    activity: ActivityType
    type: ActivitySearchTimeType
    former_page_type?: ActivityType
  }
}

const s2RMap: { [key in ActivityType as string]: SearchToRequestFunc<any> } = {
  [ActivityType.NEW_MEMBER_BONUS]: activityNewMemberBonusSearchToRequest,
  [ActivityType.RECOMMEND_FRIEND]: activityRecommendFriendSearchToRequest,
  [ActivityType.UPGRADE_BONUS_GOLD]: activityRankBonusSearchToRequest,
  [ActivityType.DAILY_SIGN_IN_BONUS]: activityDailySignInSearchToRequest,
  [ActivityType.WEEKLY_BONUS_GOLD]: activityRankBonusSearchToRequest,
  [ActivityType.BIRTHDAY_BONUS_GOLD]: normalActivitySearchToRequest,
  [ActivityType.NEW_YEAR_2023]: activityNewYear2023SearchToRequest,
  [ActivityType.DRAGON_BOAT_FESTIVAL_2023]: activityDragonBoatFestival2023SearchToRequest,
  [ActivityType.NEW_YEAR_2024]: activityNewYear2024SearchToRequest,
  [ActivityType.DEPOSIT_BONUS]: activityDepositBonusSearchToRequest,
  [ActivityType.UEFA_BUILDING_UP]: activityUefaBuildingUpSearchToRequest,
  [ActivityType.UEFA_STARS]: activityUefaStarsSearchToRequest,
  [ActivityType.WEEKLY_BONUS_DRAW]: activityWeeklyBonusDrawSearchToRequest,
  [ActivityType.DAILY_BONUS_DRAW]: activityDailyBonusDrawSearchToRequest,
  [ActivityType.NEW_YEAR_2025_DRAW]: activityNewYear2025DrawSearchToRequest,
  [ActivityType.NEW_YEAR_2025_RED_ENVELOPE]: activityNewYear2025RedEnvelopeSearchToRequest
}

export const searchToRequest: SearchToRequestFunc<ActivityRequest> = (search) => {
  const commonRequest = commonSearchToRequest(search)
  const convertOthersFunc = s2RMap[commonRequest.activity]
  if (convertOthersFunc) {
    const others = convertOthersFunc(search)
    return Object.assign({}, commonRequest, others)
  }
  return omitBy(commonRequest, isUndefined)
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const SearchDropDown: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange, handleOther, error } = useContext(FormContext)
  const searchTimeOptions = useMemo(() => {
    return Object.keys(activitySearchTimeName)
      .map((key) => Number(key) as ActivitySearchTimeType)
      .map((key) => ({ name: t(activitySearchTimeName[key]), value: key }))
  }, [t])
  return (
    <Grid item xs={12} md={6} lg={3}>
      <DropDown
        value={value.type}
        onChange={handleChange('type')}
        onBlur={handleOther('blur', 'type')}
        options={searchTimeOptions}
        label={t('common.searchDate')}
        fullWidth
        required
        error={error.type !== null}
        helperText={error.type ?? ''}
      />
    </Grid>
  )
})

const DateInput: React.FC<PropTypes> = React.memo((props) => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.time.start, value.time.end, fiveYearsAgo)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisWeek'),
        change: setThisWeek
      },
      {
        label: t('common.lastWeek'),
        change: setLastWeek
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onChange = useCallback(handleChange('time'), [])

  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      FrontComponent={props.isSub ? undefined : SearchDropDown}
    />
  )
})

const ActivityInput: React.FC = React.memo(() => {
  const { t } = useT()
  const activities = useGDKStore.activity.activities()
  const { value, handleChange } = useContext(FormContext)
  const options = useMemo(() => {
    const options = activities.map((item) => ({ name: item.name, value: item.type }))
    return options
  }, [activities])

  const onChange = useCallback(handleChange('activity'), [])
  return (
    <DropDown
      value={value.activity}
      onChange={onChange}
      label={t('common.activityType')}
      options={options}
      fullWidth
      required
    />
  )
})

const qeuryComponent: { [key in ActivityType as string]: React.ReactElement } = {
  [ActivityType.NEW_MEMBER_BONUS]: (<ActivityNewMemberBonusForm context={FormContext} />),
  [ActivityType.RECOMMEND_FRIEND]: (<ActivityRecommendFriendForm context={FormContext} />),
  [ActivityType.UPGRADE_BONUS_GOLD]: (<ActivityRankBonusForm context={FormContext} />),
  [ActivityType.DAILY_SIGN_IN_BONUS]: (<ActivityDailySignInForm context={FormContext} />),
  [ActivityType.WEEKLY_BONUS_GOLD]: (<ActivityRankBonusForm context={FormContext} />),
  [ActivityType.BIRTHDAY_BONUS_GOLD]: (<ActivityRankBonusForm context={FormContext} />),
  [ActivityType.NEW_YEAR_2023]: (<ActivityNewYear2023Form context={FormContext} />),
  [ActivityType.DRAGON_BOAT_FESTIVAL_2023]: (<ActivityDragonBoatFestival2023Form context={FormContext} />),
  [ActivityType.NEW_YEAR_2024]: (<ActivityNewYear2024Form context={FormContext} />),
  [ActivityType.DEPOSIT_BONUS]: (<ActivityDepositBonusForm context={FormContext} />),
  [ActivityType.UEFA_BUILDING_UP]: (<ActivityUefaBuildingUpForm context={FormContext} />),
  [ActivityType.UEFA_STARS]: (<ActivityUefaStarsForm context={FormContext} />),
  [ActivityType.WEEKLY_BONUS_DRAW]: (<ActivityWeeklyBonusDrawForm context={FormContext} />),
  [ActivityType.DAILY_BONUS_DRAW]: (<ActivityDailyBonusDrawForm context={FormContext} />),
  [ActivityType.NEW_YEAR_2025_DRAW]: (<ActivityNewYear2025DrawForm context={FormContext} />),
  [ActivityType.NEW_YEAR_2025_RED_ENVELOPE]: (<ActivityNewYear2025RedEnvelopeForm context={FormContext} />)
}

const OtherQuery: React.FC = React.memo(() => {
  const { value } = useContext(FormContext)
  return (
    <React.Fragment>
      {qeuryComponent[value.activity] || null}
    </React.Fragment>
  )
})

const ActivityRecordDetailForm: React.FC<PropTypes> = (props) => {
  const classes = useCommonStyles()
  const { t } = useT()
  const request = useRequestFromSearch({ searchToRequest })
  const navigate = useNavigate()
  const defaultForm = useMemo(() => {
    if (request === undefined) {
      return initialForm()
    }
    return initialForm({
      normalStatus: '--',
      recommendFriendState: '--',
      newMemberBonusState: '--',
      dailySignInState: '--',
      newYear2023Status: '--',
      newYear2024Status: '--',
      uefaBuildingUpStatus: '--',
      uefaStarsStatus: '--',
      weeklyBonusDraw: '--',
      dailyBonusDraw: '--',
      newYear2025DrawStatus: '--',
      newYear2025RedEnvelopeStatus: '--',
      ...request,
      time: {
        start: new Date(request.start_at),
        end: new Date(request.end_at)
      }
    })
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: props.isSub ? allRoute.activityRecordSub.encodePath : allRoute.activityRecordDetail.encodePath
  })

  const handleGoBack = useCallback(() => {
    const redirectTo = allRoute.activityRecord.encodePath(
      {
        param: {},
        search: omitBy({
          activity_type: request?.former_page_type,
          account: request?.account,
          agent_account: request?.agent_account,
          start_at: request?.start_at,
          end_at: request?.end_at
        }, isUndefined)
      }
    )
    navigate(redirectTo)
  }, [navigate, request])

  const validation = useMemo(() => ({
    time: [],
    type: [],
    activity: [],
    accountType: [],
    account: [],
    normalStatus: [],
    agent_account: [],
    newMemberBonusState: [],
    recommendFriendState: [],
    newYear2022Status: [],
    dailySignInState: [],
    recommend_account: [],
    recommended_account: [],
    newYear2023Status: [],
    dragonBoatFestival2023Status: [],
    newYear2024Status: [],
    uefaBuildingUpStatus: [],
    uefaStarsStatus: [],
    weeklyBonusDraw: [],
    dailyBonusDraw: [],
    newYear2025DrawStatus: [],
    newYear2025RedEnvelopeStatus: []
  } as FormValidation<ActivityFormType>), [])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            {props.isSub && <Grid item>
              <Grid container justifyContent="flex-start">
                <Grid item>
                  <Button
                    className={classes.purpleGradualButton}
                    onClick={handleGoBack}
                  >
                    {t('common.back')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>}
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {props.isSub ? t('common.statisticByActivity') : t('page.activityDetail')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput
                isSub={props.isSub}
              />
            </Grid>
            <Grid item>
              <Grid container direction="row" wrap="wrap" spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <ActivityInput />
                </Grid>
                <OtherQuery />
              </Grid>
            </Grid>
            <Grid item>
              <RequiredText />
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(ActivityRecordDetailForm)
