import React, { useContext, useMemo, useCallback, createContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { omitBy, isUndefined, enumValues } from '@golden/utils'
import { getTime, startOfDay, endOfDay, subDays, setMinutes } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import DateTimeSelect, { DateInputValue } from '../../default/form/DateTimeSelect'
import { GameCategoryType, RevenueSnapshotQuery } from '@golden/gdk-admin'
import FormStateProvider from '../../default/form/FormStateProvider'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { guaranteeNotUndefined, pipe, parseInt, guaranteeBetween, acceptUndefined, guaranteeBeOneOf, getValueFromValue } from '../../../utils/default/FormHelper'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { InitialFormFunc, useChangeUrlSubmit, SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import allRoute from '../route/route'
import FormField from '../../default/form/FormField'
import GameCategoryInput, { PropTypes as GameCategoryProps } from '../GameCategoryInput'
import { Trans } from 'react-i18next'

export interface RevenueSnapShotFormType {
  time: DateInputValue
  game_category: GameCategoryType | 'all'
}

export const initialForm: InitialFormFunc<RevenueSnapShotFormType> = (defaultForm) => ({
  time: {
    start: setMinutes(startOfDay(subDays(new Date(), 1)), 10),
    end: setMinutes(endOfDay(new Date()), 40)
  },
  game_category: 'all',
  ...defaultForm
})

const formToRequest = (form: RevenueSnapShotFormType): RevenueSnapshotQuery => {
  const converted = {
    start_at: getTime(form.time.start),
    end_at: getTime(form.time.end),
    game_category: form.game_category === 'all' ? undefined : form.game_category
  }
  return omitBy(converted, isUndefined) as unknown as RevenueSnapshotQuery
}

export const searchToRequest: SearchToRequestFunc<RevenueSnapshotQuery> = (search) => {
  const converted = {
    ...search,
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.end_at),
    game_category: acceptUndefined(search.game_category, pipe(
      (value) => guaranteeBeOneOf(Number(value), enumValues(GameCategoryType)),
      parseInt
    ))
  }
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as unknown as RevenueSnapshotQuery
}

const getTimeFromDateTimeSelectValue = (event: unknown): DateInputValue => {
  const start = (event as DateInputValue).start
  const end = (event as DateInputValue).end
  return {
    start,
    end
  }
}

const getValueFromEvent: ValueGetter<RevenueSnapShotFormType> = {
  time: getTimeFromDateTimeSelectValue,
  game_category: getValueFromValue
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const HalfHourDateTimeInput: React.FC = React.memo(() => {
  const { t } = useT()
  const useStyles = makeStyles(() => ({
    label: {
      fontSize: '10.5px'
    },
    selectGroup: {
      marginTop: '5.5px'
    }
  }))
  const classes = useStyles()
  const { value, handleChange } = useContext(FormContext)
  const today = new Date()
  const yesterday = subDays(today, 1)
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateTimeSelect
      label={t('common.searchDate2')}
      value={value.time}
      start={true}
      end={true}
      allowedDateRange={{
        start: yesterday,
        end: today
      }}
      availableMinutes={[10, 40]}
      classes={classes}
      onChange={onChange}
    />
  )
})

const GameCategoryDropdown: React.FC = React.memo(() => {
  return (
    <Box width={252}>
      <FormField<RevenueSnapShotFormType, GameCategoryProps>
        context={FormContext}
        component={GameCategoryInput}
        name="game_category"
        fullWidth
      />
    </Box>
  )
})

interface PropTypes {
  request: RevenueSnapshotQuery | undefined
}

const AdminReportRevenueSnapshotForm: React.FC<PropTypes> = (props) => {
  const { request } = props
  const { t } = useT()
  const useStyles = makeStyles(() => ({
    divider: {
      backgroundColor: '#eee',
      margin: '8px 0'
    }
  }))
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.adminReportRevenueSnapshot.encodePath
  })
  const validation = useMemo(() => {
    return {
      time: [],
      game_category: []
    } as FormValidation<RevenueSnapShotFormType>
  }, [t])
  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        game_category: request.game_category ?? 'all'
      })
    }
    return initialForm()
  }, [request])
  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={commonClasses.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.revenueSnapshot')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid container spacing={2} wrap="nowrap">
                <Grid item>
                  <HalfHourDateTimeInput />
                </Grid>
                <Grid item>
                  <FormField<RevenueSnapShotFormType, GameCategoryProps>
                    context={FormContext}
                    component={GameCategoryDropdown}
                    name="game_category"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item>
              <Grid container justifyContent="space-between" alignItems="flex-end">
                <Grid item>
                  <Typography>{t('common.revenueSnapshotNotice1')}</Typography>
                  <Typography>
                    <Trans
                      i18nKey="common.revenueSnapshotNotice2"
                      values={{
                        atTime: t('common.revenueSnapshotNoticeAtTime'),
                        period: t('common.revenueSnapshotNoticePeriod')
                      }}
                      components={{
                        span: (<span style={{ color: '#f44336' }} />)
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={commonClasses.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(AdminReportRevenueSnapshotForm)
