import React, { useMemo, createContext, useContext, useCallback } from 'react'
import { PaginationReq, IpHistoryQuery, DeviceType } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { SearchToRequestFunc, useRequestFromSearch, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { guaranteeNotUndefined, guaranteeBetween, pipe, parseInt, acceptUndefined, guaranteeBeOneOf, convertEmptyToUndefined, getTimeFromDateInputValue, getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import deviceName from '../../../constants/admin/deviceName'
import allRoutes from '../route/route'
import { createValidateIp, createValidateStartAtWithEndAt } from '../../../utils/default/Validator'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import { setToday, setYesterday, setThisMonth, getAllowedTimeStamps, createShouldDisableFuture, getCorrectedDateRange } from '../../../utils/default/TimeHelper'

export type PlayerIpRequest = PaginationReq & IpHistoryQuery

export interface PlayerIpFormType {
  time: DateInputValue
  account: string
  ip: string
  device: DeviceType | '--'
  region: string
}

export const searchToRequest: SearchToRequestFunc<PlayerIpRequest> = (search) => {
  const startTimeStamp = Number(search.start_at)
  const endTimeStamp = Number(search.end_at)
  const { minAllowedStartTimeStamp, maxAllowedEndTimeStamp } = getAllowedTimeStamps(startTimeStamp, endTimeStamp)
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, minAllowedStartTimeStamp, endTimeStamp)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, startTimeStamp, maxAllowedEndTimeStamp)
    )(search.end_at),
    device: acceptUndefined(search.device, pipe(
      (value) => guaranteeBeOneOf(value, Object.keys(deviceName)),
      parseInt
    ))
  } as PlayerIpRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as PlayerIpRequest
}

export const initialForm: InitialFormFunc<PlayerIpFormType> = (defaultForm) => ({
  time: setToday(),
  account: '',
  ip: '',
  device: '--',
  region: '',
  ...defaultForm
})

const getValueFromEvent: ValueGetter<PlayerIpFormType> = {
  time: getTimeFromDateInputValue,
  account: getValueFromChangeEvent,
  ip: getValueFromChangeEvent,
  device: getValueFromChangeEvent,
  region: getValueFromChangeEvent
}

const formToRequest = (form: PlayerIpFormType): PlayerIpRequest => {
  const converted = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    account: convertEmptyToUndefined(form.account),
    ip: convertEmptyToUndefined(form.ip),
    device: form.device === '--' ? undefined : form.device,
    region: convertEmptyToUndefined(form.region),
    page: 1
  } as PlayerIpRequest
  return omitBy(converted, isUndefined) as PlayerIpRequest
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => createShouldDisableFuture(), [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onChange = useCallback((value) => {
    handleChange('time')(getCorrectedDateRange(value))
  }, [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const PlayerIpForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        ...request
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.playerIp.encodePath
  })

  const validation = useMemo(() => ({
    time: [
      {
        func: createValidateStartAtWithEndAt('time', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    account: [],
    ip: [
      {
        func: createValidateIp('ip', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    device: [],
    region: []
  } as FormValidation<PlayerIpFormType>), [t])

  const deviceOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(deviceName)
      .map((key) => Number(key) as DeviceType)
      .map((key) => ({
        name: t(deviceName[key]),
        value: key
      })) as any
  )

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.ipHistory')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <DateInput />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<PlayerIpFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="account"
                      label={t('common.playerAccount')}
                      placeholder={t('placeholder.inputPlayerAccount')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<PlayerIpFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="ip"
                      label={t('common.loginIpAddress')}
                      placeholder={t('placeholder.inputLoginIp')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<PlayerIpFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="device"
                      label={t('common.device')}
                      options={deviceOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<PlayerIpFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="region"
                      label={t('common.provinceAndCountry')}
                      placeholder={t('placeholder.inputProvinceAndCountry')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(PlayerIpForm)
