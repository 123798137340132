import React, { useMemo, createContext, useContext, useState, useCallback } from 'react'
import { PaginationReq, AdminWithdrawStatusType, WithdrawSlipQuery } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { isValid, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, acceptUndefined, getValueFromChangeEvent, guaranteeBeKey, parseFloat, convertEmptyToUndefined, getCashInputProps, getTimeFromDateInputValue } from '../../../utils/default/FormHelper'
import allRoute from '../route/route'
import { useRequestFromSearch, SearchToRequestFunc, InitialFormFunc, useChangeUrlSubmit, useGetData } from '../../../utils/default/ComplexFlowHook'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import { createValidateMin, createValidateMax } from '../../../utils/default/Validator'
import RequiredText from '../../default/form/RequiredText'
import NumberInput from '../../default/form/NumberInput'
import adminWithdrawStatusName from '../../../constants/admin/adminWithdrawStatusName'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { createShouldDisableFuture, getAllowedTimeStamps, getCorrectedDateRange, setToday } from '../../../utils/default/TimeHelper'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'

export type SlipRequest = PaginationReq & WithdrawSlipQuery

export interface SlipFormType {
  account: string
  serial: string
  status: '--' | AdminWithdrawStatusType
  createdAt: DateInputValue
  finishedAt: DateInputValue
  min: string
  max: string
  layer_id: '--' | number
  forwarder_id: '--' | number
}

export const searchToRequest: SearchToRequestFunc<SlipRequest> = (search) => {
  const createdAtStartTimeStamp = Number(search.created_at_start)
  const createdAtEndTimeStamp = Number(search.created_at_end)
  const {
    minAllowedStartTimeStamp: minAllowedCreatedAtStartTimeStamp,
    maxAllowedEndTimeStamp: maxAllowedCreatedAtEndTimeStamp
  } = getAllowedTimeStamps(createdAtStartTimeStamp, createdAtEndTimeStamp)
  const finishedAtStartTimeStamp = Number(search.finished_at_start)
  const finishedAtEndTimeStamp = Number(search.finished_at_end)
  const {
    minAllowedStartTimeStamp: minAllowedFinishedAtStartTimeStamp,
    maxAllowedEndTimeStamp: maxAllowedFinishedAtEndTimeStamp
  } = getAllowedTimeStamps(finishedAtStartTimeStamp, finishedAtEndTimeStamp)
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    status: acceptUndefined(search.status, pipe(
      (value) => guaranteeBeKey(value, Object.keys(adminWithdrawStatusName)),
      parseInt
    )),
    created_at_start: acceptUndefined(search.created_at_start,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, minAllowedCreatedAtStartTimeStamp, createdAtEndTimeStamp)
      )
    ),
    created_at_end: acceptUndefined(search.created_at_end,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, createdAtStartTimeStamp, maxAllowedCreatedAtEndTimeStamp)
      )
    ),
    finished_at_start: acceptUndefined(search.finished_at_start,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, minAllowedFinishedAtStartTimeStamp, finishedAtEndTimeStamp)
      )
    ),
    finished_at_end: acceptUndefined(search.finished_at_end,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, finishedAtStartTimeStamp, maxAllowedFinishedAtEndTimeStamp)
      )
    ),
    cash_start: acceptUndefined(search.cash_start, parseFloat),
    cash_end: acceptUndefined(search.cash_end, parseFloat),
    layer_id: acceptUndefined(search.layer_id, parseInt),
    forwarder_id: acceptUndefined(search.forwarder_id, parseInt)
  } as SlipRequest
  if (converted.created_at_start && converted.created_at_end && converted.created_at_end < converted.created_at_start) throw new Error('The end time can\'t exceed the start time')
  if (converted.finished_at_start && converted.finished_at_end && converted.finished_at_end < converted.finished_at_start) throw new Error('The end time can\'t exceed the start time')
  if (converted.cash_start && converted.cash_end && converted.cash_end < converted.cash_start) throw new Error('The cash_end can\'t exceed the cash_start')
  return omitBy(converted, isUndefined) as SlipRequest
}

export const initialForm: InitialFormFunc<SlipFormType> = (defaultForm) => ({
  account: '',
  serial: '',
  status: '--',
  createdAt: setToday(),
  finishedAt: {
    start: null,
    end: null
  },
  min: '',
  max: '',
  layer_id: '--',
  forwarder_id: '--',
  ...defaultForm
})

const formToRequest = (form: SlipFormType): SlipRequest => {
  const converted = {
    player_account: convertEmptyToUndefined(form.account),
    order_number: convertEmptyToUndefined(form.serial),
    status: form.status === '--' ? undefined : form.status,
    created_at_start: form.createdAt.start && isValid(form.createdAt.start) ? getTime(form.createdAt.start) : undefined,
    created_at_end: form.createdAt.end && isValid(form.createdAt.end) ? getTime(form.createdAt.end) : undefined,
    finished_at_start: form.finishedAt.start && isValid(form.finishedAt.start) ? getTime(form.finishedAt.start) : undefined,
    finished_at_end: form.finishedAt.end && isValid(form.finishedAt.end) ? getTime(form.finishedAt.end) : undefined,
    cash_start: convertEmptyToUndefined(form.min),
    cash_end: convertEmptyToUndefined(form.max),
    layer_id: form.layer_id === '--' ? undefined : form.layer_id,
    forwarder_id: form.forwarder_id === '--' ? undefined : form.forwarder_id,
    page: 1
  } as SlipRequest
  return omitBy(converted, isUndefined) as SlipRequest
}

const getValueFromEvent: ValueGetter<SlipFormType> = {
  account: getValueFromChangeEvent,
  serial: getValueFromChangeEvent,
  status: getValueFromChangeEvent,
  createdAt: getTimeFromDateInputValue,
  finishedAt: getTimeFromDateInputValue,
  min: getValueFromChangeEvent,
  max: getValueFromChangeEvent,
  layer_id: getValueFromChangeEvent,
  forwarder_id: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const cashInputProps = getCashInputProps()

type DateType = 'createdAt' | 'finishedAt'
const DateInput: React.FC<{ dateType: DateType, startLabel?: string, endLabel?: string }> = React.memo((props) => {
  const { dateType, startLabel, endLabel } = props
  const { value, handleChange } = useContext(FormContext)
  const { t } = useT()
  const time = useMemo(() => ({ start: value[dateType].start, end: value[dateType].end }), [value[dateType]])
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => createShouldDisableFuture(), [])
  const startOption = useMemo(() => ({
    label: startLabel ?? t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])
  const endOption = useMemo(() => ({
    label: endLabel ?? t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])
  const onChange = useCallback((value) => {
    handleChange(dateType)(getCorrectedDateRange(value))
  }, [])
  return <DateInputBase
    sm
    value={time}
    onChange={onChange}
    start={startOption}
    end={endOption}
  />
})

const WithdrawalFMSlipForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()

  const request = useRequestFromSearch({ searchToRequest })
  const layers = useGDKStore.player.layers()
  const [couriers, setCouriers] = useState<Array<{ id: number, name: string }>>([])

  useGetData({
    gdkFunc: () => gdk.withdraw.getCourierList(),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setCouriers(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm(omitBy({
        account: request.player_account,
        serial: request.order_number,
        status: request.status,
        createdAt: {
          start: request.created_at_start ? new Date(request.created_at_start) : null,
          end: request.created_at_end ? new Date(request.created_at_end) : null
        },
        finishedAt: {
          start: request.finished_at_start ? new Date(request.finished_at_start) : null,
          end: request.finished_at_end ? new Date(request.finished_at_end) : null
        },
        min: request.cash_start?.toString() ?? '',
        max: request.cash_end?.toString() ?? '',
        layer_id: request.layer_id ?? '--',
        forwarder_id: request.forwarder_id ?? '--'
      }, isUndefined))
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.withdrawalFMSlip.encodePath
  })

  const validation = useMemo(() => ({
    account: [],
    serial: [],
    status: [],
    createdAt: [],
    finishedAt: [],
    min: [
      {
        func: createValidateMin('min', 'max', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    max: [
      {
        func: createValidateMax('min', 'max', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    layer_id: [],
    forwarder_id: []
  } as FormValidation<SlipFormType>), [t])

  const statusOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(adminWithdrawStatusName)
      .map((key) => Number(key) as AdminWithdrawStatusType)
      .map((key) => ({
        name: t(adminWithdrawStatusName[key]),
        value: key
      })) as any
  )

  const layerOptions = useMemo(() => {
    return [
      { name: t('common.all'), value: '--' }
    ]
      .concat(
        layers.map((item) => ({ name: item.name, value: item.id })) as any
      )
  }, [t, layers])

  const courierOptions = useMemo(() => {
    return [
      { name: t('common.all'), value: '--' }
    ]
      .concat(
        couriers.map((item) => ({ name: item.name, value: item.id })) as any
      )
  }, [t, couriers])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.withdrawFMManage')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="account"
                      label={t('common.playerAccount')}
                      placeholder={t('placeholder.inputPlayerAccount')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="serial"
                      label={t('common.orderNumber')}
                      placeholder={t('placeholder.inputOrderNumber')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="status"
                      options={statusOptions}
                      label={t('common.orderStatus2')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={6}>
                    <DateInput
                      dateType="createdAt"
                      startLabel={t('common.applyTime') + t('common.beginAt')}
                      endLabel={t('common.applyTime') + t('common.endAt')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateInput
                      dateType="finishedAt"
                      startLabel={t('common.confirmTime') + t('common.beginAt')}
                      endLabel={t('common.confirmTime') + t('common.endAt')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="min"
                      label={t('common.minOrderMoney')}
                      placeholder={t('placeholder.inputMinOrderMoney')}
                      inputProps={cashInputProps}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="max"
                      label={t('common.maxOrderMoney')}
                      placeholder={t('placeholder.inputMaxOrderMoney')}
                      inputProps={cashInputProps}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<SlipFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="layer_id"
                      label={t('common.playerLayer')}
                      options={layerOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<SlipFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="forwarder_id"
                      label={t('common.courierName')}
                      options={courierOptions}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <RequiredText />
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(WithdrawalFMSlipForm)
