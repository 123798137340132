import React, { useCallback, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import { SearchToRequestFunc, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import GameIdInput from '../GameIdInput'
import { GameFactory } from '@golden/game-china'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
interface PropTypes {
  title: string
  showSearch?: boolean
  showGamesFromAPI?: boolean
  onChange: (form: { gameId: number, nameOrCode?: string }) => void
  warning?: boolean
  isHidingModifiedFeaturesHiddenGames?: boolean
}

export const searchToRequest: SearchToRequestFunc<{ gameId: number, page: number, nameOrCode?: string }> = (search) => {
  return {
    gameId: search.gameId ? Number(search.gameId) : GameFactory.getFirstId(),
    page: search.page ? Number(search.page) : 1,
    ...((search.nameOrCode ?? '') === '' ? {} : { nameOrCode: search.nameOrCode })
  }
}

const ExternalGameChannelForm: React.FC<PropTypes> = (props) => {
  const { title, showSearch = false, showGamesFromAPI = false, onChange, warning, isHidingModifiedFeaturesHiddenGames = false } = props
  const request = useRequestFromSearch({ searchToRequest })
  const globalDialog = useGlobalDialog()
  const classes = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const platforms = useGDKStore.platform.platforms()
  const filterNoGames = platforms
    .flatMap((platform) => platform.games.filter((game) => {
      return game.instance.customGameHallSetting?.externalGameChannelSetting.isFromAPI
    }))
    .map((game) => game.id)
  const [tempGame, setTempGame] = useState<number>(0)
  const [nameOrCode, setNameOrCode] = useState<string>(request?.nameOrCode ?? '')
  const { t } = useT()

  useDialogFlow(globalDialog, 'changeList', () => {
    if (tempGame) {
      onChange({ gameId: tempGame })
    }
  }, [tempGame, onChange])

  return (
    <Box padding={4}>
      <Box
        paddingY={1.25}
        paddingX={2}
        className={classes.pinkTitleBar}
      >
        <Typography variant="h5">
          {title}
        </Typography>
      </Box>
      <Box paddingTop={2}>
        <Grid container direction="row" spacing={2} alignItems="flex-end">
          <Grid item xs={12} md={3}>
            <GameIdInput
              fullWidth
              noAll
              filterNoGames={showGamesFromAPI ? [] : filterNoGames}
              value={request?.gameId}
              isHidingModifiedFeaturesHiddenGames={isHidingModifiedFeaturesHiddenGames}
              onChange={useCallback((value) => {
                if (warning) {
                  globalDialog.setConfig(createGlobalDialogConfig({
                    variant: 'info',
                    showIcon: false,
                    notUseTypo: true,
                    message: (
                      <React.Fragment>
                        <div className={dialogClasses.title}>
                          {t('dialog.changeChannelList')}
                        </div>
                        <div className={dialogClasses.text}>
                          {t('dialog.unsaveLost')}
                        </div>
                      </React.Fragment>
                    )
                  }))
                  globalDialog.setOpen({
                    id: 'changeList',
                    isOK: false,
                    value: true
                  })
                  setTempGame(value)
                  return false
                } else {
                  onChange({ gameId: value })
                }
              }, [warning, t, dialogClasses, onChange])}
            />
          </Grid>
          {showSearch && (
            <>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  placeholder={t('common.searchNameOrCode')}
                  value={nameOrCode}
                  onChange={useCallback((value) => {
                    setNameOrCode(value.target.value)
                  }, [])}
                />
              </Grid>
              <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                className={classes.purpleGradualButton}
                onClick={useCallback(() => {
                  onChange({
                    gameId: request?.gameId ?? 0,
                    nameOrCode
                  })
                }, [onChange, nameOrCode, request])}
              >
                {t('common.search')}
              </Button>
            </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export default React.memo(ExternalGameChannelForm)
