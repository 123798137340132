import React, { useMemo, useContext, useCallback, createContext } from 'react'
import { PaginationReq, ProfitQuery, GameCategoryType, GameType } from '@golden/gdk-admin'
import { omitBy, isUndefined, enumValues } from '@golden/utils'
import { getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { SearchToRequestFunc, useRequestFromSearch, useChangeUrlSubmit, InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { guaranteeNotUndefined, guaranteeBetween, guaranteeBeOneOf, pipe, parseInt, acceptUndefined, getDateFromDateInputValue, getValueFromValue } from '../../../utils/default/FormHelper'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import { setToday, setThisMonth, setYesterday, setLastMonth, getAllowedTimeStamps, createShouldDisableFuture, getCorrectedDateRange } from '../../../utils/default/TimeHelper'
import allRoute from '../route/route'
import GameInput, { PropTypes as GameInputProps } from '../GameInput'

export type AdminReportProfitRequest = PaginationReq & ProfitQuery & {
  nextPage?: number
  nodeId?: number
}

export interface AdminReportProfitFormType {
  time: DateInputValue
  game: {
    game_category: GameCategoryType | 'all'
    game_id: GameType | 'all' | 'noGame'
  }
}

export const searchToRequest: SearchToRequestFunc<AdminReportProfitRequest> = (search) => {
  const startTimeStamp = Number(search.start_at)
  const endTimeStamp = Number(search.end_at)
  const { minAllowedStartTimeStamp, maxAllowedEndTimeStamp } = getAllowedTimeStamps(startTimeStamp, endTimeStamp)
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, minAllowedStartTimeStamp, endTimeStamp)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, startTimeStamp, maxAllowedEndTimeStamp)
    )(search.end_at),
    game_category: acceptUndefined(search.game_category, pipe(
      (value) => guaranteeBeOneOf(Number(value), enumValues(GameCategoryType)),
      parseInt
    )),
    game_id: acceptUndefined(search.game_id, pipe(
      (value) => guaranteeBeOneOf(Number(value), enumValues(GameType)),
      parseInt
    )),
    nextPage: acceptUndefined(search.nextPage, parseInt) ?? 1,
    nodeId: acceptUndefined(search.nodeId, parseInt) ?? 0
  } as AdminReportProfitRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as AdminReportProfitRequest
}

export const initialForm: InitialFormFunc<AdminReportProfitFormType> = (defaultForm) => ({
  time: setToday(),
  game: {
    game_category: 'all',
    game_id: 'all'
  },
  ...defaultForm
})

const getValueFromEvent: ValueGetter<AdminReportProfitFormType> = {
  time: getDateFromDateInputValue,
  game: getValueFromValue
}

const formToRequest = (form: AdminReportProfitFormType): AdminReportProfitRequest => {
  const converted = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    game_category: form.game.game_category === 'all' ? undefined : form.game.game_category,
    game_id: form.game.game_id === 'all' || form.game.game_id === 'noGame' ? undefined : form.game.game_id,
    page: 1
  } as AdminReportProfitRequest
  const omitted = omitBy(converted, isUndefined)
  return omitted as AdminReportProfitRequest
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => createShouldDisableFuture(), [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onChange = useCallback((value) => {
    handleChange('time')(getCorrectedDateRange(value))
  }, [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      onlyDate
    />
  )
})

const AdminReportProfitForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        game: {
          game_category: request.game_category ?? 'all',
          game_id: request.game_id ?? 'all'
        },
        ...request
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.adminReportProfit.encodePath
  })

  const validation = useMemo(() => ({
    time: [],
    account: [],
    game: []
  } as FormValidation<AdminReportProfitFormType>), [])

  return (
    <Paper>
      <Box
        paddingY={1.25}
        paddingX={2}
        className={classes.pinkTitleBar}
      >
        <Typography variant="h5">
          {t('common.adminProfitSearch')}
        </Typography>
      </Box>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <FormStateProvider
            context={FormContext}
            defaultValue={defaultForm}
            onSubmit={handleSubmit}
            validation={validation}
            getValueFromEvent={getValueFromEvent}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <DateInput />
              </Grid>
              <Grid item>
                <FormField<AdminReportProfitFormType, GameInputProps>
                  context={FormContext}
                  component={GameInput}
                  name="game"
                />
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormStateProvider>
        </LoadingAndErrorFrame>
      </Box>
    </Paper>
  )
}

export default React.memo(AdminReportProfitForm)
