enum RouteKey {
  NOT_FOUND = 'notFound',
  HOME = 'home',
  LOGIN = 'login',
  LOGOUT = 'logout',
  BASE_DATA = 'baseData',
  GREETING = 'greeting',
  FORESTAGE_ANNOUNCEMENT = 'forestageAnnouncement',
  FORESTAGE_ANNOUNCEMENT_CREATE = 'forestageAnnouncementCreate',
  FORESTAGE_ANNOUNCEMENT_UPDATE = 'forestageAnnouncementUpdate',
  FORESTAGE_ANNOUNCEMENT_LOOK = 'forestageAnnouncementLook',
  FORESTAGE_AGENT_ANNOUNCEMENT = 'forestageAgentAnnouncement',
  FORESTAGE_NOTIFICATION = 'forestageNotification',
  FORESTAGE_NOTIFICATION_UPDATE = 'forestageNotificationUpdate',
  FORESTAGE_PERSONAL = 'forestagePersonal',
  FORESTAGE_PERSONAL_CREATE = 'forestagePersonalCreate',
  FORESTAGE_PERSONAL_CREATE_BY_TEMPLATE = 'forestagePersonalCreateByTemplate',
  FORESTAGE_PERSONAL_TEMPLATE = 'forestagePersonalTemplate',
  FORESTAGE_PERSONAL_TEMPLATE_MANAGEMENT = 'forestagePersonalTemplateManagement',
  FORESTAGE_SMS = 'forestageSms',
  FORESTAGE_DOWNLOAD_LINK = 'forestageThirdPartDownloadLink',
  FORESTAGE_JOINT_VENTURE_SETTING = 'forestageJointVentureSetting',
  FORESTAGE_BANNER_NOTIFICATION = 'forestageBannerNotification',
  FORESTAGE_BANNER_NOTIFICATION_CREATE = 'forestageBannerNotificationCreate',
  FORESTAGE_BANNER_NOTIFICATION_COPY = 'forestageBannerNotificationCopy',
  EXTERNAL_GAME = 'externalGame',
  EXTERNAL_GAME_FEEDBACK = 'externalGameFeedback',
  EXTERNAL_GAME_FEEDBACK_UPDATE = 'externalGameFeedbackUpdate',
  EXTERNAL_GAME_CHANNEL = 'externalGameChannel',
  EXTERNAL_GAME_CHANNEL_CREATE = 'externalGameChannelCreate',
  ACCOUNT_EMPEROR = 'accountEmperor',
  ACCOUNT_EMPEROR_LAYER = 'accountEmperorLayer',
  ACCOUNT_EMPEROR_CREATE = 'accountEmperorCreate',
  ACCOUNT_EMPEROR_UPDATE = 'accountEmperorUpdate',
  ACCOUNT_AGENT_WALLET = 'agentWalletHistory',
  ACCOUNT_AGENT_WALLET_RECHARGE = 'agentWalletRecharge',
  ACCOUNT_AGENT_WALLET_REVOKE = 'agentWalletRevoke',
  ACCOUNT_AGENT_OPERATION_LOG = 'accountAgentOperationLog',
  PLAYER_REPORT_BET = 'playerReportBet',
  PLAYER_REPORT_FETCH_TASK = 'playerReportFetchTask',
  PLAYER_REPORT_TRANSFER = 'playerReportTransfer',
  PLAYER_REPORT_TRANSFER_UPDATE = 'playerReportTransferUpdate',
  PLAYER_REPORT_CASH = 'playerReportCash',
  PLAYER_REPORT_PROFIT = 'playerReportProfit',
  PLAYER_REPORT_PROFIT_EXPORT = 'playerReportProfitExport',
  PLAYER_REPORT_CASH_ACCOUNTING = 'playerCashAccounting',
  PLAYER_REPORT_WINLOSS_ANALYZE = 'playerReportWinlossAnalyze',
  PLAYER_REPORT_FEEDBACK_HISTORY = 'playerReportFeedbackHistory',
  ADMIN_REPORT_PROFIT = 'adminReportProfit',
  ADMIN_REPORT_PROFIT_LAYER = 'adminReportProfitLayer',
  ADMIN_REPORT_DEPOSIT_STATISTIC = 'adminReportDepositStatistic',
  ADMIN_REPORT_DEPOSIT_STATISTIC_DETAIL = 'adminReportDepositStatisticDetail',
  ADMIN_REPORT_NEW_PEOPLE_STATISTIC = 'adminReportNewPeopleStatistic',
  ADMIN_REPORT_DEVICE_STATISTIC = 'adminReportDeviceStatistic',
  ADMIN_REPORT_COMPANY_STATISTIC = 'adminReportCompanyStatistic',
  ADMIN_REPORT_IN_AND_OUT = 'adminReportInAndOut',
  ADMIN_REPORT_PLATFORM_FEE = 'adminReportPlatformFee',
  ADMIN_REPORT_PLAYER_LEADERBOARD = 'adminReportPlayerLeaderboard',
  ADMIN_REPORT_PLAYER_LEADERBOARD_DETAIL = 'adminReportPlayerLeaderboardDetail',
  ADMIN_REPORT_REVENUE_SNAPSHOT = 'adminReportRevenueSnapshot',
  FINANCE_PARAMETER = 'financeParameter',
  FINANCE_BLACKLIST = 'financeBlacklist',
  FINANCE_BLACKLIST_CREATE = 'financeBlacklistCreate',
  FINANCE_COURIER = 'FinanceCourier',
  FINANCE_COURIER_CREATE = 'FinanceCourierCreate',
  FINANCE_COURIER_UPDATE = 'FinanceCourierUpdate',
  FINANCE_DEPOSIT_WAY = 'financeDepositWay',
  FINANCE_DEPOSIT_WAY_CREATE = 'financeDepositWayCreate',
  FINANCE_DEPOSIT_WAY_UPDATE = 'financeDepositWayUpdate',
  FINANCE_WITHDRAWAL_WAY = 'financeWithdrawalWay',
  FINANCE_WITHDRAWAL_WAY_CREATE = 'financeWithdrawalWayCreate',
  FINANCE_WITHDRAWAL_WAY_UPDATE = 'financeWithdrawalWayUpdate',
  FINANCE_BANK = 'financeBank',
  FINANCE_BANK_CODE = 'financeBankCode',
  FINANCE_BANK_UPDATE = 'financeBankUpdate',
  FINANCE_PIGGY_BANK = 'financePiggyBank',
  FINANCE_PIGGY_BANK_CREATE = 'financePiggyBankCreate',
  FINANCE_PIGGY_BANK_UPDATE = 'financePiggyBankUpdate',
  FINANCE_PIGGY_WECHAT = 'financePiggyWechat',
  FINANCE_PIGGY_WECHAT_CREATE = 'financePiggyWechatCreate',
  FINANCE_PIGGY_WECHAT_UPDATE = 'financePiggyWechatUpdate',
  FINANCE_PIGGY_ALIPAY = 'financePiggyAlipay',
  FINANCE_PIGGY_ALIPAY_CREATE = 'financePiggyAlipayCreate',
  FINANCE_PIGGY_ALIPAY_UPDATE = 'financePiggyAlipayUpdate',
  FINANCE_INTERNAL_WITHDRAW = 'financeInternalWithdraw',
  FINANCE_INTERNAL_WITHDRAW_ACCOUNT = 'financeInternalWithdrawAccount',
  FINANCE_INTERNAL_WITHDRAW_CREATE = 'financeInternalWithdrawCreate',
  FINANCE_INTERNAL_WITHDRAW_EXECUTE = 'financeInternalWithdrawExecute',
  FINANCE_INTERNAL_WITHDRAW_HISTORY = 'financeInternalWithdrawHistory',
  DEPOSIT_CRAWLER = 'depositCrawler',
  DEPOSIT_SLIP = 'depositSlip',
  DEPOSIT_THIRD_PARTY_SLIP = 'depositThirdPartySlip',
  DEPOSIT_ERROR = 'depositError',
  WITHDRAWAL_RC_SLIP = 'withdrawalRCSlip',
  WITHDRAWAL_FM_SLIP = 'withdrawalFMSlip',
  WITHDRAWAL_TIP = 'withdrawalTip',
  WITHDRAWAL_LIMIT = 'withdrawalLimit',
  WITHDRAWAL_LIMIT_CREATE = 'withdrawalLimitCreate',
  WITHDRAWAL_LIMIT_UPDATE = 'withdrawalLimitUpdate',
  MANUAL_EFFECTIVE_CASH_UPDATE = 'manualEffectiveCashUpdate',
  MANUAL_EFFECTIVE_CASH_HISTORY = 'manualEffectiveCashHistory',
  MANUAL_DEPOSIT = 'manualDeposit',
  MANUAL_DEPOSIT_CREATE = 'manualDepositCreate',
  MANUAL_WITHDRAW = 'manualWithdraw',
  MANUAL_WITHDRAW_CREATE = 'manualWithdrawCreate',
  SYSTEM_GOOGLE = 'systemGoogle',
  SYSTEM_IP_WHITELIST = 'systemIpWhitelist',
  SYSTEM_IP_WHITELIST_CREATE = 'systemIpWhitelistCreate',
  PLAYER = 'player',
  PLAYER_DETAIL = 'playerDetail',
  PLAYER_IP = 'playerIp',
  PLAYER_GAME_LOGIN_HISTORY = 'playerGameLoginHistory',
  PLAYER_IP_MONITORING = 'playerIpMonitoring',
  PLAYER_JUMP_SHIP = 'playerJumpShip',
  PLAYER_JUMP_SHIP_CREATE = 'playerJumpShipCreate',
  PLAYER_LAYER = 'playerLayer',
  PLAYER_LAYER_UPDATE = 'playerLayerUpdate',
  PLAYER_RANK = 'playerRank',
  PLAYER_RANK_UPDATE = 'playerRankUpdate',
  PLAYER_RANK_EFFECTIVE_BET = 'playerRankEffectiveBet',
  PLAYER_RANK_EFFECTIVE_BET_UPDATE = 'playerRankEffectiveBetUpdate',
  PLAYER_OPERATION_LOGS = 'playerOperationLogs',
  ROLE_MANAGEMENT = 'roleManagement',
  ROLE_MANAGEMENT_CREATE = 'roleManagementCreate',
  ROLE_MANAGEMENT_UPDATE = 'roleManagementUpdate',
  MANAGER_LOGIN_HISTORY = 'managerLoginHistory',
  MANAGER_ACCOUNT = 'managerAccount',
  MANAGER_ACCOUNT_CREATE = 'managerAccountCreate',
  MANAGER_ACCOUNT_UPDATE = 'managerAccountUpdate',
  JOURNAL_OPERATION = 'journalOperation',
  JOURNAL_EVENT = 'journalEvent',
  CS = 'cs',
  ACTIVITY_MANAGEMENT = 'activityManagement',
  ACTIVITY_MANAGEMENT_LAYOUT_CREATE = 'activityManagementLayoutCreate',
  ACTIVITY_MANAGEMENT_PREVIEW = 'activityManagementPreview',
  ACTIVITY_MANAGEMENT_LAYOUT_UPDATE = 'activityManagementLayoutUpdate',
  ACTIVITY_MANAGEMENT_LAYOUT_LOOK = 'activityManagementLayoutLook',
  ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_UPDATE = 'activityManagementLayoutSystemUpdate',
  ACTIVITY_MANAGEMENT_LAYOUT_SYSTEM_LOOK = 'activityManagementLayoutSystemLook',
  ACTIVITY_MANAGEMENT_BANNER_CREATE = 'activityManagementBannerCreate',
  ACTIVITY_MANAGEMENT_BANNER_UPDATE = 'activityManagementBannerUpdate',
  ACTIVITY_MANAGEMENT_BANNER_LOOK = 'activityManagementBannerLook',
  ACTIVITY_RECORD = 'activityRecord',
  ACTIVITY_RECORD_SUB = 'activityRecordSub',
  ACTIVITY_RECORD_AGENT_SUB = 'activityRecordAgentSub',
  ACTIVITY_RECORD_DETAIL = 'activityRecordDetail',
  ACTIVITY_APPLICATION = 'activityApplication',
  ACTIVITY_WALLET = 'activityWallet',
  ACTIVITY_WALLET_CREATE = 'activityWalletCreate'
}

export default RouteKey
