import React, { useMemo, useCallback } from 'react'
import {
  LineChart as LineChartBase,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  CartesianGrid,
  LineProps,
  Legend,
  Tooltip
} from 'recharts'
import { makeStyles } from '@material-ui/core/styles'
import { formatCount } from '../../../utils/default/TableHelper'

const useStyles = makeStyles((theme) => ({
  label: {
    fill: theme.palette.text.primary
  }
}))

export interface PropTypes<T extends object> {
  data: T[]
  dataKey: keyof T
  xLabel?: string
  yLabel?: string
  lines: Array<{
    name: string
    type?: LineProps['type']
    dataKey: keyof T
    stroke: string
  }>
  height: number
  gridStroke?: string
  chartMarginRight?: number
  interval?: number
  allowDataOverflow?: boolean
  angle?: number
  legendStyles?: React.CSSProperties
  removeTooltipDecimal?: boolean
}

const LineChart = function<T extends object> (props: PropTypes<T>) {
  const { data, dataKey, xLabel, yLabel, lines, height, gridStroke, chartMarginRight, interval, allowDataOverflow, angle, legendStyles, removeTooltipDecimal = true } = props
  const classes = useStyles()
  const array = useMemo(() => {
    return data.map((item) => lines.map((line) => item[line.dataKey])).reduce((item, array) => array.concat(item), [])
  }, [data, lines])
  const max = useMemo(() => {
    if (!interval) return 'auto'
    const maxData = array.length ? array.reduce<T[keyof T]>((pre, cur) => cur > pre ? cur : pre, array[0]) : null
    if (!maxData) return 'auto'
    return (Math.ceil(Number(maxData) / interval) + 1) * interval
  }, [array, interval])
  const min = useMemo(() => {
    if (!interval) return 'auto'
    const minData = array.length ? array.reduce<T[keyof T]>((pre, cur) => cur < pre ? cur : pre, array[0]) : null
    if (Number(minData) === 0) return 0
    if (!minData) return 'auto'
    return (Math.ceil(Number(minData) / interval) - 1) * interval
  }, [array, interval])
  const ticks = useMemo(() => {
    if (!interval || max === 'auto' || min === 'auto') return undefined
    const maxNumber = Number(max)
    const minNumber = Number(min) > 0 ? 0 : Number(min)
    const length = (maxNumber - minNumber) / interval
    if (length > 5000) {
      const newInterval = Math.floor((maxNumber - minNumber) / 5000)
      return (new Array(Math.ceil((maxNumber - minNumber) / newInterval))).fill(1).map((_, index) => minNumber + (index * newInterval))
    }
    return (new Array(length)).fill(1).map((_, index) => minNumber + (index * interval))
  }, [interval, max, min])
  const formatter = useCallback((tick) => {
    return formatCount(tick)
  }, [])
  return (
    <ResponsiveContainer height={height}>
      <LineChartBase data={data} margin={{ top: 32, right: chartMarginRight ?? 0 }}>
        <XAxis
          angle={angle}
          allowDataOverflow={allowDataOverflow}
          dataKey={dataKey as string}
          label={{ value: xLabel, position: 'right', offset: 24, className: classes.label }}
          tickMargin={20}
        />
        <YAxis
          label={{ value: yLabel, position: 'top', offset: 16, className: classes.label }}
          ticks={ticks}
          domain={[min, max]}
          interval="preserveStartEnd"
          tickFormatter={formatter}
        />
        {lines.map((line) => (
          <Line name={line.name} key={line.dataKey as string} type={line.type ?? 'linear'} dataKey={line.dataKey as string} stroke={line.stroke} connectNulls />
        ))}
        <CartesianGrid stroke={gridStroke ?? '#d2d2d2'} strokeDasharray="5 5" />
        <Tooltip
          formatter={removeTooltipDecimal ? formatter : undefined}
        />
        <Legend wrapperStyle={{ ...legendStyles, color: '#000' }} />
      </LineChartBase>
    </ResponsiveContainer>
  )
}

export default LineChart
