import React, { useState, useEffect, useMemo, useCallback } from 'react'
import clsx from 'clsx'
import {
  PaginationRes,
  WithdrawRCSlipSum,
  WithdrawRCSlip,
  WithdrawWayType,
  RCStatusType,
  AccountWarnedType,
  GDKError,
  PermissionType,
  getTargetURL
} from '@golden/gdk-admin'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { searchToRequest } from './WithdrawalRCSlipForm'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, useReload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData, formatCount, formatMoney } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute from '../route/route'
import DateTime from '../../default/present/DateTime'
import rcStatusName from '../../../constants/admin/rcStatusName'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import PlayerIsWarnedIcon from '../PlayerIsWarnedIcon'
import RefreshCount from '../RefreshCount'
import WithdrawalRCSlipDetailDialog, { DialogPayloadContext, defaultPayload } from './WithdrawalRCSlipDetailDialog'
import StateProvider from '../../../providers/default/StateProvider'
import WithdrawalRCSlipButtons from './WithdrawalRCSlipButtons'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { useDebouncedCallback } from 'use-debounce/lib'
import { getServerUrl } from '../../../utils/default/StageHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

const useStyles = makeStyles((theme) => ({
  noMargin: {
    margin: '0px auto'
  },
  moneyText: {
    color: '#55B559',
    margin: '0px auto'
  },
  usdtText: {
    color: '#aaaaaa',
    margin: '0px auto'
  },
  lockText: {
    color: theme.palette.common.black
  },
  denseTable: {
    padding: '6px 12px 6px 12px'
  },
  preNowrap: {
    whiteSpace: 'pre'
  },
  warnTipGrid: {
    width: '20px'
  },
  accountText: {
    minWidth: '100px',
    maxWidth: '120px'
  }
}))

interface SumRowType {
  id: number
  type: string
  cash: string | React.ReactElement
  count: string
}

interface RowType {
  id: number
  serial: string
  account: React.ReactElement
  staffAccount: string
  bankAccount: React.ReactElement
  cash: React.ReactElement | string
  status: React.ReactElement | string
  functions: React.ReactElement
  updatedBy: string
  applyAt: React.ReactElement
  confirmAt: React.ReactElement
  rcApplyAt: React.ReactElement
}

const WithdrawalRCSlipTable: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const me = useGDKStore.admin.me()
  const globalDialog = useGlobalDialog()
  const writableExport = useChecker([PermissionType.WITHDRAW_RC_SLIP_EXPORT])
  const { reload, reloadFlag } = useReload()
  const [list, setList] = useState<PaginationRes<WithdrawRCSlip[]> & WithdrawRCSlipSum>({
    ...createDefaultPaginationData([]),
    rmb: {
      cash_sum: '0.0000',
      count: 0
    },
    usdt: {
      cash_sum: '0.0000',
      count: 0,
      cash_crypto_sum: '0.0000'
    }
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useEffect(() => {
    if (request !== undefined) pageFlow.setLoadingStart()
  }, [request])
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.withdraw.getWithdrawRCSlipList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onSuccess: (res: PaginationRes<WithdrawRCSlip[]> & WithdrawRCSlipSum) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: clsx(commonClasses.tableCellHead, classes.denseTable),
    cellBody: classes.denseTable
  }), [commonClasses, classes])
  const sumRows: SumRowType[] = useMemo(() => {
    return [
      {
        id: 1,
        type: 'RMB',
        count: formatCount(list.rmb.count),
        cash: formatMoney(list.rmb.cash_sum)
      },
      {
        id: 2,
        type: 'USDT',
        count: formatCount(list.usdt.count),
        cash: (
          <React.Fragment>
            <p className={classes.noMargin}>{ formatMoney(list.usdt.cash_sum) }</p>
            <p className={classes.usdtText}>{ formatMoney(list.usdt.cash_crypto_sum) } USDT</p>
          </React.Fragment>
        )
      }
    ] as SumRowType[]
  }, [list.rmb, list.usdt, classes.noMargin, classes.usdtText])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      const mins = Math.floor((Number(new Date()) - Number(item.locked_at)) / 1000 / 60)
      const isWarned = (item.rc_warned || item.fm_warned)
      return {
        id: item.id,
        serial: item.order_number,
        account: (
          <Box paddingY={1}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid container direction="row" justifyContent="center" alignItems="center" wrap="nowrap">
                  {isWarned && (
                    <Grid container item className={classes.warnTipGrid} direction="column" wrap="nowrap" alignItems="center" justifyContent="center">
                      <PlayerIsWarnedIcon isWarned={item.fm_warned} />
                      <PlayerIsWarnedIcon isWarned={item.rc_warned} warnedType={AccountWarnedType.RISK_CONTROL} />
                    </Grid>
                  )}
                  <Grid item className={classes.accountText}>{item.player_account}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ),
        staffAccount: item.staff_account,
        bankAccount: (
          <Box paddingY={1} className={commonClasses.nowrap}>
            <Grid container direction="column" spacing={1}>
              <Grid item>{item.bank_account || '--'}{item.method === WithdrawWayType.AGENT_TRANSFER_WITHDRAW && '*'}</Grid>
              {item.has_duplicate_bank_account && (<Grid item><Typography variant="body2" color="error">{t('common.duplicateBind')}</Typography></Grid>)}
            </Grid>
          </Box>
        ),
        cash: item.method === WithdrawWayType.CURRENCY_CRYPTO_ERC || item.method === WithdrawWayType.CURRENCY_CRYPTO_TRC || item.method === WithdrawWayType.CURRENCY_CGPAY_USDT
          ? (
          <React.Fragment>
            <p>{formatMoney(item.cash)}</p>
            <p className={classes.usdtText}>{item.cash_crypto ? formatMoney(item.cash_crypto) : '-'} USDT</p>
          </React.Fragment>
            )
          : formatMoney(item.cash),
        status: (
          <Box paddingY={1} className={commonClasses.nowrap}>
            <Grid container direction="column" spacing={1}>
              {(item.rc_status !== RCStatusType.REVIEWING || !item.locked_at) && (
                <Grid item>{t(rcStatusName[item.rc_status])}</Grid>
              )}
              {item.rc_status === RCStatusType.REVIEWING && item.locked_at && (
                <Grid item className={classes.preNowrap}>
                  {t('common.lockTime', { mins: mins < 0 ? 0 : mins })}
                </Grid>
              )}
              {item.first_use_debit_card && (<Grid item><Typography variant="body2" color="error">{t('common.isFirstWithdrawWithDebitCard')}</Typography></Grid>)}
              {(item.is_first && !item.first_use_debit_card) && (<Grid item><Typography variant="body2" color="error">{t('common.isFirstWithdraw')}</Typography></Grid>)}
            </Grid>
          </Box>
        ),
        functions: (<WithdrawalRCSlipButtons item={item} reload={reload} />),
        updatedBy: item.updated_by,
        applyAt: (<DateTime time={item.created_at} />),
        confirmAt: item.finished_at ? (<DateTime time={item.finished_at} />) : (<p></p>),
        rcApplyAt: item.rc_apply_at ? (<DateTime time={item.rc_apply_at} />) : (<p></p>)
      } as RowType
    })
  }, [classes.lockText, request, list.data, t, me])
  const sumData = useMemo(() => {
    return createTableData<SumRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        type: {
          label: t('common.orderType'),
          value: 'type',
          align: 'center'
        },
        cash: {
          label: t('common.cash'),
          value: 'cash',
          align: 'center'
        },
        count: {
          label: t('common.amount'),
          value: 'count',
          align: 'center'
        }
      },
      [
        'type',
        'cash',
        'count'
      ],
      sumRows,
      'id'
    )
  }, [sumRows, t])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        serial: {
          label: t('common.orderNumber'),
          value: 'serial',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        staffAccount: {
          label: t('common.agentAccount3'),
          value: 'staffAccount',
          align: 'center'
        },
        bankAccount: {
          label: `${t('common.cardNo')}/${t('common.address')}`,
          value: 'bankAccount',
          align: 'center'
        },
        cash: {
          label: t('common.orderMoney'),
          value: 'cash',
          align: 'center'
        },
        status: {
          label: t('common.orderStatus2'),
          value: 'status',
          align: 'center'
        },
        functions: {
          label: t('common.operation'),
          value: 'functions',
          align: 'center'
        },
        updatedBy: {
          label: t('common.updateBy'),
          value: 'updatedBy',
          align: 'center'
        },
        applyAt: {
          label: t('common.applyAt'),
          value: 'applyAt',
          align: 'center'
        },
        confirmAt: {
          label: t('common.confirmDate'),
          value: 'confirmAt',
          align: 'center'
        },
        rcApplyAt: {
          label: t('common.reviewDate'),
          value: 'rcApplyAt',
          align: 'center'
        }
      },
      [
        'serial',
        'staffAccount',
        'account',
        'bankAccount',
        'cash',
        'status',
        'functions',
        'updatedBy',
        'applyAt',
        'rcApplyAt',
        'confirmAt'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const [handleDebouncedExport] = useDebouncedCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    gdk.withdraw.exportWithdrawRCSlipHistory(request!).subscribe({
      next: (res) => {
        const link = document.createElement('a')
        link.href = getTargetURL(getServerUrl('api'), res.url.replace(/\/api/i, ''))
        link.click()
        link.remove()
      },
      error: (err: GDKError) => {
        globalDialog.setConfig(createGlobalDialogConfig({
          variant: 'error',
          message: err.message,
          showCancel: false
        }))
        globalDialog.setOpen({ id: 'exportRCSlipFailed', value: true, isOK: false })
      }
    })
  }, 200)

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.withdrawalRCSlip.encodePath
  })

  const handleCountReload = useCallback(() => {
    pageFlow.setLoadingStart()
    reload()
  }, [])

  if (request === undefined) return null
  return (
    <StateProvider
      defaultValue={defaultPayload}
      context={DialogPayloadContext}
    >
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Box display='flex'>
                <Box flex={1}>
                  <CoreTable
                    classes={tableClasses}
                    data={sumData}
                    total={1}
                  />
                </Box>
                { writableExport && (<Box marginLeft={1}>
                  <Button
                    onClick={handleDebouncedExport}
                    className={commonClasses.purpleGradualOutlineButton}
                  >
                    {t('common.export')}
                  </Button>
                </Box>) }
              </Box>
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <RefreshCount reload={handleCountReload} />
          <Box paddingTop={2}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={data}
                total={list.total}
                showPagination
                page={request.page}
                onChangePage={handlePagination}
                dense
              />
            </LoadingAndErrorFrame>
          </Box>
        </Box>
      </ScrollablePaper>
      <WithdrawalRCSlipDetailDialog reload={reload} />
    </StateProvider>
  )
}

export default React.memo(WithdrawalRCSlipTable)
