import React, { createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import AnnouncementSmsForm from '../../../components/admin/forestage/AnnouncementSmsForm'
import AnnouncementSmsTable from '../../../components/admin/forestage/AnnouncementSmsTable'
import { Payload, initialPayload } from '../../../components/admin/forestage/AnnouncementSmsResendDialog'
import { PaginationReq, SmsQuery } from '@golden/gdk-admin'
import { SearchToRequestFunc } from '../../../utils/default/ComplexFlowHook'
import { omitBy, isUndefined } from '@golden/utils'
import { guaranteeBetween, guaranteeNotUndefined, pipe, parseInt, acceptUndefined, guaranteeBeKey } from '../../../utils/default/FormHelper'
import smsTriggerName from '../../../constants/admin/smsTriggerName'
import smsSendStatusName from '../../../constants/admin/smsSendStatusName'
import smsReceiveStatusName from '../../../constants/admin/smsReceiveStatusName'
import smsOperatorName from '../../../constants/admin/smsOperatorName'
import StateProvider from '../../../providers/default/StateProvider'
import { getAllowedTimeStamps } from '../../../utils/default/TimeHelper'

export type Request = Partial<SmsQuery> & PaginationReq

export const searchToRequest: SearchToRequestFunc<Request> = (search) => {
  const sendStartTimeStamp = Number(search.send_start_at)
  const sendEndTimeStamp = Number(search.send_end_at)
  const verifyStartTimeStamp = Number(search.verify_start_at)
  const verifyEndTimeStamp = Number(search.verify_end_at)
  const {
    minAllowedStartTimeStamp: minAllowedSendStartTimeStamp,
    maxAllowedEndTimeStamp: maxAllowedSendEndTimeStamp
  } = getAllowedTimeStamps(sendStartTimeStamp, sendEndTimeStamp)
  const {
    minAllowedStartTimeStamp: minAllowedVerifyStartTimeStamp,
    maxAllowedEndTimeStamp: maxAllowedVerifyEndTimeStamp
  } = getAllowedTimeStamps(verifyStartTimeStamp, verifyEndTimeStamp)
  const converted = {
    ...search,
    send_start_at: acceptUndefined(search.send_start_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, minAllowedSendStartTimeStamp, sendEndTimeStamp)
    )),
    send_end_at: acceptUndefined(search.send_end_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, sendStartTimeStamp, maxAllowedSendEndTimeStamp)
    )),
    verify_start_at: acceptUndefined(search.verify_start_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, minAllowedVerifyStartTimeStamp, verifyEndTimeStamp)
    )),
    verify_end_at: acceptUndefined(search.verify_end_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, verifyStartTimeStamp, maxAllowedVerifyEndTimeStamp)
    )),
    trigger: acceptUndefined(search.trigger, pipe(
      (value) => guaranteeBeKey(value, Object.keys(smsTriggerName))
    )),
    send_status: acceptUndefined(search.send_status, pipe(
      (value) => guaranteeBeKey(value, Object.keys(smsSendStatusName))
    )),
    receive_status: acceptUndefined(search.receive_status, pipe(
      (value) => guaranteeBeKey(value, Object.keys(smsReceiveStatusName))
    )),
    operator: acceptUndefined(search.operator, pipe(
      (value) => guaranteeBeKey(value, Object.keys(smsOperatorName))
    )),
    is_verified: acceptUndefined(search.is_verified, parseInt),
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page)
  }
  if (
    (converted.send_start_at && converted.send_end_at && converted.send_end_at < converted.send_start_at) ||
    (converted.verify_start_at && converted.verify_end_at && converted.verify_end_at < converted.verify_start_at)
  ) {
    throw new Error('The end time can\'t exceed the start time')
  }
  return omitBy(converted, isUndefined) as unknown as Request
}

export const SmsResendDialogContext = createContext<[Payload, Dispatch<SetStateAction<Payload>>]>([
  initialPayload(),
  () => {}
])

const AnnouncementSmsPage: React.FC = () => {
  return (
    <StateProvider
      context={SmsResendDialogContext}
      defaultValue={initialPayload()}
    >
      <Box paddingY={6}>
        <Box paddingX={6}>
          <AnnouncementSmsForm />
        </Box>
        <Box paddingTop={2}>
          <AnnouncementSmsTable />
        </Box>
      </Box>
    </StateProvider>
  )
}

export default React.memo(AnnouncementSmsPage)
