import React, { useState, useMemo, useCallback } from 'react'
import clsx from 'clsx'
import { useDebouncedCallback } from 'use-debounce'
import { omitBy, isUndefined } from '@golden/utils'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { AdminPlayerDebitCard, AdminPlayerDebitCardForm, GDKFormError } from '@golden/gdk-admin'
import useT from '../../../i18ns/admin/useT'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import DebitCardValidationDialog, { initialValidationForm } from './DebitCardValidationDialog'
import DebitCardInfoDialog, { initialCardForm, CardFormType } from './DebitCardInfoDialog'

const Button = React.memo(MuiButton)

const formToRequest = (form: CardFormType, origin: AdminPlayerDebitCard, token?: string): Partial<AdminPlayerDebitCardForm> => {
  const data = {
    real_name: form.name !== origin.real_name ? form.name : undefined,
    bank_account: form.account !== origin.bank_account ? form.account : undefined,
    bank_code: Number(form.bank) !== origin.bank_code ? Number(form.bank) : undefined,
    token
  }
  return omitBy(data, isUndefined)
}

interface PropTypes {
  index: number
  id: number
  card: AdminPlayerDebitCard
  reload: () => void
}

const DebitCardUpdateButton: React.FC<PropTypes> = (props) => {
  const { index, id, card, reload } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [openValidation, setOpenValidation] = useState<boolean>(false)
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const handleClose = useCallback(() => { setOpen(false) }, [])
  const [form, setForm] = useState<CardFormType | null>(null)
  const handleUpdate = useCallback((token?: string) => {
    if (form === null) return null
    setLoading(true)
    return gdk.player.updatePlayerDebitCard(id, card.id, formToRequest(form, card, token)).subscribe({
      next: () => {
        globalDialog.clearState()
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: false,
          showCancel: false,
          message: t('dialog.updateCardSuccess')
        }))
        globalDialog.setOpen({ id: `updateDebitCard-${card.id}Success`, value: true, isOK: false })
        setOpen(false)
        setOpenValidation(false)
        setLoading(false)
        reload()
      },
      error: (error: GDKFormError) => {
        setLoading(false)
        globalDialog.clearState()
        globalDialog.setConfig(createGlobalDialogConfig({
          showIcon: true,
          variant: 'error',
          showCancel: false,
          message: error.message
        }))
        globalDialog.setOpen({ id: `updateDebitCard-${card.id}Fail`, value: true, isOK: false })
      }
    })
  }, [gdk, id, card, form, t])
  const [handleDebouncedSubmitInfo] = useDebouncedCallback(useCallback((form: CardFormType) => {
    if (form.account === card.bank_account && form.name === card.real_name) {
      globalDialog.setConfig(createGlobalDialogConfig({
        showIcon: false,
        notUseTypo: true,
        message: (
          <React.Fragment>
            <Typography className={dialogClasses.text}>{t('dialog.confirmUpdateDebitCard')}</Typography>
            <Typography className={clsx(dialogClasses.text, commonClasses.purpleWord)}>[{form.name}] [{form.account}]</Typography>
          </React.Fragment>
        )
      }))
      globalDialog.setOpen({ id: `confirmUpdateDebitCard-${card.id}`, value: true, isOK: false })
    } else {
      setOpenValidation(true)
    }
    setForm(form)
  }, [card.id, card.bank_account, card.real_name]), 200)
  useDialogFlow(globalDialog, `confirmUpdateDebitCard-${card.id}`, () => {
    const subscription = handleUpdate()
    setOpen(false)
    if (subscription !== null) {
      return () => {
        if ([`updateDebitCard-${card.id}Success`, `updateDebitCard-${card.id}Fail`].includes(globalDialog.open.id)) {
          subscription.unsubscribe()
        }
      }
    }
  }, [
    card.id,
    handleUpdate,
    globalDialog.open.id
  ])
  useDialogFlow(globalDialog, `warnUpdateDebitCard-${card.id}`, () => {
    const subscription = handleUpdate()
    if (subscription !== null) {
      return () => {
        if ([`updateDebitCard-${card.id}Success`, `updateDebitCard-${card.id}Fail`].includes(globalDialog.open.id)) {
          subscription.unsubscribe()
        }
      }
    }
  }, [
    card.id,
    handleUpdate,
    globalDialog.open.id
  ])
  const handleSubmitInfo = useCallback((form: CardFormType) => {
    handleDebouncedSubmitInfo(form)
    return form
  }, [handleDebouncedSubmitInfo])
  return (
    <React.Fragment>
      <Button
        onClick={useCallback(() => { setOpen(true) }, [])}
        classes={{
          root: commonClasses.blueGradualOutlineButton,
          disabled: commonClasses.blueGradualOutlineDisabledButton
        }}
        disabled={card.is_blacklist}
      >
        {t('common.update')}
      </Button>
      <DebitCardInfoDialog
        open={open}
        title={t('common.updateDebitCard')}
        disableName={index !== 0}
        onClose={handleClose}
        onSubmit={handleSubmitInfo}
        defaultValue={useMemo(() => initialCardForm({
          name: card.real_name,
          account: card.bank_account,
          bank: card.bank_code
        }), [card])}
      />
      <DebitCardValidationDialog
        title={t('common.updateDebitCard')}
        confirmText={t('common.confirmUpdate')}
        id={id}
        open={openValidation}
        loading={loading}
        onSubmit={handleUpdate}
        onClose={useCallback(() => { setOpenValidation(false) }, [])}
        defaultValue={useMemo(() => initialValidationForm({
          account: form?.account ?? '',
          realName: form?.name ?? ''
        }), [form])}
      />
    </React.Fragment>
  )
}

export default React.memo(DebitCardUpdateButton)
