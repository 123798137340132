import React, { Context, useMemo, useContext } from 'react'
import { AgCategoryType, ChannelForm, GameType } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { DialogPayloadContext } from '../../../views/admin/externalGame/ExternalGameChannelPage'
import { FormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { getValueFromChangeEvent, getValueFromCheckboxEvent } from '../../../utils/default/FormHelper'
import useT from '../../../i18ns/admin/useT'
import { createValidateNotEmpty } from '../../../utils/default/Validator'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { GameBase } from '@golden/game-china'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { findGame } from '../../../utils/default/PlatformHelper'

export interface FormType {
  code: string
  name: string
  category: number
  agGciType: string
  isShow: boolean
}

export const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  code: '',
  name: '',
  category: AgCategoryType.SLOT,
  agGciType: '',
  isShow: true,
  ...defaultForm
})

const getValueFromEvent: ValueGetter<FormType> = {
  code: getValueFromChangeEvent,
  name: getValueFromChangeEvent,
  category: getValueFromChangeEvent,
  agGciType: getValueFromChangeEvent,
  isShow: getValueFromCheckboxEvent
}

export interface Payload {
  context: Context<FormState<FormType>>
  onSubmit: (form: FormType) => FormType
  onCancel: () => void
  defaultValue: FormType
  gameId: GameType
  open: boolean
}

export const formToRequest = (game: GameBase) => (form: FormType): Partial<ChannelForm> => {
  const editSetting = game.editSetting
  const request = {
    code: editSetting.includes('code') ? form.code : undefined,
    name: editSetting.includes('name') ? form.name : undefined,
    is_show: editSetting.includes('isShow') ? form.isShow : undefined,
    category: editSetting.includes('category') ? form.category : undefined,
    ag_gci_type: editSetting.includes('agGciType') ? form.agGciType : undefined
  }
  return omitBy(request, isUndefined)
}

const ExternalGameChannelDialog: React.FC = () => {
  const classes = useCommonStyles()
  const [payload] = useContext(DialogPayloadContext)
  const {
    open,
    context,
    defaultValue,
    onSubmit,
    onCancel,
    gameId
  } = payload
  const { t } = useT()
  const games = useGDKStore.platform.games()
  const game = useMemo(() => findGame(gameId, games)?.instance ?? null, [gameId, games])
  const tableSetting = useMemo(() => {
    return game?.tableSetting ?? []
  }, [game])
  const editSetting = useMemo(() => {
    return game?.editSetting ?? []
  }, [game])
  const validation = useMemo(() => {
    return {
      code: [
        {
          func: createValidateNotEmpty('code', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      name: [
        {
          func: createValidateNotEmpty('name', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      category: [],
      agGciType: [],
      isShow: []
    } as FormValidation<FormType>
  }, [t])
  const categoryOptions = useMemo(() => {
    return (game?.channelCategories ?? []).map((el) => ({ name: el.text, value: el.id }))
  }, [game])
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={classes.dialogPinkHeader}>
        {t('common.changeGame')}
      </Box>
      <FormStateProvider
        context={context}
        defaultValue={defaultValue}
        onSubmit={onSubmit}
        getValueFromEvent={getValueFromEvent}
        validation={validation}
      >
        <DialogContent>
          <Box padding={3}>
            <Grid container direction="column" spacing={2}>
              {tableSetting.includes('code') && (
                <Grid item>
                  <FormField<FormType, TextFieldProps>
                    context={context}
                    component={TextField}
                    name="code"
                    label={t('common.gameCode')}
                    fullWidth
                    required
                    disabled={!editSetting.includes('code') || game?.hideModifyFeatures}
                  />
                </Grid>
              )}
              {tableSetting.includes('name') && (
                <Grid item>
                  <FormField<FormType, TextFieldProps>
                    context={context}
                    component={TextField}
                    name="name"
                    label={t('common.name')}
                    fullWidth
                    required
                    disabled={!editSetting.includes('name')}
                  />
                </Grid>
              )}
              {tableSetting.includes('category') && (
                <Grid item>
                  <FormField<FormType, DropDownProps>
                    context={context}
                    component={DropDown}
                    name="category"
                    label={t('common.class')}
                    options={categoryOptions}
                    fullWidth
                    required
                    disabled={!editSetting.includes('category')}
                  />
                </Grid>
              )}
              {tableSetting.includes('agGciType') && (
                <Grid item>
                  <FormField<FormType, TextFieldProps>
                    context={context}
                    component={TextField}
                    name="agGciType"
                    label="GCI GameType"
                    fullWidth
                    disabled={!editSetting.includes('agGciType')}
                  />
                </Grid>
              )}
              {tableSetting.includes('isShow') && (
                <Grid item>
                  <FormField<FormType, OnOffCheckboxProps>
                    context={context}
                    component={OnOffCheckbox}
                    name="isShow"
                    label={t('common.showInGameHall')}
                    disabled={!editSetting.includes('isShow')}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box padding={3}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Button
                  className={classes.greyButton}
                  onClick={onCancel}
                >
                  {t('common.cancel')}
                </Button>
              </Grid>
              <Grid item>
                <FormSubmitButton
                  context={context}
                  component={Button}
                  className={classes.purpleGradualButton}
                  type="submit"
                >
                  {t('common.confirm')}
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </FormStateProvider>
    </Dialog>
  )
}

export default React.memo(ExternalGameChannelDialog)
