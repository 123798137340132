import React, { createContext, Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { Branch, GameType } from '@golden/gdk-admin'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import ExternalGameChannelForm, { searchToRequest } from '../../../components/admin/externalGame/ExternalGameChannelForm'
import StateProvider from '../../../providers/default/StateProvider'
import ExternalGameChannelTable from '../../../components/admin/externalGame/ExternalGameChannelTable'
import ExternalGameChannelDialog, {
  initialForm, FormType as ChannelDialogFormType,
  Payload as ChannelDialogPayload
} from '../../../components/admin/externalGame/ExternalGameChannelDialog'
import ExternalGameChannelImageDialog, {
  Payload as ChannelImageDialogPayload
} from '../../../components/admin/externalGame/ExternalGameChannelImageDialog'
import { createDefaultFormState } from '../../../utils/default/FormHook'
import useT from '../../../i18ns/admin/useT'
import ExternalGameChannelCreateButton from '../../../components/admin/externalGame/ExternalGameChannelCreateButton'
import { useChangeUrlSubmit, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import allRoute from '../../../components/admin/route/route'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { findGame } from '../../../utils/default/PlatformHelper'
import { GameBase } from '@golden/game-china'

const defaultChangeForm = initialForm()
const initialContext = createContext(createDefaultFormState(defaultChangeForm))

const defaultPayload = {
  context: initialContext,
  defaultValue: defaultChangeForm,
  gameId: -1 as GameType,
  open: false,
  onSubmit: (form: ChannelDialogFormType) => form,
  onCancel: () => {}
}

export const DialogPayloadContext = createContext<[
  ChannelDialogPayload,
  Dispatch<SetStateAction<ChannelDialogPayload>>
]>([
  defaultPayload,
  () => {}
])

const defaultImagePayload = {
  onSubmit: () => {},
  onCancel: () => {},
  defaultImageId: 0,
  defaultImagePath: '',
  channel: '',
  open: false
}

export const ImageDialogPayloadContext = createContext<[
  ChannelImageDialogPayload,
  Dispatch<SetStateAction<ChannelImageDialogPayload>>
]>([
  defaultImagePayload,
  () => {}
])

interface FormType {
  gameId: number
  nameOrCode?: string
}

interface RequestType {
  gameId: number
  page: number
  nameOrCode?: string
}

const formToRequest = (form: FormType): RequestType => {
  const converted = {
    gameId: form.gameId,
    ...((form.nameOrCode ?? '') !== '' ? { nameOrCode: form.nameOrCode } : {}),
    page: 1
  } as RequestType
  return converted
}

const ExternalGameChannelPage: React.FC = () => {
  const gdk = useGDK()
  const { t } = useT()
  const request = useRequestFromSearch({ searchToRequest })
  const aliveGames = useGDKStore.platform.aliveGames()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const game: GameBase | null = useMemo(() => findGame(request!.gameId as GameType, aliveGames)?.instance ?? null, [aliveGames, request])
  const writable = useChecker()
  useEffect(() => {
    gdk.trunk.trigger([Branch.PLATFORMS])
  }, [gdk])
  const handleChange = useChangeUrlSubmit({
    formToRequest,
    encodePath: allRoute.externalGameChannel.encodePath,
    toAddNowTimestamp: true
  })
  return (
    <StateProvider
      context={ImageDialogPayloadContext}
      defaultValue={defaultImagePayload}
    >
      <StateProvider
        context={DialogPayloadContext}
        defaultValue={defaultPayload}
      >
        <Box paddingY={6}>
          <Box paddingX={6}>
            {(writable && !game?.customGameHallSetting?.externalGameChannelSetting.isFromAPI && !game?.hideModifyFeatures) && (<ExternalGameChannelCreateButton />)}
            <Paper>
              <ExternalGameChannelForm showSearch showGamesFromAPI title={t('page.channel')} onChange={handleChange} />
            </Paper>
          </Box>
          <Box paddingTop={2}>
            <ExternalGameChannelTable />
          </Box>
          <ExternalGameChannelDialog />
          <ExternalGameChannelImageDialog context={ImageDialogPayloadContext} />
        </Box>
      </StateProvider>
    </StateProvider>
  )
}

export default React.memo(ExternalGameChannelPage)
