import React, { createContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import MuiButton from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'
import { ValueGetter, FormValidation, createDefaultFormState } from '../../../utils/default/FormHook'
import { getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import useT from '../../../i18ns/admin/useT'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { createValidateChinaPhone, createValidateEmail, createValidateNotEmpty } from '../../../utils/default/Validator'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import { useCommonStyles } from '../../../utils/admin/StyleHook'

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  }
}))

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

export interface WithdrawalAccountFormType {
  name: string
  account: string
}

export const initialWithdrawalAccountForm: InitialFormFunc<WithdrawalAccountFormType> = (defaultForm) => ({
  name: '',
  account: '',
  ...defaultForm
})

const getValueFromWithdrawalAccountForm: ValueGetter<WithdrawalAccountFormType> = {
  name: getValueFromChangeEvent,
  account: getValueFromChangeEvent
}

const WithdrawalAccountFormContext = createContext(createDefaultFormState(initialWithdrawalAccountForm()))

interface PropTypes {
  open: boolean
  title: string
  onClose: () => void
  onSubmit: (form: WithdrawalAccountFormType) => WithdrawalAccountFormType
  defaultValue: WithdrawalAccountFormType
}

const WithdrawalAccountInfoDialog: React.FC<PropTypes> = (props) => {
  const { open, title, onClose, onSubmit, defaultValue } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const validation = useMemo(() => {
    return {
      name: [
        {
          func: createValidateNotEmpty('name', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      account: [
        {
          func: createValidateNotEmpty('account', t),
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: (value, form, lastSubmitForm) => {
            if (/^\d+$/.test(value as string)) {
              return createValidateChinaPhone<WithdrawalAccountFormType>('account', t)(value, form, lastSubmitForm, null)
            } else {
              return createValidateEmail<WithdrawalAccountFormType>('account', t)(value, form, lastSubmitForm, null)
            }
          },
          when: ['change', 'beforeClickSubmit']
        }
      ]
    } as FormValidation<WithdrawalAccountFormType>
  }, [t])

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box padding={2} className={commonClasses.dialogPinkHeader}>
        {title}
      </Box>
      <FormStateProvider
        context={WithdrawalAccountFormContext}
        defaultValue={defaultValue}
        onSubmit={onSubmit}
        getValueFromEvent={getValueFromWithdrawalAccountForm}
        validation={validation}
      >
        <DialogContent>
          <Box padding={3}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <FormField<WithdrawalAccountFormType, TextFieldProps>
                  context={WithdrawalAccountFormContext}
                  component={TextField}
                  name="name"
                  label={t('common.realName')}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormField<WithdrawalAccountFormType, TextFieldProps>
                  context={WithdrawalAccountFormContext}
                  component={TextField}
                  name="account"
                  label={t('common.account')}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid container direction="row" spacing={2} justifyContent="center">
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={onClose}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <FormSubmitButton
                context={WithdrawalAccountFormContext}
                component={Button}
                className={commonClasses.purpleGradualButton}
                type="submit"
              >
                {t('common.confirm')}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </DialogActions>
      </FormStateProvider>
    </Dialog>
  )
}

export default React.memo(WithdrawalAccountInfoDialog)
