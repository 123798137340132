import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { format } from 'date-fns'
import Box from '@material-ui/core/Box'
import { RevenueSnapshot, RevenueSnapshotItem, RevenueSnapshotQuery } from '@golden/gdk-admin'
import useT from '../../../i18ns/admin/useT'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import LineChart, { PropTypes as LineChartProps } from '../../default/present/LineChart'
import OnOffCheckbox from '../../default/form/OnOffCheckbox'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { makeStyles } from '@material-ui/styles'
interface PropTypes {
  request: RevenueSnapshotQuery
  result: RevenueSnapshot
}

interface ChartGameMainCategory {
  value: number
  name: string
}

interface ChartGameSubCategory extends ChartGameMainCategory {
  category: number
}

interface CheckboxGroup {
  name: string
  isChecked: boolean
}

interface ChartType {
  date: string
  [gameCategoryMappedName: string]: number | string
}

const AdminReportRevenueSnapshotTable: React.FC<PropTypes> = (props) => {
  const { request, result } = props
  const { t } = useT()
  const useStyles = makeStyles(() => ({
    wrapper: {
      position: 'relative',
      overflow: 'auto'
    },
    chartContainer: {
      position: 'sticky',
      left: 0,
      zIndex: 1,
      backgroundColor: '#fff'
    }
  }))
  const classes = useStyles()
  const categories = useGDKStore.platform.categories()

  const [checkboxGroups, setCheckboxGroups] = useState<CheckboxGroup[]>([])
  const [chartMinWidth, setChartMinWidth] = useState<number>(0)

  const cachedHexColors = useMemo(() => (['#FF4500', '#FF6347', '#CC8E00', '#808000', '#006400', '#00008B', '#8B008B', '#800000']), [])
  const mainCategories = useMemo(() =>
    categories.map((category) => ({ value: category.instance.id, name: category.instance.name } as ChartGameMainCategory))
  , [categories])
  const subCategories = useMemo(() => {
    const sub: ChartGameSubCategory[] = []
    categories.forEach(category => {
      category.games.forEach(game => {
        sub.push({ value: game.id, name: game.nameWithStatus, category: game.category })
      })
    })
    return sub
  }, [categories])
  const flattenCategories = useMemo(() => [...mainCategories, ...subCategories], [mainCategories, subCategories])

  const getXLabelDate = useCallback((date: Date): string => {
    const formattedTime = format(date, 'HH:mm')
    if (formattedTime.includes('00:10')) {
      return format(date, 'MM/dd HH:mm')
    } else {
      return formattedTime
    }
  }, [])
  const getChartDataInMainCategory = useCallback((revenueItem: RevenueSnapshotItem) => {
    const mappedGameNameItem: ChartType = { date: '' }
    mainCategories.forEach((category) => {
      for (const key in revenueItem) {
        if (key !== 'date' && key === category.value.toString()) {
          mappedGameNameItem[category.name] = Number(revenueItem[key])
        } else if (key === 'date') {
          mappedGameNameItem.date = getXLabelDate(revenueItem.date)
        }
      }
    })
    return mappedGameNameItem
  }, [mainCategories])
  const getChartDataInSubCategory = useCallback((revenueItem: RevenueSnapshotItem) => {
    const mappedGameNameItem: ChartType = { date: '' }
    for (const key in revenueItem) {
      if (key !== 'date') {
        const gameName = subCategories.find((category) => category.value.toString() === key)?.name ?? ''
        if (gameName) {
          mappedGameNameItem[gameName] = Number(revenueItem[key])
        }
      } else {
        mappedGameNameItem.date = getXLabelDate(revenueItem.date)
      }
    }
    return mappedGameNameItem
  }, [subCategories])

  const data: ChartType[] = useMemo(() => {
    if (!request) return []
    const mappedChartData: ChartType[] = []
    setChartMinWidth(result.revenue.length * 50 + 100)
    result.revenue.forEach((item: RevenueSnapshotItem) => {
      const mappedGameNameItem: ChartType = request.game_category === undefined ? getChartDataInMainCategory(item) : getChartDataInSubCategory(item)
      mappedChartData.push({ ...mappedGameNameItem })
    })
    return mappedChartData
  }, [request, result.revenue, mainCategories, subCategories])
  const lines = useMemo(() => {
    let index = 0
    const lines = []
    const firstRevenue = data[0]
    for (const key in firstRevenue) {
      if (key !== 'date' && checkboxGroups.find((group) => group.name === key)?.isChecked) {
        lines.push({ name: key, dataKey: key, stroke: cachedHexColors[index % cachedHexColors.length] } as LineChartProps<ChartType>['lines'][0])
        index++
      }
    }
    return lines
  }, [data, flattenCategories, checkboxGroups, t])

  useEffect(() => {
    if (!data.length || !categories.length) return
    const newCheckboxGroups: CheckboxGroup[] = []
    const firstData = data[0]
    for (const key in firstData) {
      if (key !== 'date') {
        newCheckboxGroups.push({
          name: flattenCategories.find((category) => category.name === key)?.name ?? key,
          isChecked: true
        })
      }
    }
    setCheckboxGroups(newCheckboxGroups)
  }, [result.revenue, categories])

  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4} className={classes.wrapper}>
        <Box display="flex" alignItems="center" flexDirection="row" className={classes.chartContainer}>
          {
            checkboxGroups.map((item, index) => (
              <OnOffCheckbox
                key={index}
                label={item.name}
                value={item.isChecked}
                onChange={() => {
                  const newCheckboxGroups = [...checkboxGroups]
                  newCheckboxGroups[index].isChecked = !newCheckboxGroups[index].isChecked
                  setCheckboxGroups(newCheckboxGroups)
                }}
              />
            ))
          }
        </Box>
        <Box paddingY={2} minWidth={chartMinWidth}>
          <LineChart<ChartType>
            height={600}
            interval={500}
            data={data}
            dataKey="date"
            xLabel={t('common.revenueSnapshotTiming')}
            yLabel={t('common.revenue')}
            lines={lines}
            chartMarginRight={100}
            angle={-30}
            allowDataOverflow={true}
            legendStyles={{ paddingTop: 28 }}
            removeTooltipDecimal={false}
          />
        </Box>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(AdminReportRevenueSnapshotTable)
