import React, { useState, useMemo, useCallback, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { useGetDataByPayload, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { AdminPlayerCryptoWallet, PermissionType, CryptoProtocolType, CryptoWallets } from '@golden/gdk-admin'
import DateTime from '../../default/present/DateTime'
import { createTableData } from '../../../utils/default/TableHelper'
import CoreTable from '../../default/present/CoreTable'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import CryptoWalletDeleteButton from './CryptoWalletDeleteButton'
import CGPayDetailIButton from './CGPayDetailIButton'
import TableTabs from '../TableTabs'
import BoundRelateRecordButton from './BoundRelateRecordButton'
import GoubaoDetailButton from './GoubaoDetailButton'
import { Grid } from '@material-ui/core'

interface PropTypes {
  id: number
  section: string
  reload: () => void
  loaded: Dispatch<SetStateAction<string>>
}

type CardsTab = 'binding' | 'bound'

interface RowType {
  id: number
  time: React.ReactElement
  protocol: string
  address: string
  function: React.ReactElement
}

const showDetailButton = (item: CryptoWallets<Date>, id: number) => {
  if (CryptoProtocolType.CGPAY === item.protocol || CryptoProtocolType.CGPAY_USDT === item.protocol) {
    return (
    <Grid item>
      <CGPayDetailIButton id={id} wallet={item} />
    </Grid>
    )
  }
  if (CryptoProtocolType.GOUBAO === item.protocol) {
    return (
      <Grid item>
        <GoubaoDetailButton id={id} />
      </Grid>
    )
  }
  return null
}

const PlayerDetailCryptoWalletSection: React.FC<PropTypes> = (props) => {
  const { id, section, reload: reloadProps, loaded } = props
  const { reload, reloadFlag } = useReload()
  const [cryptoWallets, setCryptoWallets] = useState<AdminPlayerCryptoWallet>({
    real_name_unmatched_by_forwarder: [],
    wallets: {
      binding: [],
      bound: []
    }
  })
  const [tab, setTab] = useState<CardsTab>('binding')
  const writable = useChecker([PermissionType.PLAYER_MANAGEMENT_CRYPTO_WALLET])
  const { t } = useT()
  const gdk = useGDK()
  const commonClasses = useCommonStyles()
  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const pageFlow = usePageFlow()

  const tabs = useMemo(() => [
    { label: t('common.currentBinding'), value: 'binding' },
    { label: t('common.pastBound'), value: 'bound' }
  ], [t])
  const isTabBinding = useMemo(() => (tab === 'binding'), [tab])

  useGetDataByPayload({
    payload: id,
    gdkFunc: (payload) => gdk.player.getPlayerCryptoWallet(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: AdminPlayerCryptoWallet) => {
      setCryptoWallets(res)
      pageFlow.setContentShow()
      loaded(section)
    },
    onError: pageFlow.setGDKError
  })
  const reloadAll = useCallback(() => {
    reload()
    reloadProps()
  }, [])
  const walletList = useMemo(() => {
    if (isTabBinding) return cryptoWallets.wallets.binding
    return cryptoWallets.wallets.bound
  }, [cryptoWallets, isTabBinding])

  const rows = useMemo(() => {
    return walletList.map((item) => {
      return {
        id: item.id,
        time: (<DateTime time={isTabBinding ? item.created_at : item.deleted_at} />),
        protocol: item.protocol_name,
        address: item.address,
        function: (
          <Grid container direction="row" spacing={1} justifyContent="center">
            {isTabBinding && (
              <>
                <Grid item>
                  <CryptoWalletDeleteButton id={id} wallet={item} reload={reloadAll} />
                </Grid>
                { showDetailButton(item, id) }
              </>
            )}
            <Grid item>
              <BoundRelateRecordButton relatedRecords={item.related_records} />
            </Grid>
          </Grid>
        )
      } as RowType
    })
  }, [walletList, id, reloadAll, isTabBinding])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        time: {
          label: (isTabBinding) ? t('common.bindData') : t('common.unbindData'),
          value: 'time',
          align: 'center'
        },
        protocol: {
          label: t('common.way'),
          value: 'protocol',
          align: 'center'
        },
        address: {
          label: t('common.address'),
          value: 'address',
          align: 'center'
        },
        function: {
          label: t('common.function'),
          value: 'function',
          align: 'center',
          width: 300
        }
      },
      writable
        ? [
            'time',
            'protocol',
            'address',
            'function'
          ]
        : [
            'time',
            'protocol',
            'address'
          ],
      rows,
      'id'
    )
  }, [rows, t, writable, isTabBinding])
  return (
    <div id={section}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={commonClasses.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('common.cryptoWalletData')}
            </Typography>
          </Box>
          <Box>
            <Box display="flex" paddingTop={2}>
              <TableTabs
                value={tab}
                tabs={tabs}
                onChange={(value) => {
                  setTab(value as CardsTab)
                }}
              />
            </Box>
          </Box>
          <Box paddingY={2}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={data}
                total={walletList.length}
              />
            </LoadingAndErrorFrame>
          </Box>
          {
            !!cryptoWallets.real_name_unmatched_by_forwarder.length &&
              <Box whiteSpace="pre">
                <Typography color='secondary'>
                  { t('common.thirdPartyNoMatch', { name: cryptoWallets.real_name_unmatched_by_forwarder.map(item => t(`thirdPartName.${item}`)).join('、') }) }
                </Typography>
              </Box>
          }
        </Box>
      </ScrollablePaper>
    </div>
  )
}

export default React.memo(PlayerDetailCryptoWalletSection)
