import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { useInternValue } from '../../../utils/default/FormHook'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { eachDayOfInterval, getDate, subDays, isSameDay, lightFormat, isSameHour, isAfter, isBefore } from 'date-fns'
import { Box } from '@material-ui/core'

export interface DateInputValue {
  start: Date
  end: Date
}

interface PropTypes {
  label?: string
  value?: DateInputValue
  defaultValue?: DateInputValue
  availableMinutes?: number[]
  availableHours?: number[]
  minutesStep?: number
  allowedDateRange: DateInputValue
  start?: boolean
  end?: boolean
  classes?: Partial<Record<'label' | 'selectGroup', string>>
  onChange?: (value: DateInputValue) => void
}

interface DateTimeSelectStateGroup {
  date: string
  hour: string
  minute: string
}

type ReducerActions = {
  type: 'SET_STATES'
  payload: DateTimeSelectStateGroup
} | {
  type: 'SET_DATE' | 'SET_HOUR' | 'SET_MINUTE'
  payload: string
}

const addZero = (num: number): string => {
  return num < 10 ? `0${num}` : num.toString()
}

const defaultAvailableHours = Array.from({ length: 24 }, (_, i) => (i))
const defaultAvailableMinutes = [0, 30]

const DateTimeSelect = (props: PropTypes) => {
  const {
    label,
    defaultValue,
    value,
    availableMinutes = defaultAvailableMinutes,
    availableHours = defaultAvailableHours,
    allowedDateRange,
    start,
    end,
    classes: propsClasses,
    onChange
  } = props

  const [internValue, setInternValue] = useInternValue(
    defaultValue ?? value ?? { start: new Date(), end: new Date() },
    value
  )
  const initialStartState: DateTimeSelectStateGroup = {
    date: lightFormat(internValue.start, 'yyyy/MM/dd'),
    hour: lightFormat(internValue.start, 'HH'),
    minute: lightFormat(internValue.start, 'mm')
  }

  const initialEndState: DateTimeSelectStateGroup = {
    date: lightFormat(internValue.end, 'yyyy/MM/dd'),
    hour: lightFormat(internValue.end, 'HH'),
    minute: lightFormat(internValue.end, 'mm')
  }

  const reducer = useCallback((state: DateTimeSelectStateGroup, action: ReducerActions) => {
    switch (action.type) {
      case 'SET_STATES':
        return {
          ...state,
          ...action.payload
        }
      case 'SET_DATE':
        return { ...state, date: action.payload }
      case 'SET_HOUR':
        return { ...state, hour: action.payload }
      case 'SET_MINUTE':
        return { ...state, minute: action.payload }
      default:
        throw new Error('Unhandled action type')
    }
  }, [])
  const [startState, startDispatch] = useReducer(reducer, initialStartState)
  const [endState, endDispatch] = useReducer(reducer, initialEndState)
  const dateRange = useMemo(() => {
    return eachDayOfInterval(allowedDateRange)
  }, [internValue])
  const mappedHours = useMemo(() => availableHours.map((hour) => addZero(hour)), [availableHours])
  const mappedMinutes = useMemo(() => availableMinutes.map((minute) => addZero(minute)), [availableMinutes])
  useEffect(() => {
    const newStartValue = new Date(`${startState.date} ${startState.hour}:${startState.minute}:00`)
    if (isAfter(newStartValue, internValue.end)) {
      startDispatch({
        type: 'SET_STATES',
        payload: {
          date: lightFormat(internValue.end, 'yyyy/MM/dd'),
          hour: lightFormat(internValue.end, 'HH'),
          minute: lightFormat(internValue.end, 'mm')
        }
      })
    }
    const newInterValue = {
      ...internValue,
      start: newStartValue
    }
    if (onChange !== undefined) {
      onChange({
        ...newInterValue
      })
    }
    setInternValue({
      ...newInterValue
    })
  }, [onChange, startState])

  useEffect(() => {
    const newEndValue = new Date(`${endState.date} ${endState.hour}:${endState.minute}:00`)
    if (isBefore(newEndValue, internValue.start)) {
      startDispatch({
        type: 'SET_STATES',
        payload: {
          date: lightFormat(newEndValue, 'yyyy/MM/dd'),
          hour: lightFormat(newEndValue, 'HH'),
          minute: lightFormat(newEndValue, 'mm')
        }
      })
    }
    const newInterValue = {
      ...internValue,
      end: newEndValue
    }
    if (onChange !== undefined) {
      onChange({
        ...newInterValue
      })
    }
    setInternValue({
      ...newInterValue
    })
  }, [onChange, endState])
  return (
    <Grid
      container
      direction="column"
    >
      {label && <InputLabel classes={{ root: propsClasses?.label }}>{ label }</InputLabel>}
      <Grid
        container
        direction="row"
        classes={{ root: propsClasses?.selectGroup }}
      >
        {start && <>
          <Select
            value={startState.date}
            onChange={(e) => {
              startDispatch({ type: 'SET_DATE', payload: e.target.value as string })
            }}
          >
            {dateRange.map((date, index) => (
              <MenuItem
                key={index}
                value={lightFormat(date, 'yyyy/MM/dd')}
                disabled={isAfter(date, internValue.end)}
              >
                {lightFormat(date, 'MM/dd')}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={startState.hour}
            onChange={(e) => {
              startDispatch({ type: 'SET_HOUR', payload: e.target.value as string })
            }}
          >
            {mappedHours.map((hour) => (
              <MenuItem
                key={hour}
                value={hour}
                disabled={isSameDay(internValue.start, internValue.end) && endState.hour < hour}
              >{hour}</MenuItem>
            ))}
          </Select>
          <Select
            value={startState.minute}
            onChange={(e) => {
              startDispatch({ type: 'SET_MINUTE', payload: e.target.value as string })
            }}
          >
            {mappedMinutes.map((minute) => (
              <MenuItem
                key={minute}
                value={minute}
                disabled={
                  isSameHour(internValue.start, internValue.end) && endState.minute <= minute}
              >{minute}</MenuItem>
            ))}
          </Select>
        </>}
        {start && end && <Box paddingX={1} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography>~</Typography>
        </Box>}
        {end && <>
          <Select
            value={endState.date}
            onChange={(e) => {
              endDispatch({ type: 'SET_DATE', payload: e.target.value as string })
            }}
          >
            {dateRange.map((date) => (
              <MenuItem
                key={getDate(date)}
                value={lightFormat(date, 'yyyy/MM/dd')}
                disabled={isBefore(date, subDays(internValue.start, 1))}
              >
                {lightFormat(date, 'MM/dd')}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={endState.hour}
            onChange={(e) => {
              endDispatch({ type: 'SET_HOUR', payload: e.target.value as string })
            }}
          >
            {mappedHours.map((hour) => (
              <MenuItem
              key={hour}
              value={hour}
              disabled={isSameDay(internValue.start, internValue.end) && startState.hour > hour}
              >{hour}</MenuItem>
            ))}
          </Select>
          <Select
            value={endState.minute}
            onChange={(e) => {
              endDispatch({ type: 'SET_MINUTE', payload: e.target.value as string })
            }}
          >
            {mappedMinutes.map((minute) => (
              <MenuItem
                key={minute}
                value={minute}
                disabled={isSameHour(internValue.start, internValue.end) && startState.minute >= minute}
              >{minute}</MenuItem>
            ))}
          </Select>
        </>}
      </Grid>
    </Grid>
  )
}

export default React.memo(DateTimeSelect)
