import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { useRequestFromSearch, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import AdminReportRevenueSnapshotForm, { searchToRequest } from '../../../components/admin/adminReport/AdminReportRevenueSnapShotForm'
import { RevenueSnapshot } from '@golden/gdk-admin/dist/interface/RevenueSnapshot'
import AdminReportRevenueSnapshotTable from '../../../components/admin/adminReport/AdminReportRevenueSnapshotTable'

const AdminReportRevenueSnapshot: React.FC = () => {
  const gdk = useGDK()
  const request = useRequestFromSearch({ searchToRequest })
  const [result, setResult] = useState<RevenueSnapshot>({
    revenue: []
  })
  const pageFlow = usePageFlow()
  useEffect(() => {
    console.log({ result })
  }, [result])
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getRevenueSnapshot(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: RevenueSnapshot) => {
      setResult(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  return (
    <Box paddingY={5}>
      <Box paddingX={5}>
        <AdminReportRevenueSnapshotForm request={request} />
      </Box>
      <LoadingAndErrorFrame error={null} loading={false} showContent={!pageFlow.status.showContent}>
        <Box paddingX={5} paddingY={3}>
          <Paper>
            <Box padding={5}>
              <LoadingAndErrorFrame { ...pageFlow.status }></LoadingAndErrorFrame>
            </Box>
          </Paper>
        </Box>
      </LoadingAndErrorFrame>
      {request && (
        <LoadingAndErrorFrame { ...pageFlow.status } error={null} loading={false}>
          {request && result?.revenue && (
            <Box marginTop={3}>
              <AdminReportRevenueSnapshotTable request={request} result={result} />
            </Box>
          )}
        </LoadingAndErrorFrame>
      )}
    </Box>
  )
}

export default React.memo(AdminReportRevenueSnapshot)
