import React, { useState, useMemo } from 'react'
import {
  DepositStatistic,
  DepositStatisticType,
  GoldenDepositStatisticItem,
  ThirdPartyDepositStatisticItem
} from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { searchToRequest } from './AdminReportDepositStatisticForm'
import CoreTable from '../../default/present/CoreTable'
import PointsCell from '../../default/present/PointsCell'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useRequestFromSearch, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createTableData, formatCount, formatMoney } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute, { Path } from '../route/route'
import { useLocation, useNavigate } from 'react-router'
import { parsePath } from '../../../utils/default/RouteHelper'
import { depositCategoryTypeName } from '../../../constants/default/depositTypeName'

interface SumRowType {
  id: number
  golden: string
  goldenCount: string
  goldenCash: React.ReactElement
  thirdParty: string
  thirdPartyCount: string
  thirdPartyCash: React.ReactElement
  thirdPartyFee: string
  sum: string
  sumCount: string
  sumCash: React.ReactElement
}

interface DetailRowType {
  id: number
  golden: string
  goldenName: string
  goldenCount: string
  goldenCash: React.ReactElement
  thirdParty: string
  thirdPartyName: string
  thirdPartyCount: string
  thirdPartyCash: React.ReactElement
  thirdPartyFee: string
}

const AdminReportDepositStatisticTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const location = useLocation()
  const navigate = useNavigate()

  const search = useMemo(() => {
    return parsePath(location.search, location.pathname, Path.ADMIN_REPORT_DEPOSIT_STATISTIC).search
  }, [location.pathname, location.search])
  const [res, setRes] = useState<DepositStatistic>({
    count_sum: 0,
    cash_sum: '0.0000',
    golden: {
      count: 0,
      cash: '0.0000',
      details: []
    },
    third_party: {
      count: 0,
      cash: '0.0000',
      fee: '0.0000',
      details: []
    }
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getDepositStatistic(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: DepositStatistic) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const sumRows: SumRowType[] = useMemo(() => {
    return [{
      id: 1,
      golden: '',
      goldenCount: formatCount(res.golden.count),
      goldenCash: (
        <PointsCell
          classes={{ text: classes.purpleAnchor }}
          points={res.golden.cash}
          onClick={() => {
            navigate(allRoute.adminReportDepositStatisticDetail.encodePath({
              param: {}, search: { start_at: search.start_at, end_at: search.end_at, type: DepositStatisticType.GOLDEN, page: 1, title: t('common.platform') }
            }))
          }}
        />
      ),
      thirdParty: '',
      thirdPartyCount: formatCount(res.third_party.count),
      thirdPartyCash: (
        <PointsCell
          classes={{ text: classes.purpleAnchor }}
          points={res.third_party.cash}
          onClick={() => {
            navigate(allRoute.adminReportDepositStatisticDetail.encodePath({
              param: {},
              search: { start_at: search.start_at, end_at: search.end_at, type: DepositStatisticType.THIRD_PARTY, page: 1, title: t('common.thirdParty') }
            }))
          }}
        />
      ),
      thirdPartyFee: formatMoney(res.third_party.fee),
      sum: '',
      sumCount: formatCount(res.count_sum),
      sumCash: (
        <PointsCell
          classes={{ text: classes.purpleAnchor }}
          points={res.cash_sum}
          onClick={() => { navigate(allRoute.adminReportDepositStatisticDetail.encodePath({ param: {}, search: { start_at: search.start_at, end_at: search.end_at, page: 1, title: t('common.sum') } })) }}
        />
      )
    }] as SumRowType[]
  }, [navigate, res.golden.cash, res.cash_sum, res.third_party.cash, res.golden.count, res.count_sum, res.third_party.count, res.third_party.fee, search, classes.purpleAnchor, t])
  const detailRows: DetailRowType[] = useMemo(() => {
    const length = res.golden.details.length > res.third_party.details.length ? res.golden.details.length : res.third_party.details.length
    return (new Array(length)).fill(1).map((_, index) => {
      const goldenItem: undefined | GoldenDepositStatisticItem = res.golden.details[index]
      const thirdPartyItem: undefined | ThirdPartyDepositStatisticItem = res.third_party.details[index]
      const goldenSearch: { bank_code?: number, category?: string, start_at: string, end_at: string, type: DepositStatisticType, forwarder_id: string, page: number } = {
        start_at: search.start_at,
        end_at: search.end_at,
        type: DepositStatisticType.GOLDEN,
        forwarder_id: goldenItem?.forwarder_id ?? '',
        page: 1
      }
      if (goldenItem?.bank_code) goldenSearch.bank_code = goldenItem.bank_code
      if (goldenItem?.category) goldenSearch.category = goldenItem.category
      const thirdPartyName = thirdPartyItem
        ? `${thirdPartyItem?.slug_name ?? ''} - ${t(depositCategoryTypeName[thirdPartyItem?.category])}`
        : ''
      return {
        id: index,
        golden: '',
        goldenName: goldenItem?.name ?? '',
        goldenCount: goldenItem?.count !== undefined ? formatCount(goldenItem.count) : '',
        goldenCash: goldenItem?.cash
          ? (
          <PointsCell
            classes={{ text: classes.purpleAnchor }}
            points={goldenItem.cash}
            onClick={() => {
              navigate(allRoute.adminReportDepositStatisticDetail.encodePath({ param: {}, search: { ...goldenSearch, title: goldenItem?.name ?? '' } }))
            }}
          />
            )
          : (<p></p>),
        thirdParty: '',
        thirdPartyName,
        thirdPartyCount: thirdPartyItem?.count !== undefined ? formatCount(thirdPartyItem.count) : '',
        thirdPartyCash: thirdPartyItem?.cash
          ? (
          <PointsCell
            classes={{ text: classes.purpleAnchor }}
            points={thirdPartyItem.cash}
            onClick={() => {
              navigate(allRoute.adminReportDepositStatisticDetail.encodePath({
                param: {},
                search: {
                  title: thirdPartyName,
                  start_at: search.start_at,
                  end_at: search.end_at,
                  type: DepositStatisticType.THIRD_PARTY,
                  slug: thirdPartyItem.slug,
                  category: thirdPartyItem.category,
                  page: 1
                }
              }))
            }}
          />
            )
          : (<p></p>),
        thirdPartyFee: thirdPartyItem?.fee ? formatMoney(thirdPartyItem.fee) : ''
      } as DetailRowType
    })
  }, [navigate, res.golden, res.third_party, search, t, classes.purpleAnchor])
  const sumData = useMemo(() => {
    return createTableData<SumRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        golden: {
          label: t('common.platform'),
          value: 'golden',
          align: 'center',
          groupItems: ['goldenCount', 'goldenCash']
        },
        thirdParty: {
          label: t('common.thirdParty'),
          value: 'thirdParty',
          align: 'center',
          groupItems: ['thirdPartyCount', 'thirdPartyCash', 'thirdPartyFee']
        },
        sum: {
          label: t('common.sum'),
          value: 'sum',
          align: 'center',
          groupItems: ['sumCount', 'sumCash']
        },
        goldenCash: {
          label: t('common.cash'),
          value: 'goldenCash',
          align: 'right'
        },
        goldenCount: {
          label: t('common.count'),
          value: 'goldenCount',
          align: 'center'
        },
        thirdPartyCount: {
          label: t('common.count'),
          value: 'thirdPartyCount',
          align: 'center'
        },
        thirdPartyFee: {
          label: t('common.systemFee'),
          value: 'thirdPartyFee',
          align: 'right'
        },
        thirdPartyCash: {
          label: t('common.cash'),
          value: 'thirdPartyCash',
          align: 'right'
        },
        sumCount: {
          label: t('common.count'),
          value: 'sumCount',
          align: 'center'
        },
        sumCash: {
          label: t('common.cash'),
          value: 'sumCash',
          align: 'right'
        }
      },
      [
        'golden',
        'thirdParty',
        'sum'
      ],
      sumRows,
      'id'
    )
  }, [sumRows, t])
  const detailData = useMemo(() => {
    return createTableData<DetailRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        golden: {
          label: t('common.platform'),
          value: 'golden',
          align: 'center',
          groupItems: ['goldenName', 'goldenCount', 'goldenCash']
        },
        thirdParty: {
          label: t('common.thirdParty'),
          value: 'thirdParty',
          align: 'center',
          groupItems: ['thirdPartyName', 'thirdPartyCount', 'thirdPartyCash', 'thirdPartyFee']
        },
        goldenName: {
          label: t('common.payPlatform'),
          value: 'goldenName',
          align: 'center'
        },
        goldenCash: {
          label: t('common.cash'),
          value: 'goldenCash',
          align: 'right'
        },
        goldenCount: {
          label: t('common.count'),
          value: 'goldenCount',
          align: 'center'
        },
        thirdPartyName: {
          label: t('common.thirdPartyPlatform'),
          value: 'thirdPartyName',
          align: 'center'
        },
        thirdPartyCount: {
          label: t('common.count'),
          value: 'thirdPartyCount',
          align: 'center'
        },
        thirdPartyCash: {
          label: t('common.cash'),
          value: 'thirdPartyCash',
          align: 'right'
        },
        thirdPartyFee: {
          label: t('common.systemFee'),
          value: 'thirdPartyFee',
          align: 'right'
        }
      },
      [
        'golden',
        'thirdParty'
      ],
      detailRows,
      'id'
    )
  }, [detailRows, t])

  if (request === undefined) return null
  return (
    <React.Fragment>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                marginBottom={3}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.depositStatistic')}
                </Typography>
              </Box>
              <CoreTable
                classes={tableClasses}
                data={sumData}
                total={1}
                separateGroup
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <Box
              paddingY={1.25}
              paddingX={2}
              marginBottom={3}
              className={classes.pinkTitleBar}
            >
              <Typography variant="h5">
                {t('common.depositPlatformStatistic')}
              </Typography>
            </Box>
            <CoreTable
              classes={tableClasses}
              data={detailData}
              total={detailData.rows.length}
              separateGroup
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </React.Fragment>
  )
}

export default React.memo(AdminReportDepositStatisticTable)
