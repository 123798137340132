import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { AdminPlayerWithdrawalAccount } from '@golden/gdk-admin'
import { useCommonStyles, useDetailStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingBottom: theme.spacing(4)
  }
}))

const Button = React.memo(MuiButton)

interface PropTypes {
  index: number
  id: number
  account: AdminPlayerWithdrawalAccount
  reload: () => void
}

const WithdrawalAccountDeleteButton: React.FC<PropTypes> = (props) => {
  const { id, account, reload } = props
  const [open, setOpen] = useState<boolean>(false)
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const detailClasses = useDetailStyles()
  const { t } = useT()
  const gdk = useGDK()
  const globalDialog = useGlobalDialog()
  const [handleDebouncedOK] = useDebouncedCallback(useCallback(() => {
    setOpen(false)
    gdk.player.deletePlayerWithdrawalAccount(id, account.id)
      .subscribe({
        next: () => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: false,
            showCancel: false,
            message: t('dialog.deleteCardSuccess')
          }))
          globalDialog.setOpen({
            id: `deleteDebitCardSuccess-${account.id}`,
            value: true,
            isOK: false
          })
          reload()
        },
        error: (error) => {
          globalDialog.setConfig(createGlobalDialogConfig({
            showIcon: true,
            variant: 'error',
            showCancel: false,
            message: error.message
          }))
          globalDialog.setOpen({
            id: `deleteDebitCardFail-${account.id}`,
            value: true,
            isOK: false
          })
        }
      })
  }, [gdk, id, account.id]), 200)
  const onClick = useCallback(handleDebouncedOK, [])
  return (
    <React.Fragment>
      <Button
        onClick={useCallback(() => { setOpen(true) }, [])}
        className={commonClasses.pinkGradualOutlineButton}
      >
        {t('common.delete')}
      </Button>
      <Dialog open={open} fullWidth maxWidth="sm">
        <Box padding={2} className={commonClasses.dialogPinkHeader}>
          {t('dialog.confirmDeleteWithdrawalAccount')}
        </Box>
        <DialogContent>
          <Box padding={3}>
            <table className={detailClasses.table}>
              <tbody>
                <tr>
                  <td className={detailClasses.head}>{t('common.realName')}</td>
                  <td className={detailClasses.cell}>{account.real_name}</td>
                </tr>
                <tr>
                  <td className={detailClasses.head}>{t('common.alipayAccount')}</td>
                  <td className={detailClasses.cell}>{account.account}</td>
                </tr>
              </tbody>
            </table>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid container direction="row" spacing={2} justifyContent="center">
            <Grid item>
              <Button
                className={commonClasses.greyButton}
                onClick={useCallback(() => { setOpen(false) }, [])}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={commonClasses.purpleGradualButton}
                onClick={onClick}
              >
                {t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default React.memo(WithdrawalAccountDeleteButton)
