import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import {
  PaginationRes,
  EffectiveCashHistory,
  WithdrawPointType,
  EffectiveCashHistorySum,
  ADMIN_API,
  WithdrawPointTarget,
  GuardType
} from '@golden/gdk-admin'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { searchToRequest } from './EffectiveCashHistoryForm'
import CoreTable from '../../default/present/CoreTable'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl, usePerPageChange } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { getGameName } from '../../../utils/default/PlatformHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute from '../route/route'
import DateTime from '../../default/present/DateTime'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { createDefaultMemoPopoverPayload, MemoPopoverPayload } from '../../default/memo/MemoPopover'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import MemoTextWithTitleAndContext from '../../default/memo/MemoTextWithTitleAndContext'
import withdrawPointReasonName from '../../../constants/admin/withdrawPointReasonName'
import { getTime, startOfDay, endOfDay } from 'date-fns'
import EffectiveCashHistorySumTable from './EffectiveCashHistorySumTable'
import withdrawPointTargetName from '../../../constants/admin/withdrawPointTargetName'

interface GeneralRowType {
  id: number
  serial: React.ReactElement
  target: string
  reason: string
  delta: React.ReactElement
  memo: React.ReactElement
  createdAt: React.ReactElement
  creatorAccount: string
  isReset: boolean
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const getSerialHref = (type: WithdrawPointType, serialNumber: string, createdAt: Date) => {
  switch (type) {
    case WithdrawPointType.AGENT_DISPATCH:
    case WithdrawPointType.AGENT_TRANSFER_DEPOSIT:
      return allRoute.agentWalletHistory.encodePath({ search: { transfer_number: serialNumber, page: 1 }, param: {} })
    case WithdrawPointType.MANUAL_DEPOSIT:
      return allRoute.manualDeposit.encodePath({ search: { order_number: serialNumber, page: 1 }, param: {} })
    case WithdrawPointType.THIRD_PARTY_DEPOSIT:
      return allRoute.depositThirdPartySlip.encodePath({ search: { order_number: serialNumber, page: 1 }, param: {} })
    case WithdrawPointType.DEPOSIT:
      return allRoute.depositSlip.encodePath({ search: { order_number: serialNumber, page: 1 }, param: {} })
    case WithdrawPointType.MANUAL_WITHDRAW:
      return allRoute.manualWithdraw.encodePath({ search: { serial_number: serialNumber, page: 1 }, param: {} })
    case WithdrawPointType.WITHDRAW:
      return allRoute.withdrawalFMSlip.encodePath({ search: { order_number: serialNumber, page: 1 }, param: {} })
    case WithdrawPointType.FEEDBACK:
      return allRoute.playerReportCash.encodePath({ search: { order_number: serialNumber, page: 1, start_at: getTime(startOfDay(createdAt)), end_at: getTime(endOfDay(createdAt)) }, param: {} })
    default:
      return ''
  }
}

const EffectiveCashHistoryTable: React.FC = () => {
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const games = useGDKStore.platform.games()
  const [list, setList] = useState<PaginationRes<EffectiveCashHistory[]>>(createDefaultPaginationData([]))
  const [sumData, setSumData] = useState<EffectiveCashHistorySum>({
    last_reset_at: new Date(),
    previous_reset_at: new Date(),
    buckets: [],
    user_id: 0,
    account: '',
    is_withdrawable: true,
    can_not_withdraw_reasons: [],
    reset_ats: []
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.withdraw.getEffectiveCashHistory(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<EffectiveCashHistory[]> & EffectiveCashHistorySum) => {
      setList({ ...res })
      setSumData({ ...res })
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })

  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [commonClasses])
  const rows: GeneralRowType[] = useMemo(() => {
    return list.data.map((item, index) => {
      const href = getSerialHref(item.link_type, item.serial_number, item.created_at)
      return {
        id: index,
        serial: item.serial_number
          ? (
              href !== ''
                ? (<Link className={commonClasses.anchor} to={href}>{item.serial_number}</Link>)
                : (<p>{item.serial_number}</p>)
            )
          : (<p>--</p>),
        target: item.target === WithdrawPointTarget.GAME && item.target_id ? getGameName(item.target_id, games) : t(withdrawPointTargetName[item.target]),
        reason: `${t(withdrawPointReasonName[item.reason])}${item.is_reset ? ` | ${t('common.reset')}` : ''}`,
        delta: (<span style={{ color: ((Number(item.value) > 0) ? '#55B559' : '#f44336') }}>{item.value}</span>),
        memo: item.link_type === WithdrawPointType.ADMIN ? (<MemoTextWithTitleAndContext context={MemoContext} title={item.memo ?? ''} memo={item.memo ?? ''} />) : item.memo,
        createdAt: (<DateTime time={item.created_at} />),
        creatorAccount: `${item.creator_account}${item.creator_type === GuardType.AGENT ? '*' : ''}`,
        isReset: item.is_reset
      } as GeneralRowType
    })
  }, [commonClasses.anchor, list.data, t, games])
  const data = useMemo(() => {
    return createTableData<GeneralRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        serial: {
          label: t('common.serialShort'),
          value: 'serial',
          align: 'center'
        },
        target: {
          label: t('common.effectiveCashType2'),
          value: 'target',
          align: 'center'
        },
        reason: {
          label: t('common.reason'),
          value: 'reason',
          align: 'center'
        },
        delta: {
          label: t('common.effectiveCash2'),
          value: 'delta',
          align: 'right'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        },
        createdAt: {
          label: t('common.updateAt'),
          value: 'createdAt',
          align: 'center'
        },
        creatorAccount: {
          label: t('common.updateBy'),
          value: 'creatorAccount',
          align: 'center'
        },
        isReset: {
          label: '',
          value: 'isReset',
          align: 'center'
        }
      },
      [
        'serial',
        'target',
        'reason',
        'delta',
        'memo',
        'createdAt',
        'creatorAccount'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  const selected = useMemo(() => rows.reduce<number[]>((accu, curr) => {
    if (curr.isReset) accu.push(curr.id)
    return accu
  }, []), [rows])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.manualEffectiveCashHistory.encodePath
  })
  const handlePerPageChange = usePerPageChange({
    request,
    encodePath: allRoute.manualEffectiveCashHistory.encodePath,
    gdk,
    pageKey: ADMIN_API.GET_EFFECTIVE_CASH_HISTORY.url
  })

  if (request === undefined) return null
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingTop={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <EffectiveCashHistorySumTable
                data={sumData}
                classes={tableClasses}
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <Box paddingTop={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={4}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={data}
                total={list.total}
                showPagination
                page={request.page}
                perPage={list.per_page}
                onChangePage={handlePagination}
                onChangeRowsPerPage={handlePerPageChange}
                isSelected
                selected={selected}
              />
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
    </MemoPopoverWithContext>
  )
}

export default React.memo(EffectiveCashHistoryTable)
