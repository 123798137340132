import React, { useState, useMemo, createContext, useContext, useCallback, useEffect } from 'react'
import { PaginationReq, RCStatusType, WithdrawRCSlipQuery, WithdrawWayType } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { isValid, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, acceptUndefined, getValueFromChangeEvent, getValueFromCheckboxEvent, guaranteeBeKey, parseFloat, convertEmptyToUndefined, getCashInputProps, getTimeFromDateInputValue } from '../../../utils/default/FormHelper'
import allRoute from '../route/route'
import { useRequestFromSearch, SearchToRequestFunc, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import { createValidateMin, createValidateMax } from '../../../utils/default/Validator'
import NumberInput from '../../default/form/NumberInput'
import rcStatusName from '../../../constants/admin/rcStatusName'
import withdrawWayTypeName from '../../../constants/admin/withdrawWayTypeName'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { createShouldDisableFuture, getAllowedTimeStamps, getCorrectedDateRange, setToday } from '../../../utils/default/TimeHelper'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'

export type SlipRequest = PaginationReq & WithdrawRCSlipQuery

export interface SlipFormType {
  account: string
  serial: string
  status: '--' | RCStatusType
  method: '--' | WithdrawWayType
  createdAt: DateInputValue
  finishedAtNull: boolean | null
  finishedAt: DateInputValue
  rcAppliedAt: DateInputValue
  rcUpdatedBy: string
  min: string
  max: string
  bankAccount: string
  realName: string
  realNameNull: boolean | null
  staffAccount: string
}

export const searchToRequest: SearchToRequestFunc<SlipRequest> = (search) => {
  const createdAtStartTimeStamp = Number(search.created_at_start)
  const createdAtEndTimeStamp = Number(search.created_at_end)
  const {
    minAllowedStartTimeStamp: minAllowedCreatedAtStartTimeStamp,
    maxAllowedEndTimeStamp: maxAllowedCreatedAtEndTimeStamp
  } = getAllowedTimeStamps(createdAtStartTimeStamp, createdAtEndTimeStamp)
  const finishedAtStartTimeStamp = Number(search.finished_at_start)
  const finishedAtEndTimeStamp = Number(search.finished_at_end)
  const {
    minAllowedStartTimeStamp: minAllowedFinishedAtStartTimeStamp,
    maxAllowedEndTimeStamp: maxAllowedFinishedAtEndTimeStamp
  } = getAllowedTimeStamps(finishedAtStartTimeStamp, finishedAtEndTimeStamp)
  const rcApplyAtStartTimeStamp = Number(search.rc_apply_at_start)
  const rcApplyAtEndTimeStamp = Number(search.rc_apply_at_end)
  const {
    minAllowedStartTimeStamp: minAllowedRcApplyAtStartTimeStamp,
    maxAllowedEndTimeStamp: maxAllowedRcApplyAtEndTimeStamp
  } = getAllowedTimeStamps(rcApplyAtStartTimeStamp, rcApplyAtEndTimeStamp)
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    status: acceptUndefined(search.rc_status, pipe(
      (value) => guaranteeBeKey(value, Object.keys(rcStatusName))
    )),
    method: acceptUndefined(search.method, pipe(
      (value) => guaranteeBeKey(value, Object.keys(withdrawWayTypeName))
    )),
    created_at_start: acceptUndefined(search.created_at_start,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, minAllowedCreatedAtStartTimeStamp, createdAtEndTimeStamp)
      )
    ),
    created_at_end: acceptUndefined(search.created_at_end,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, createdAtStartTimeStamp, maxAllowedCreatedAtEndTimeStamp)
      )
    ),
    finished_at_start: acceptUndefined(search.finished_at_start,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, minAllowedFinishedAtStartTimeStamp, finishedAtEndTimeStamp)
      )
    ),
    finished_at_end: acceptUndefined(search.finished_at_end,
      pipe(
        parseInt,
        (value) => guaranteeBetween(value, finishedAtStartTimeStamp, maxAllowedFinishedAtEndTimeStamp)
      )
    ),
    rc_apply_at_start: acceptUndefined(search.rc_apply_at_start, pipe(
      parseInt,
      (value) => guaranteeBetween(value, minAllowedRcApplyAtStartTimeStamp, rcApplyAtEndTimeStamp)
    )),
    rc_apply_at_end: acceptUndefined(search.rc_apply_at_end, pipe(
      parseInt,
      (value) => guaranteeBetween(value, rcApplyAtStartTimeStamp, maxAllowedRcApplyAtEndTimeStamp)
    )),
    cash_start: acceptUndefined(search.cash_start, parseFloat),
    cash_end: acceptUndefined(search.cash_end, parseFloat)
  } as SlipRequest
  if (converted.created_at_start && converted.created_at_end && converted.created_at_end < converted.created_at_start) throw new Error('The end time can\'t exceed the start time')
  if (converted.finished_at_start && converted.finished_at_end && converted.finished_at_end < converted.finished_at_start) throw new Error('The end time can\'t exceed the start time')
  if (converted.created_at_start && converted.created_at_end && converted.created_at_end < converted.created_at_start) throw new Error('The end time can\'t exceed the start time')
  if (converted.rc_apply_at_start && converted.rc_apply_at_end && converted.rc_apply_at_end < converted.rc_apply_at_start) throw new Error('The cash_end can\'t exceed the cash_start')
  return omitBy(converted, isUndefined) as SlipRequest
}

export const initialForm: InitialFormFunc<SlipFormType> = (defaultForm) => ({
  account: '',
  serial: '',
  status: '--',
  method: '--',
  bankAccount: '',
  createdAt: setToday(),
  finishedAtNull: null,
  finishedAt: {
    start: null,
    end: null
  },
  rcAppliedAt: {
    start: null,
    end: null
  },
  rcUpdatedBy: '',
  min: '',
  max: '',
  realName: '',
  realNameNull: null,
  staffAccount: '',
  ...defaultForm
})

const formToRequest = (form: SlipFormType): SlipRequest => {
  const converted = {
    player_account: convertEmptyToUndefined(form.account),
    order_number: convertEmptyToUndefined(form.serial),
    rc_status: form.status === '--' ? undefined : form.status,
    method: form.method === '--' ? undefined : form.method,
    bank_account: convertEmptyToUndefined(form.bankAccount),
    created_at_start: form.createdAt.start && isValid(form.createdAt.start) ? getTime(form.createdAt.start) : undefined,
    created_at_end: form.createdAt.end && isValid(form.createdAt.end) ? getTime(form.createdAt.end) : undefined,
    finished_at_null: typeof form.finishedAtNull === 'boolean' ? Number(form.finishedAtNull) : undefined,
    finished_at_start: form.finishedAtNull !== true && form.finishedAt.start && isValid(form.finishedAt.start) ? getTime(form.finishedAt.start) : undefined,
    finished_at_end: form.finishedAtNull !== true && form.finishedAt.end && isValid(form.finishedAt.end) ? getTime(form.finishedAt.end) : undefined,
    rc_apply_at_start: form.rcAppliedAt.start && isValid(form.rcAppliedAt.start) ? getTime(form.rcAppliedAt.start) : undefined,
    rc_apply_at_end: form.rcAppliedAt.end && isValid(form.rcAppliedAt.end) ? getTime(form.rcAppliedAt.end) : undefined,
    rc_updated_by: convertEmptyToUndefined(form.rcUpdatedBy),
    cash_start: convertEmptyToUndefined(form.min),
    cash_end: convertEmptyToUndefined(form.max),
    real_name: convertEmptyToUndefined(form.realName),
    real_name_null: form.realNameNull !== null ? Number(form.realNameNull) : undefined,
    staff_account: convertEmptyToUndefined(form.staffAccount),
    page: 1
  } as SlipRequest
  return omitBy(converted, isUndefined) as SlipRequest
}

const getValueFromEvent: ValueGetter<SlipFormType> = {
  account: getValueFromChangeEvent,
  serial: getValueFromChangeEvent,
  status: getValueFromChangeEvent,
  method: getValueFromChangeEvent,
  createdAt: getTimeFromDateInputValue,
  finishedAtNull: getValueFromCheckboxEvent,
  finishedAt: getTimeFromDateInputValue,
  rcAppliedAt: getTimeFromDateInputValue,
  rcUpdatedBy: getValueFromChangeEvent,
  min: getValueFromChangeEvent,
  max: getValueFromChangeEvent,
  bankAccount: getValueFromChangeEvent,
  realName: getValueFromChangeEvent,
  realNameNull: getValueFromCheckboxEvent,
  staffAccount: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const cashInputProps = getCashInputProps()

type DateType = 'createdAt' | 'finishedAt' | 'rcAppliedAt'
const DateInput: React.FC<{ dateType: DateType, disabled: boolean }> = React.memo((props) => {
  const { dateType, disabled } = props
  const { value, handleChange } = useContext(FormContext)
  const { t } = useT()
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => createShouldDisableFuture(), [])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate,
    disabled
  }), [shouldDisableStartDate, disabled, t])
  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate,
    disabled
  }), [shouldDisableEndDate, disabled, t])
  const onChange = useCallback((value) => {
    handleChange(dateType)(getCorrectedDateRange(value))
  }, [dateType])
  return <DateInputBase
    sm
    value={value[dateType]}
    onChange={onChange}
    start={startOption}
    end={endOption}
  />
})
const DateRow: React.FC = React.memo(() => {
  const { value, handleChange } = useContext(FormContext)
  const { t } = useT()
  const request = useRequestFromSearch({ searchToRequest })
  const getInitialDateType = useCallback(() => {
    if (request) {
      if (request.finished_at_start ?? request.finished_at_end ?? request.finished_at_null) return 'finishedAt'
      if (request.rc_apply_at_start ?? request.rc_apply_at_end) return 'rcAppliedAt'
    }
    return 'createdAt'
  }, [])
  const [dateType, setDateType] = useState<DateType>(getInitialDateType())
  const dateTypeOptions: Array<{ name: string, value: DateType }> = useMemo(() => ([
    {
      name: t('common.applyDate'),
      value: 'createdAt'
    },
    {
      name: t('common.reviewDate'),
      value: 'rcAppliedAt'
    },
    {
      name: t('common.confirmDate'),
      value: 'finishedAt'
    }
  ]), [t])
  const handleDateTypeChange = useCallback((event) => {
    handleChange('createdAt')(event.target.value === 'createdAt' ? setToday() : { start: null, end: null })
    handleChange('rcAppliedAt')(event.target.value === 'rcAppliedAt' ? setToday() : { start: null, end: null })
    handleChange('finishedAt')(event.target.value === 'finishedAt' ? setToday() : { start: null, end: null })
    handleChange('finishedAtNull')({ target: { value: event.target.value === 'finishedAt' ? false : null } })
    setDateType(event.target.value)
  }, [value, dateTypeOptions])
  const handleFinishedAtNull = useCallback((event) => {
    if (value.finishedAtNull === false) {
      handleChange('finishedAt')({ start: null, end: null })
    } else {
      handleChange('finishedAt')(setToday())
    }
    handleChange('finishedAtNull')(event)
  }, [handleChange])
  const disabled = useMemo(() => dateType === 'finishedAt' && !!value.finishedAtNull, [dateType, value.finishedAtNull])
  return (
    <Grid item>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={3}>
          <DropDown
            value={dateType}
            fullWidth
            options={dateTypeOptions}
            onChange={handleDateTypeChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput dateType={dateType} disabled={disabled} />
        </Grid>
        {
          dateType === 'finishedAt' && (
            <Grid item xs={12} md={3}>
              <OnOffCheckbox
                label={t('common.emptyDate')}
                value={value.finishedAtNull ?? false}
                onChange={handleFinishedAtNull}
              />
            </Grid>
          )
        }
      </Grid>
    </Grid>
  )
})

const WithdrawalRCSlipForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const pageFlow = usePageFlow()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm(omitBy({
        account: request.player_account,
        serial: request.order_number,
        status: request.rc_status ?? '--',
        method: request.method ?? '--',
        createdAt: {
          start: request.created_at_start ? new Date(request.created_at_start) : null,
          end: request.created_at_end ? new Date(request.created_at_end) : null
        },
        finishedAtNull: request.finished_at_null !== null ? Boolean(Number(request.finished_at_null)) : null,
        finishedAt: {
          start: request.finished_at_start ? new Date(request.finished_at_start) : null,
          end: request.finished_at_end ? new Date(request.finished_at_end) : null
        },
        rcAppliedAt: {
          start: request.rc_apply_at_start ? new Date(request.rc_apply_at_start) : null,
          end: request.rc_apply_at_end ? new Date(request.rc_apply_at_end) : null
        },
        rcUpdatedBy: request.rc_updated_by,
        min: request.cash_start?.toString() ?? '',
        max: request.cash_end?.toString() ?? '',
        bankAccount: request.bank_account,
        realName: request.real_name,
        realNameNull: request.real_name_null !== undefined ? Boolean(Number(request.real_name_null)) : null,
        staffAccount: request.staff_account ?? ''
      }, isUndefined))
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.withdrawalRCSlip.encodePath
  })

  const validation = useMemo(() => ({
    account: [],
    serial: [],
    status: [],
    method: [],
    createdAt: [],
    finishedAtNull: [],
    finishedAt: [],
    rcAppliedAt: [],
    rcUpdatedBy: [],
    min: [
      {
        func: createValidateMin('min', 'max', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    max: [
      {
        func: createValidateMax('min', 'max', t),
        when: ['change', 'beforeClickSubmit']
      }
    ],
    bankAccount: [],
    realName: [],
    realNameNull: [],
    staffAccount: []
  } as FormValidation<SlipFormType>), [t])

  const statusOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(rcStatusName)
      .map((key) => ({
        name: t(rcStatusName[key as RCStatusType]),
        value: key
      })) as any
  )

  const methodOptions = [{ name: t('common.all'), value: '--' }].concat(
    Object.keys(withdrawWayTypeName)
      .map((key) => ({
        name: t(withdrawWayTypeName[key as WithdrawWayType]),
        value: key
      })) as any
  )

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <LoadingAndErrorFrame {...pageFlow.status}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  paddingY={1.25}
                  paddingX={2}
                  className={classes.pinkTitleBar}
                >
                  <Typography variant="h5">
                    {t('page.withdrawRCManage')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="account"
                      label={t('common.playerAccount')}
                      placeholder={t('placeholder.inputPlayerAccount')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="serial"
                      label={t('common.orderNumber')}
                      placeholder={t('placeholder.inputOrderNumber')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="status"
                      options={statusOptions}
                      label={t('common.orderStatus2')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="method"
                      options={methodOptions}
                      label={t('common.chooseWithdrawWay')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <DateRow />
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="realName"
                      label={t('common.realName')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, OnOffCheckboxProps>
                      context={FormContext}
                      component={OnOffCheckbox}
                      name="realNameNull"
                      label={t('common.emptyRealName')}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="rcUpdatedBy"
                      label={t('common.updateBy')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="min"
                      label={t('common.minOrderMoney')}
                      placeholder={t('placeholder.inputMinOrderMoney')}
                      inputProps={cashInputProps}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={NumberInput}
                      name="max"
                      label={t('common.maxOrderMoney')}
                      placeholder={t('placeholder.inputMaxOrderMoney')}
                      inputProps={cashInputProps}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="bankAccount"
                      label={`${t('common.cardNo')}/${t('common.address')}`}
                      placeholder={`${t('placeholder.input')}${t('common.cardNo')}/${t('common.address')}`}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormField<SlipFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="staffAccount"
                      label={t('common.agentAccount3')}
                      placeholder={`${t('placeholder.input')}${t('common.agentAccount3')}`}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LoadingAndErrorFrame>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(WithdrawalRCSlipForm)
