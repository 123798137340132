import React, { useMemo, createContext, useContext, useCallback } from 'react'
import { PaginationReq, AlertQuery, AlertInfo, alertInfoMapPermission } from '@golden/gdk-admin'
import { omitBy, isUndefined, enumValues } from '@golden/utils'
import { getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, guaranteeBeOneOf, acceptUndefined, getTimeFromDateInputValue, getValueFromChangeEvent } from '../../../utils/default/FormHelper'
import allRoutes from '../route/route'
import { useRequestFromSearch, SearchToRequestFunc, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { FormValidation, createDefaultFormState, ValueGetter } from '../../../utils/default/FormHook'
import { createShouldDisableFuture, getAllowedTimeStamps, getCorrectedDateRange, setToday, setYesterday } from '../../../utils/default/TimeHelper'
import alertInfoName from '../../../constants/admin/alertInfoName'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

export type JournalEventRequest = PaginationReq & AlertQuery

export interface JournalEventFormType {
  time: DateInputValue
  info: AlertInfo | '--'
}

export const searchToRequest: SearchToRequestFunc<JournalEventRequest> = (search) => {
  const startTimeStamp = Number(search.start_at)
  const endTimeStamp = Number(search.end_at)
  const { minAllowedStartTimeStamp, maxAllowedEndTimeStamp } = getAllowedTimeStamps(startTimeStamp, endTimeStamp)
  const converted = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, minAllowedStartTimeStamp, endTimeStamp)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, startTimeStamp, maxAllowedEndTimeStamp)
    )(search.end_at),
    info: acceptUndefined(search.info, pipe(
      (value) => guaranteeBeOneOf(Number(value), enumValues(AlertInfo)),
      parseInt
    ))
  } as JournalEventRequest
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as JournalEventRequest
}

export const initialForm: InitialFormFunc<JournalEventFormType> = (defaultForm) => ({
  time: setToday(),
  info: '--',
  ...defaultForm
})

const formToRequest = (form: JournalEventFormType): JournalEventRequest => {
  const converted = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!),
    info: form.info === '--' ? undefined : form.info,
    page: 1
  } as JournalEventRequest
  return omitBy(converted, isUndefined) as JournalEventRequest
}

const getValueFromEvent: ValueGetter<JournalEventFormType> = {
  time: getTimeFromDateInputValue,
  info: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => createShouldDisableFuture(), [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday
      },
      {
        label: t('common.yesterday'),
        change: setYesterday
      }
    ]
  }, [t])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  const onChange = useCallback((value) => {
    handleChange('time')(getCorrectedDateRange(value))
  }, [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const JournalEventForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const me = useGDKStore.admin.me()
  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        },
        ...request
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.journalEvent.encodePath
  })

  const validation = useMemo(() => ({
    time: [],
    info: []
  } as FormValidation<JournalEventFormType>), [])

  const infoOptions: Array<{ name: string, value: string | number }> = [
    { name: t('common.all'), value: '--' }
  ].concat(
    Object.values(AlertInfo)
      .filter((item) => ((typeof item === 'number')))
      .filter((item) => ((me?.permissions.includes(alertInfoMapPermission[Number(item) as AlertInfo]))))
      .map((key) => ({
        name: t(alertInfoName[key as AlertInfo]),
        value: key
      })) as any
  )

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.warningLog')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormField<JournalEventFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="info"
                    options={infoOptions}
                    label={t('common.warningType')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(JournalEventForm)
